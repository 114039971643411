import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import {
  GetExportDomainsAndColumns,
  GetExportScheduleTypes,
  GetExportTypes,
  GetSelectedPreferencesData
} from 'Redux/Service/DataExportService';
import {
  getEcrfTrialName,
  handleSelectedSources,
  handleTrialItems
} from '../../ReviewAndPublish/utilities';
import {
  setDomainAndColumnMetaData,
  setExportTypeMetaData,
  setScheduleFrequencies,
  setSelectedPreferences
} from 'Redux/Slice/PublishMappingSpecSlice';

const getInitialDomainColumns = (domainAndColumns) => {
  const domainColumnMap = {};
  domainAndColumns.forEach((domain) => {
    domainColumnMap[domain.domainCode] = {
      ...domain,
      checked: true,
      exportVariables: domain.exportVariables?.map((variable) => ({ ...variable, checked: true }))
    };
  });
  return domainColumnMap;
};

const getLoadPreferenceDomainColumns = (
  domainAndColumns,
  loadPreferenceDomainColums,
  isIncludeSystemFields
) => {
  const domainColumnMap = {};
  domainAndColumns.forEach((domain) => {
    const preferenceDomain = loadPreferenceDomainColums?.find(
      (el) => el.domainCode === domain.domainCode
    );
    if (preferenceDomain) {
      const _exportVariables =
        preferenceDomain.variableInformation?.map((variable) => ({ ...variable, checked: true })) ||
        [];
      domain.exportVariables?.forEach((variable) => {
        if (
          !preferenceDomain.variableInformation?.some(
            (el) => el.originalColumnName === variable.originalColumnName
          )
        ) {
          _exportVariables.push({
            ...variable,
            checked: isIncludeSystemFields && variable.isSystemColumn
          });
        }
      });

      domainColumnMap[domain.domainCode] = {
        ...domain,
        checked: true,
        exportVariables: _exportVariables
      };
    } else {
      domainColumnMap[domain.domainCode] = {
        ...domain,
        checked: false,
        exportVariables: domain.exportVariables?.map((variable) => ({
          ...variable,
          checked: isIncludeSystemFields && variable.isSystemColumn
        }))
      };
    }
  });
  return domainColumnMap;
};

const useExportSettings = (exportDetails) => {
  const { exportPreferences, destination } = exportDetails;
  const [domainAndColumns, setDomainAndColumns] = useState({});
  const [domainAndColumnsLoading, setDomainAndColumnsLoading] = useState(false);

  const [selectedExportType, setSelectedExportType] = useState(exportPreferences?.exportType || '');
  const [exportTypeTooltipMessage, setExportTypeTooltipMessage] = useState();
  const [exportTypeMetaDataLoading, setExportTypeMetaDataLoading] = useState(false);

  const [selectedScheduleExportFrequency, setSelectedScheduleExportFrequency] = useState(
    exportPreferences?.scheduleType || ''
  );
  const [scheduleFrequenciesLoading, setScheduleFrequenciesLoading] = useState(false);

  const [includeSystemFields, setIncludeSystemFields] = useState(true);

  const {
    selectSourceDetails,
    mappingSpecDetails,
    selectedSourceData,
    domainAndColumnMetaData = [],
    exportTypeMetaData = [],
    scheduleFrequencies = [],
    selectedPreferences
  } = useSelector((state) => state.PublishMappingSpecData);

  const dispatch = useDispatch();

  const getExportDomainsAndColumns = async () => {
    setDomainAndColumnsLoading(true);
    try {
      if (domainAndColumnMetaData?.length) {
        if (Object.keys(exportPreferences?.domainAndColumns || {}).length) {
          setDomainAndColumns(JSON.parse(JSON.stringify(exportPreferences?.domainAndColumns)));
          let _includeSystemFields = Object.values(exportPreferences?.domainAndColumns).some(
            (domain) =>
              domain.checked &&
              domain.exportVariables?.some((column) => column.checked && column.isSystemColumn)
          );
          console.log('_includeSystemFields', _includeSystemFields);
          setIncludeSystemFields(_includeSystemFields);
        } else {
          setDomainAndColumns(getInitialDomainColumns(domainAndColumnMetaData));
        }
      } else {
        const dataSourceIDObj = {};

        handleSelectedSources(selectSourceDetails)?.map((item) => {
          dataSourceIDObj[item.sourceName] = item.dataSourceID;
        });
        const trialLists = handleTrialItems(selectedSourceData);
        const selectedDataSets = selectSourceDetails?.masterDataSources
          ?.filter((item) => item.parentSourceName === 'CDR' && item.selected && item.connection)
          ?.map((el) => el.sourceName);

        const payload = {
          mappingRuleVersionID: mappingSpecDetails?.mappingRuleVersionID,
          dataSourceID: dataSourceIDObj,
          selectedDataSets: selectedDataSets,
          trialNames: trialLists,
          ecrfTrialName: getEcrfTrialName(selectedSourceData)
        };
        const domainsAndColumnsResp = await dispatch(GetExportDomainsAndColumns(payload)).then(
          unwrapResult
        );
        if (domainsAndColumnsResp?.data?.success) {
          dispatch(setDomainAndColumnMetaData(domainsAndColumnsResp?.data?.domainAndColumns));
          if (Object.keys(exportPreferences?.domainAndColumns || {}).length) {
            setDomainAndColumns(JSON.parse(JSON.stringify(exportPreferences?.domainAndColumns)));
            let _includeSystemFields = Object.values(exportPreferences?.domainAndColumns).some(
              (domain) =>
                domain.checked &&
                domain.exportVariables?.some((column) => column.checked && column.isSystemColumn)
            );
            console.log('_includeSystemFields', _includeSystemFields);
            setIncludeSystemFields(_includeSystemFields);
          } else {
            setDomainAndColumns(
              getInitialDomainColumns(domainsAndColumnsResp?.data?.domainAndColumns)
            );
          }
        } else {
          dispatch(showBanner({ variant: 'error', message: domainsAndColumnsResp?.data?.message }));
        }
      }
    } catch (error) {
      console.log('Error while fetching Domains and Columns :: ', error);
    }
    setDomainAndColumnsLoading(false);
  };

  const getExportType = async () => {
    setExportTypeMetaDataLoading(true);
    try {
      if (exportTypeMetaData?.length) {
        let tooltipMessage = exportTypeMetaData.map((e) => ({
          subtitle: `${e.exportTypeName} - ${e.toolTipMessage}. `
        }));
        setExportTypeTooltipMessage(tooltipMessage);
        selectedExportType || setSelectedExportType(exportTypeMetaData[0].exportTypeName);
      } else {
        const resp = await dispatch(GetExportTypes()).then(unwrapResult);
        if (resp?.data?.success) {
          let tooltipMessage = resp?.data.exportTypes.map((e) => ({
            subtitle: `${e.exportTypeName} - ${e.toolTipMessage}. `
          }));

          setExportTypeTooltipMessage(tooltipMessage);
          dispatch(setExportTypeMetaData(resp?.data.exportTypes));
          selectedExportType || setSelectedExportType(resp?.data.exportTypes[0].exportTypeName);
        } else {
          dispatch(showBanner({ variant: 'error', message: resp?.data?.message }));
        }
      }
    } catch (error) {
      console.log('Error while fetching Export Types :: ', error);
    }
    setExportTypeMetaDataLoading(false);
  };

  const getExportScheduleTypes = async () => {
    setScheduleFrequenciesLoading(true);
    try {
      if (!scheduleFrequencies?.length) {
        const resp = await dispatch(GetExportScheduleTypes()).then(unwrapResult);
        if (resp?.data?.success) {
          dispatch(setScheduleFrequencies(resp?.data?.exportScheduleTypes));
        } else {
          dispatch(showBanner({ variant: 'error', message: resp?.data?.message }));
        }
      }
    } catch (error) {
      console.log('Error while fetching Export Schedule Types :: ', error);
    }
    setScheduleFrequenciesLoading(false);
  };

  const handleReset = () => {
    setDomainAndColumns(getInitialDomainColumns(domainAndColumnMetaData));
    setIncludeSystemFields(true);
    setSelectedExportType(exportTypeMetaData[0].exportTypeName);
    setSelectedScheduleExportFrequency('');
  };

  const loadSelectedPreference = async (preferenceId) => {
    setExportTypeMetaDataLoading(true);
    setScheduleFrequenciesLoading(true);
    setDomainAndColumnsLoading(true);
    const resp = await dispatch(GetSelectedPreferencesData(preferenceId)).then(unwrapResult);
    if (resp?.data?.success) {
      //Set include system fields switch
      let _includeSystemFields = Object.values(resp.data.exportPreferencesData).some((domain) =>
        domain.variableInformation?.some((column) => column.isSystemColumn)
      );
      setIncludeSystemFields(_includeSystemFields);

      //Set Export Type
      const exportTypeId = resp.data.exportPreference?.exportTypeID;
      const exportTypeName =
        exportTypeMetaData.find((el) => el.exportTypeId === exportTypeId)?.exportTypeName ||
        exportTypeMetaData[0].exportTypeName;
      setSelectedExportType(exportTypeName);

      //Set Schedule Export Frequency
      const scheduleTypeID = resp.data.exportPreference?.scheduleTypeID;
      const exportSchedule =
        scheduleFrequencies.find((el) => el.scheduleTypeID === scheduleTypeID)?.scheduleFrequency ||
        '';
      setSelectedScheduleExportFrequency(exportSchedule);

      //Set Domain and coulmns
      const _domainColumnsFromLoad = getLoadPreferenceDomainColumns(
        domainAndColumnMetaData,
        resp.data.exportPreferencesData,
        _includeSystemFields
      );
      setDomainAndColumns(_domainColumnsFromLoad);

      //update selected Preference
      dispatch(
        setSelectedPreferences({
          ...selectedPreferences,
          [destination]: {
            exportType: exportTypeName,
            scheduleType: exportSchedule,
            domainInformation: JSON.parse(JSON.stringify(_domainColumnsFromLoad))
          }
        })
      );
    }
    setExportTypeMetaDataLoading(false);
    setScheduleFrequenciesLoading(false);
    setDomainAndColumnsLoading(false);
  };

  useEffect(() => {
    getExportDomainsAndColumns();
    getExportType();
    getExportScheduleTypes();
  }, []);

  return {
    domainAndColumns,
    setDomainAndColumns,
    domainAndColumnsLoading,

    exportTypeMetaData,
    selectedExportType,
    setSelectedExportType,
    exportTypeTooltipMessage,
    exportTypeMetaDataLoading,

    scheduleFrequencies,
    selectedScheduleExportFrequency,
    setSelectedScheduleExportFrequency,
    scheduleFrequenciesLoading,

    handleReset,

    includeSystemFields,
    setIncludeSystemFields,

    loadSelectedPreference
  };
};

export default useExportSettings;
