export const ErrorConstants = {
  uniqueError: 'It must be unique',
  uniqueCodeListError: 'Combination of $odmForm, $itemOid and $value must be unique',
  uniqueGenericCodeListError: 'Combination of $odmCodeListname and $value must be unique',
  uniqueTrialArmsError: 'Combination of $armcd and $taetord must be unique',
  trialVisitUniqueError: 'visit,visitnum,arm,armcd,tvclrid,tvgrpid must be unique',
  timePointsUniqueError: 'visit,visitnum,tvgrpid,tvclrid,tptmref,tptmpnt,tptmnum must be unique',
  ctpSvtUniqueError: 'tvgrpid,tvclrid must be unique',
  flagValueError: 'It can have Yes or No only',
  yesNoValueError: 'It can have Y or N only',
  numberError: 'It must be a number greater than 0',
  mandatoryError: "It's a mandatory Value",
  invalidVisitError: 'visit is invalid.',
  blankError: 'It must be blank for unplanned visit',
  referencePointError: 'It must be valid visit name or "$MAXVISITDY" function',
  variableNameError: 'Should not exceed more than 8 characters ',
  variableLabelError: 'Should not exceed more than 40 characters ',
  validVariableNameError: 'Invalid Variable Name',
  blankOrFilled: 'tptmref & tptmpnt & tptmnum must be filled or left blank',
  nameAndNumberMapError: 'tptmpnt must mapped to one tptmnum',
  stringError: 'It must be a string',
  domainError: 'It must be acording to the standard Domain Rules',
  unplannedVisitDescBlankError: 'It must be blank for planned visit',
  qecgError: 'Invalid QECG Value',
  qlabError: 'Invalid QLAB Value',
  edcError: 'Invalid EDC value',
  edcBlankError: 'Please select EDC source',
  qecgBlankError: 'Please select QECG source',
  qlabBlankError: 'Please select QLAB source',
  cdrBlankError: 'Please select CDR source',
  variableNameUniqueError: 'Variable Name must be unique',
  trialElementUniqueError: 'Element code should be unique',
  trialIEUniqueError: 'IETESTCD should be unique',
  IETESTCDValidationError: 'IETESTCD cannot start with a number and also exceed 8 characters',
  trialIEColumnError: 'IETESTCD cannot start with a number',
  validIetestcdError: 'IETESTCD should be a combination of numbers or letters or underscore',
  trialIELengthError: 'IETESTCD should not exceed 8 characters',
  etcdLengthError: 'ETCD should not exceed 8 characters',
  visitTypeNameError: 'It does not match with source meta data',
  visitNameSeqNumError: 'INVALID $SEQNUM',
  ctpVisitNameError: 'VISIT NAME should be unique',
  mandatoryErrorCDR: 'CDRDEFAULTFORM should be provided for every Visit name',
  mandatoryErrorEDC: 'EDCDEFAULTFORM should be provided for every Visit name',
  mandatoryErrorEDCorCDR:
    'Either EDCDEFAULTFORM or CDRDEFAULTFORM is mandatory for every Visit Name'
};
