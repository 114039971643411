/*eslint-disable*/
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Trash from 'apollo-react-icons/Trash';
import ApolloCard from 'apollo-react/components/Card';
import IconButton from 'apollo-react/components/IconButton';
import Tooltip from 'apollo-react/components/Tooltip';
import Button from 'apollo-react/components/Button';
import SplitButton from 'apollo-react/components/SplitButton';
import React from 'react';
const useStyles = makeStyles({
  splitButton:{
    boxShadow: 'none',
    '& .css-rk5m0a-MuiButtonBase-root-MuiButton-root':{
      border: '#ffffff'
    }
   },
  wrapper: {
    maxWidth: '325px',
    height: '310px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative'
  },
  title: {
    fontWeight: 500,
    fontSize: '13px',
    marginLeft: '12px',
    marginTop: '25px',
    color: '#0768FD',
    textDecoration: 'underline',
    cursor: 'pointer',
    textDecorationStyle: 'dotted',
    textUnderlinePosition: 'under'
  },
  footer: {
    color: '#0768FD',
    marginLeft: '12px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
    color: '#bad4fd'
  }
});

const Card = ({
  children,
  handleDownload,
  isEditDisable,
  handleEditPreview,
  setErrorFlag,
  refMsg,
  cardName,
  cardTitle,
  isCtmsConfigured=true,
  isCtmsmetadataSuccess,
  required,
  handleImportStudyLibrary,
  isEdcInfoReady,
  deleteCardInParent,
  disableImportText,
  targetDataModelType,
  handleTimePointsSheetDownload  
}) => {
  const classes = useStyles();
  const deleteCard = async () => {
    deleteCardInParent();
  };
  const { selectedRowsForSave } = useSelector((state) => state.RuleEditorData);  
  const options = [
    {
      label: 'Download Excel Template',
      onClick: handleDownload,
    },
    {
      label: 'Download Source Events Sheet',
      onClick: handleTimePointsSheetDownload,
    },
  ];

  return (
    <ApolloCard className={classes.wrapper}>
      {refMsg && (
        <Tooltip title={'Issues detected'} subtitle={refMsg}>
          <StatusExclamation
            style={{
              color: 'red',
              position: 'absolute',
              top: '15px',
              right: '16px'
            }}
          />
        </Tooltip>
      )}
      {!required && (
        <IconButton
          onClick={deleteCard}
          style={{ position: 'absolute', top: '10px', right: '0px' }}>
          <Trash />
        </IconButton>
      )}
      {(cardTitle === 'CTP SVT' || cardTitle === 'Time Points') &&
      !selectedRowsForSave.every((item) => item.libraryType === 'CDR Tabular') ? (
        <div style={{ marginLeft: '4px', marginTop: '15px' }}>
          <SplitButton
            className={classes.splitButton}
            label="Download Excel Template"
            onClick={handleDownload}
            variant="secondary"
            size="small"
            menuItems={options}
          />
        </div>
      ) : (
        <span className={classes.title} onClick={handleDownload}>
          Download Excel Template
        </span>
      )}
      {children}

      <a
        id="anchor-preview"
        className={`${classes.footer} ${isEditDisable ? classes.disabled : ''}`}
        onClick={handleEditPreview}>
        {!isEditDisable && setErrorFlag ? (
          <Tooltip title={'Issues detected'} subtitle={'Fix data table issues to continue'}>
            <span className={classes.title}>
              <StatusExclamation
                style={{
                  float: 'left',
                  height: '18px',
                  marginTop: '2px',
                  color: 'red'
                }}
              />
              Fix data tables issues
            </span>
          </Tooltip>
        ) : !isEditDisable &&
          !setErrorFlag &&
          (isEdcInfoReady !== undefined ? isEdcInfoReady : true) && !isCtmsmetadataSuccess ? (
          'View/Edit Data Table'
        ) : (
          ''
        )}
      </a>

      {targetDataModelType !== 'Ruleset' && isEditDisable && cardName === 'Code Lists' && (
        <Button
          style={{ position: 'absolute', bottom: '4px', left: '0px' }}
          variant="text"
          id="anchor-preview"
          className={`${classes.footer}`}
          onClick={() => handleImportStudyLibrary('import', true)}
          disabled={disableImportText}>
          <span style={{ justifyContent: 'flex-start', color: '#0768FD' }}>
            Import from Study Library
          </span>
        </Button>
      )}
    </ApolloCard>
  );
};

export default Card;
