import { astVisitor, parse } from 'pgsql-ast-parser';

const PREFIX_STATEMENT = 'select * from META_TABLE where ';
const ERROR_LINE_PRE_MSG = 'line 1 col ';
const ERROR_SYNTAX_UNEXPECTED_FORMAT = 'Unexpected end of input';
const ERROR_AMBIQUOUS_SYNTAX = 'Ambiguous SQL syntax';
const ERROR_SYNTAX_UNEXPECTED_SELECT = 'Unexpected kw_select token';
const STRING_LITERAL_PLACEHOLDER = "'__STRING_LITERAL__'";

const VALID_EXPRESSION_TYPES = ['binary', 'unary'];
const STRING_LITERAL_REGEX = "(\\'[\\w\\s\\$~&+,:;=?@#|`\"'<>.^\\*(){}\\[\\]%!-\\\\/]+\\')";
const VALID_IN_OP_REGEX = '([\\s\\n]in[\\s\\n]*\\()';
const IN_OP_REGEX = '([\\s\\n]in[\\s\\n]*)';
const MESSAGE_COLUMNS_REGEX = '<[\\w\\s\\$~&+,:;=?@#|`"\'.^\\*(){}\\[\\]%!-/\\\\]*>';
const ANGLE_BRACES_REGEX = '[<>]';

const ERROR_INVALID_SYNTAX = 'Incorrect syntax! \nProvide a valid VLC Rule.';
const ERROR_ONLY_ONE_EXP_ALLOWED = 'Incorrect syntax! \nOnly one expression is allowed at a time.';
const ERROR_SUBQURIES_NOT_ALLOWED = 'Incorrect syntax! \nSubqueries are not allowed.';
const ERROR_INTEGER_NOT_ALLOWED_WITH_LIKE =
  'Incorrect syntax! \nInteger is not allowed with LIKE/NOT LIKE operators.';
const ERROR_LIST_REQUIRED_WITH_IN =
  'Incorrect syntax! \nColumn, literal or null usage is not allowed with IN/NOT IN operators. Only list type is allowed.';

const getInvalidRefErrorMsg = (name) =>
  `Incorrect syntax! \nColumn/literal/null reference provided: <strong>[${name}]</strong>.\nOnly boolean operations are allowed.`;
const getInvalidColumnErrorMsg = (columns) =>
  `The following column references are not valid for the selected dataset: <strong>[${columns.join(
    ', '
  )}]</strong>.`;

/**
 * To validate if more than 1 expression is provided in the input
 * @param {Array} multipleAst
 */
const validateMultipleExpressions = (multipleAst) => {
  if (multipleAst?.length) {
    throw ERROR_ONLY_ONE_EXP_ALLOWED;
  }
};

/**
 * To validate if in the gien query the IN/NOT IN operator is used with correct syntax
 * @param {string} query
 */
const validateInOperatorSyntax = (query) => {
  let formatedQuery = query.replaceAll(
    new RegExp(STRING_LITERAL_REGEX, 'gi'),
    STRING_LITERAL_PLACEHOLDER
  );
  const IN_OP_MATCHES = formatedQuery.match(new RegExp(IN_OP_REGEX, 'gi'));
  const VALID_IN_OP_MATCHES = formatedQuery.match(new RegExp(VALID_IN_OP_REGEX, 'gi'));

  if (IN_OP_MATCHES?.length !== VALID_IN_OP_MATCHES?.length) {
    throw ERROR_LIST_REQUIRED_WITH_IN;
  }
};

/**
 * To validate if the given query contains any subqueries or any other incorrect syntax
 * @param {object} expTree
 */
const validateSyntax = (expTree) => {
  const validateTypes = (operation) => {
    if (!VALID_EXPRESSION_TYPES.includes(operation.type)) {
      if (operation.type === 'select') {
        throw ERROR_SUBQURIES_NOT_ALLOWED;
      } else if (operation.type === 'ref') {
        throw getInvalidRefErrorMsg(operation.name);
      } else if (operation.type === 'integer') {
        throw getInvalidRefErrorMsg(operation.value);
      } else if (operation.type === 'null') {
        throw getInvalidRefErrorMsg('null');
      } else {
        throw ERROR_INVALID_SYNTAX;
      }
    }
  };

  const validateListTypes = (reference) => {
    if (reference.type === 'list') {
      if (reference.expressions?.find((exp) => exp.type === 'select')) {
        throw ERROR_SUBQURIES_NOT_ALLOWED;
      }
    } else if (reference.type === 'select') {
      throw ERROR_SUBQURIES_NOT_ALLOWED;
    }
  };

  const validateLikeTypes = (reference) => {
    if (reference.type === 'integer') {
      throw ERROR_INTEGER_NOT_ALLOWED_WITH_LIKE;
    } else if (reference.type === 'select') {
      throw ERROR_SUBQURIES_NOT_ALLOWED;
    }
  };

  validateTypes(expTree);
  if (expTree.type !== 'unary') {
    if (expTree.op === 'AND' || expTree.op === 'OR') {
      validateSyntax(expTree.left);
      validateSyntax(expTree.right);
    } else if (expTree.op === 'IN' || expTree.op === 'NOT IN') {
      validateListTypes(expTree.left);
      validateListTypes(expTree.right);
    } else if (expTree.op === 'LIKE' || expTree.op === 'NOT LIKE') {
      console.log('expTree', expTree);
      validateLikeTypes(expTree.left);
      validateLikeTypes(expTree.right);
    } else if (expTree.left.type === 'select' || expTree.right.type === 'select') {
      throw ERROR_SUBQURIES_NOT_ALLOWED;
    } else if (
      expTree.left.type === 'binary' ||
      expTree.left.type === 'unary' ||
      expTree.right.type === 'binary' ||
      expTree.right.type === 'unary'
    ) {
      throw ERROR_INVALID_SYNTAX;
    }
  }
};

/**
 * To validate if the given query has any invalid column references
 * @param {object} ast
 * @param {string[]} columnMetaData
 */
const validateMetaData = (ast, columnMetaData) => {
  const columnsInExp = {};
  const invalidColumns = [];
  const visitor = astVisitor(() => ({
    ref: (column) => {
      columnsInExp[column.name?.toUpperCase()] = true;
    }
  }));
  visitor.statement(ast);
  delete columnsInExp['*'];

  const columnMetaDataMap = new Map(columnMetaData?.map((column) => [column.toUpperCase(), true]));
  for (let column in columnsInExp) {
    if (!columnMetaDataMap.get(column)) invalidColumns.push(column);
  }
  if (invalidColumns?.length) {
    throw getInvalidColumnErrorMsg(invalidColumns);
  }
};

/**
 * To validate the given expression for proper syntax and against the provided columns metadata
 * @param {string} expression
 * @param {string[]} columnMetaData
 */
export const validateVLCRule = (expression, columnMetaData) => {
  const query = `${PREFIX_STATEMENT}${expression}`;
  const [ast, ...multipleAst] = parse(query);
  validateMultipleExpressions(multipleAst);
  validateSyntax(ast.where);
  validateInOperatorSyntax(query);
  validateMetaData(ast, columnMetaData);
};

/**
 * To validate the given action message against the provided columns metadata
 * @param {string} message
 * @param {string[]} columnMetaData
 */
export const validateVLCActionMessage = (message, columnMetaData) => {
  const columnMetaDataMap = new Map(
    columnMetaData?.map((column) => [`<${column.toUpperCase()}>`, true])
  );
  const invalidColumnsInMsg = message
    .match(new RegExp(MESSAGE_COLUMNS_REGEX, 'gi'))
    ?.filter((column) => !columnMetaDataMap.get(column.toUpperCase()))
    .map((column) => column.replaceAll(new RegExp(ANGLE_BRACES_REGEX, 'gi'), ''));

  if (invalidColumnsInMsg?.length) throw getInvalidColumnErrorMsg(invalidColumnsInMsg);
};

/**
 * To format the error into presentable message
 * @param {object | string} error
 * @returns {string}
 */
export const formatVLCErrorMessage = (error) => {
  let errorMessage = '';
  if (typeof error === 'string') {
    errorMessage = error;
  } else if (
    error.message.includes(ERROR_SYNTAX_UNEXPECTED_FORMAT) ||
    error?.message?.includes(ERROR_AMBIQUOUS_SYNTAX)
  ) {
    errorMessage = ERROR_INVALID_SYNTAX;
  } else if (error.message.includes(ERROR_SYNTAX_UNEXPECTED_SELECT)) {
    errorMessage = ERROR_SUBQURIES_NOT_ALLOWED;
  } else if (error?.message?.includes('Instead,')) {
    errorMessage = error.message.split('Instead,')[0];
  } else if (error?.message?.includes('I did not')) {
    errorMessage = error.message.split('I did not')[0];
  } else {
    errorMessage = error.message;
  }

  errorMessage = errorMessage.replace(PREFIX_STATEMENT, '');
  const lineNumIndex = errorMessage.indexOf(ERROR_LINE_PRE_MSG);
  if (lineNumIndex != -1) {
    const lineNumEndIndex = errorMessage.indexOf(':', lineNumIndex + ERROR_LINE_PRE_MSG.length);
    const errorColNumber = +errorMessage
      .substring(lineNumIndex + ERROR_LINE_PRE_MSG.length, lineNumEndIndex)
      .trim();
    errorMessage = errorMessage.replace(
      `${ERROR_LINE_PRE_MSG}${errorColNumber}:`,
      `${ERROR_LINE_PRE_MSG}${errorColNumber - PREFIX_STATEMENT.length}:`
    );
    errorMessage = errorMessage.replace(
      new RegExp(`[\\s]{${PREFIX_STATEMENT.length}}[\\^]{1}`),
      `^`
    );
  }
  return errorMessage;
};
