/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useOutletContext, useLocation, useNavigate } from 'react-router-dom';
import Typography from 'apollo-react/components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import Grid from 'apollo-react/components/Grid';
import { dateFormatByType, compareDates, dateFilter } from 'Utils';
import Table from 'apollo-react/components/Table/Table';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { showLoader, closeLoader } from 'Redux/Slice/LoaderSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Button from 'apollo-react/components/Button';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import Filter from 'apollo-react-icons/Filter';
import Tooltip from 'apollo-react/components/Tooltip';
import {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import IconButton from 'apollo-react/components/IconButton';
import { neutral7, neutral8 } from 'apollo-react/colors';
import { Radio } from 'apollo-react';
import { makeStyles } from '@mui/styles';
import StatusNegative from 'apollo-react-icons/StatusNegative';
import StudyInfo from 'Components/SourceStudyDisplay/StudyDisplayInfo';
import { getDataProductsForClone } from 'Redux/Service/CloneDataProductService';
import _ from 'lodash';
import { TableWrapper, Title, StudyInfoWrapper } from '../../Common';
import TextField from 'apollo-react/components/TextField';
import { DateFilter } from 'Components/Common/DateFilter';
import useDisplayName from 'Utils/useDisplayName';

const SelectDataProductMappingSpecs = () => {
  const { setNextDisabled } = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [dataProductsWithMapping, setDataProductsWithMapping] = useState([]);
  const { refreshDataProductsForClone } = useSelector((state) => state.CopyMappingSpecData);
  const [expandedRows, setExpandedRows] = useState([]);
  const dispatch = useDispatch();

  const mappingSpecVersionStyles = {
    headerCell: {
      color: neutral8,
      alignItems: 'center',
      display: 'flex',
      '& > p': { fontSize: '14px', fontWeight: 600 }
    },
    cell: {
      color: neutral7,
      alignItems: 'center',
      display: 'flex',
      '& > p': { fontSize: '14px', fontWeight: 400 }
    }
  };

  useEffect(() => {
    getDataProducts();
  }, [protocol, refreshDataProductsForClone]);

  useEffect(() => {
    _.isEmpty(location?.state?.selectedDataProduct)
      ? setNextDisabled(true)
      : setNextDisabled(false);
  }, []);

  const getDataProducts = async () => {
    try {
      dispatch(showLoader());
      let studyData;
      if (protocol && Object.entries(protocol).length > 0) {
        if (
          (protocol.isStudyLibraryConfigured || protocol.isDataSouceConfigured) &&
          protocol.isActive
        ) {
          studyData = await dispatch(
            getDataProductsForClone({
              sourceProtocol: location.state.selectedSourceStudy.protocolNumber.value,
              targetProtocol: protocol.protocolNumber
            })
          ).then(unwrapResult);
          console.log(':::::', studyData, protocol);
          if (studyData?.data?.success) {
            const { publishDataProducts } = studyData.data;
            const dataProductList = [];
            if (publishDataProducts.length > 0) {
              publishDataProducts.map(({ dataProduct, mappingSpecVersions }, index) => {
                dataProductList.push({
                  auditDate: dateFormatByType(dataProduct.auditDate, 'Table'),
                  description: dataProduct.description,
                  isCurrent: dataProduct.isCurrent === true ? 'Active' : 'Inactive',
                  productMnemonic: dataProduct.productMnemonic,
                  userID: dataProduct.userID,
                  productVersion: dataProduct.productVersion,
                  mappingRuleVersionStatus: dataProduct.mappingRuleVersionStatus,
                  productID: dataProduct.productId,
                  mappingSpecVersions,
                  protocolName: dataProduct.protocolNumber,
                  targetDataModelName:
                    dataProduct.targetDataModel !== '' && dataProduct.targetDataModel !== null
                      ? dataProduct.targetDataModel
                      : 'N/A',
                  targetDataModelVersion:
                    dataProduct.targetDataModelVersion !== '' &&
                    dataProduct.targetDataModelVersion !== null
                      ? dataProduct.targetDataModelVersion
                      : 'N/A',
                  targetDataModelType: dataProduct.targetDataModelType,
                  id: dataProduct.productId,
                  targetDataModelID: dataProduct.targetDataModelID
                });
              });

              setDataProductsWithMapping(dataProductList);
            }
          } else {
            dispatch(showBanner({ variant: 'error', message: studyData.data.message }));
          }
        }
      }
      dispatch(closeLoader());
    } catch (err) {
      dispatch(closeLoader());
      dispatch(showBanner({ variant: 'error', message: err.message }));
    }
  };

  const MappingSpecVersionsDetails = (props) => {
    const classes = makeStyles(mappingSpecVersionStyles)();
    const { mappingSpecVersions, viewMappingSpec, downloadMappingSpec, ...row } = props;
    const getDisplayName = useDisplayName();

    const renderDataSources = (sources) => {
      let notConnectedSources = Object.keys(sources).filter((source) => !sources[source]);
      return (
        <div>
          {notConnectedSources.length ? (
            <Tooltip
              variant="light"
              interactive={true}
              title={`${notConnectedSources.toString()} ${
                notConnectedSources.length > 1 ? 'are' : 'is'
              } not configured in the current study.`}
              subtitle={
                <a href="/data-standard-library/source-configuration" target="_blank">
                  View Source Configuration
                </a>
              }>
              <span style={{ float: 'left', marginTop: '2px' }}>
                <StatusNegative style={{ height: '15px', color: 'red' }} />
              </span>
            </Tooltip>
          ) : null}
          <span>
            {Object.keys(sources)
              .map((source) => getDisplayName(source))
              .toString()}
          </span>
        </div>
      );
    };

    const getMappingRuleID = (row, mappingSpecDetails) => {
      let newData = {
        selectedDataProduct: {
          productMnemonic: { label: 'Product Mnemonic', value: row.productMnemonic },
          mappingSpecVersionName: {
            label: 'Mapping Spec Version',
            value: mappingSpecDetails.mappingSpecVersionName
          },
          targetDataModelName: { label: 'Target Data Model Name', value: row.targetDataModelName }
        },
        mappingVersionID: mappingSpecDetails.mappingRuleVersionID,
        productID: row.productID,
        productVersion: row.productVersion,
        sourceProtocol: row.protocolName,
        productMnemonic: row.productMnemonic,
        targetDataModelID: row.targetDataModelID,
        targetDataModelVersion: row.targetDataModelVersion,
        targetDataModelName: row.targetDataModelName,
        targetDataModelType: row.targetDataModelType,
        cloneDataMappings: []
      };

      props.navigate('.', {
        state: { ...location.state, ...newData }
      });
      props.setNextDisabled(false);
    };

    return (
      <Grid container style={{ backgroundColor: '#f8f9fb' }}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '10px 2%',
            padding: '10px'
          }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} className={classes.headerCell}>
            <Typography>{'Mapping Spec Version'}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.headerCell}>
            <Typography>{'Created By'}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.headerCell}>
            <Typography>{'Title'}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.headerCell}>
            <Typography>{'Date Created'}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.headerCell}>
            <Typography>{'Data Sources'}</Typography>
          </Grid>
        </Grid>

        {mappingSpecVersions.map((version) => {
          return (
            <Grid
              key={version.mappingSpecVersionName}
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '10px 3% 10px 3%'
              }}>
              <Grid item xs={1} className={classes.cell}>
                <Tooltip
                  variant="light"
                  interactive={true}
                  title={row.error && 'Align data sources to enable selection'}>
                  <span>
                    <Radio
                      disabled={Object.keys(version.sources).some(
                        (source) => !version.sources[source]
                      )}
                      style={{ marginBottom: '15px' }}
                      checked={location?.state?.mappingVersionID === version.mappingRuleVersionID}
                      onChange={() => getMappingRuleID(row, version)}
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{version.mappingSpecVersionName}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{version.createdBy}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{version.title}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{dateFormatByType(version.createdDate, 'Table')}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{renderDataSources(version.sources)}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const DetailRow = ({ row }) => {
    return (
      <MappingSpecVersionsDetails {...row} navigate={navigate} setNextDisabled={setNextDisabled} />
    );
  };

  const CustomButtonHeader = ({ toggleFilters }) => {
    return (
      <div>
        <Button
          size="small"
          variant="secondary"
          icon={Filter}
          style={{ marginRight: 15 }}
          onClick={toggleFilters}>
          Filter
        </Button>
      </div>
    );
  };

  const handleToggleRow = (rowId) => {
    setExpandedRows((expandedRows) =>
      expandedRows.includes(rowId)
        ? expandedRows.filter((id) => id !== rowId)
        : [...expandedRows, rowId]
    );
  };

  const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
    return (
      <TextField
        value={filters[accessor]}
        name={accessor}
        onChange={updateFilterValue}
        fullWidth
        margin="none"
        size="small"
      />
    );
  };

  const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
    return (
      <TextField
        value={filters[accessor]}
        name={accessor}
        onChange={updateFilterValue}
        type="number"
        style={{ width: '118px' }}
        margin="none"
        size="small"
      />
    );
  };

  const ExpandCell = ({ row: { id, handleToggleRow, expanded } }) => {
    return (
      <div style={{ width: 12, marginRight: '16px' }}>
        <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
          <IconButton id="expand" size="small" onClick={() => handleToggleRow(id)}>
            {expanded ? <ChevronDown /> : <ChevronRight />}
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const EditableCell = ({ row, column: { accessor: key } }) => {
    return row.editMode ? (
      <TextField
        size="small"
        fullWidth
        value={row.editedRow[key]}
        onChange={(e) => {
          row.editRow(key, e.target.value);
        }}
        //error={!row.editedRow[key]}
        //helperText={!row.editedRow[key] && 'Required'}
        {...fieldStyles}
      />
    ) : (
      row[key]
    );
  };

  const VersionCell = ({ row, column: { accessor } }) => {
    const versionRight = row[accessor];
    return (
      <Typography
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '24px',
          color: '#595959',
          fontSize: '14px'
        }}>
        {versionRight}
      </Typography>
    );
  };

  const columns = [
    {
      accessor: 'expand',
      customCell: ExpandCell
    },
    {
      header: 'Product Mnemonic',
      accessor: 'productMnemonic',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('productMnemonic'),
      fixedWidth: false
    },
    {
      header: 'Description',
      accessor: 'description',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('description'),
      customCell: EditableCell,
      fixedWidth: false
    },
    {
      header: 'Target Data Model Name',
      accessor: 'targetDataModelName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('targetDataModelName'),
      fixedWidth: false
    },
    {
      header: 'Target Data Model Version',
      accessor: 'targetDataModelVersion',
      sortFunction: compareNumbers,
      filterComponent: IntegerFilter,
      filterFunction: numberSearchFilter('targetDataModelVersion'),
      customCell: VersionCell,
      width: 120,
      fixedWidth: false
    },
    {
      header: 'Last Updated User',
      accessor: 'userID',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('userID'),
      fixedWidth: false
    },
    {
      header: 'Last updated Date',
      accessor: 'auditDate',
      sortFunction: compareDates,
      filterFunction: dateFilter('auditDate'),
      filterComponent: DateFilter,
      fixedWidth: false
    }
  ];

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Title>Select Data Product and Mapping Spec</Title>
        </Grid>
        <StudyInfoWrapper item xs={12}>
          <StudyInfo
            productInfo={location?.state?.selectedSourceStudy}
            label={'Source Study Information'}
          />
        </StudyInfoWrapper>
        <TableWrapper item xs={12}>
          <Table
            title={<div style={{ fontSize: '16px' }}>Data Products</div>}
            subtitle={
              <div>
                <Typography variant="body2" gutterBottom>
                  Select a mapping spec from the existing data products to use in the draft of your
                  new data product.
                </Typography>
              </div>
            }
            rows={
              dataProductsWithMapping &&
              dataProductsWithMapping.map((row, index) => {
                const rowData = {
                  ...row,
                  expanded: expandedRows.includes(row.id) ? true : false,
                  handleToggleRow,
                  key: index
                };
                return rowData;
              })
            }
            ExpandableComponent={DetailRow}
            columns={columns}
            initialSortedColumn="productMnemonic"
            initialSortOrder="asc"
            CustomHeader={(props) => <CustomButtonHeader {...props} />}
            rowsPerPageOptions={[10, 20, 50, 100, 'All']}
            tablePaginationProps={{
              truncate: true
            }}
            hasScroll
            maxHeight={650}
          />
        </TableWrapper>
      </Grid>
    </>
  );
};
export default SelectDataProductMappingSpecs;
