/* eslint-disable no-unused-vars */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Download from 'apollo-react-icons/Download';
import Filter from 'apollo-react-icons/Filter';
import { jsonToExcel, jsonToFileData, uuidv4 } from 'Utils';
import PlusIcon from 'apollo-react-icons/Plus';
import CustomModal from 'Components/Modal';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Trash from 'apollo-react-icons/Trash';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import { unwrapResult } from '@reduxjs/toolkit';
import Grid from 'apollo-react/components/Grid';
import Switch from 'apollo-react/components/Switch';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import { getTrialVisit } from 'Redux/Service/ReferenceDataCardsService';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveTrialVisits } from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { trialVisitValidator } from 'Validators/TrialVisits.Validator';
import { TableNewRow, useNewRow } from './TableNewRow';
import { useNavigate, useParams } from 'react-router-dom';
import { getReferenceData, setReferenceData } from 'service/reference-data.service';
import useGetTitle from '../../../hooks/useGetTitle';
import Loader from 'Components/Loader/Loader';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Cookies from 'js-cookie';
const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  }
});

const mapping_obj = {
  visit: '',
  visitnum: '',
  visitdy: '',
  armcd: '',
  arm: '',
  epoch: '',
  tvpsttol: '',
  tvpentol: '',
  tvmsttol: '',
  tvmentol: '',
  tvrfpnt: '',
  tvgrpid: '',
  tvclrid: '',
  tvblflg: '',
  tvenrl: '',
  tvstrl: '',
  tvupflg: ''
};

const fieldStyles = {
  style: {
    marginTop: 3,
    marginLeft: -8
  }
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};
const isNull = (value) => {
  if (value === null) {
    return true;
  }
  return false;
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const TrialVisitsPreview = () => {
  const { cardTitle } = useGetTitle('trial-visits');
  const params = useParams();
  const navigate = useNavigate();
  const mappingId = params.id;
  const [trialVisitData, setTrialVisitData] = useState([]);
  const [trialVisitValidationResult, setTrialVisitValidationResult] = useState({});
  const [error, setError] = useState();
  const [isPreview, setIsPreview] = useState(false);
  const [isPreviewDataUploaded, setIsPreviewDataUploaded] = useState();
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [trialVisitMessage, setTrialVisitMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [editedRows, setEditedRows] = useState([]);
  const [newRow, setNewRow, editNewRow] = useNewRow({});
  const [isAdding, setIsAdding] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);

  const [resetPage, setPageReset] = useState(false);
  const [rows, setRows] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const userId = Cookies.get('user.id');

  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [errorRowId, setErrorRowId] = useState({});
  const [errorCount, setErrorCount] = useState({});
  const TRIAL_VISIT = 'trial-visit';
  const TRIAL_VISIT_VALIDATION_RESULT = 'trial-visit-validationResult';
  const editMode = editedRows.length > 0;

  const classes = useStyles();

  //For validation and fetching again
  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingId
      };
      if (getReferenceData(TRIAL_VISIT) && getReferenceData(TRIAL_VISIT).length > 0) {
        setTrialVisitData(getReferenceData(TRIAL_VISIT));
        const validationResult = getReferenceData(TRIAL_VISIT_VALIDATION_RESULT);
        setTrialVisitValidationResult(validationResult);
        setIsLoading(false);
        setEnableSave(true);
        setIsPreviewDataUploaded(true);
      } else {
        const trialVisitGetAPIData = await dispatch(getTrialVisit(payload)).then(unwrapResult);
        if (
          trialVisitGetAPIData &&
          trialVisitGetAPIData.data &&
          trialVisitGetAPIData.data.success
        ) {
          if (
            trialVisitGetAPIData.data.trialVisits &&
            trialVisitGetAPIData.data.trialVisits.length > 0
          ) {
            setError('');
            const newData = trialVisitGetAPIData.data.trialVisits.map((item) => {
              return {
                visit: item.visit,
                visitnum: item.visitnum,
                visitdy: isNull(item.visitdy) ? '' : item.visitdy,
                armcd: item.armcd,
                arm: item.arm,
                epoch: item.epoch,
                tvpsttol: isNull(item.tvpsttol) ? '' : item.tvpsttol,
                tvpentol: isNull(item.tvpentol) ? '' : item.tvpentol,
                tvmsttol: isNull(item.tvmsttol) ? '' : item.tvmsttol,
                tvmentol: isNull(item.tvmentol) ? '' : item.tvmentol,
                tvrfpnt: item.tvrfpnt,
                tvgrpid: item.tvgrpid,
                tvclrid: item.tvclrid,
                tvblflg: item.tvblflg,
                tvenrl: item.tvenrl,
                tvstrl: item.tvstrl,
                tvupflg: item.tvupflg,
                id: uuidv4()
              };
            });
            let validationResult = trialVisitValidator(newData);
            setTrialVisitValidationResult(validationResult);
            setTrialVisitData(newData);
            setIsLoading(false);
            setIsPreviewDataUploaded(false);
          } else {
            setIsLoading(false);
            setTrialVisitData([]);
          }
        } else {
          setIsLoading(false);
          setTrialVisitMessage(trialVisitGetAPIData.data.message);
          setTrialVisitData([]);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (trialVisitValidationResult && Object.keys(trialVisitValidationResult).length > 0) {
      setErrorRowId(trialVisitValidationResult && trialVisitValidationResult.getErrorRowId());
      setErrorCount(trialVisitValidationResult && trialVisitValidationResult.getErrorCount());
    }
  }, [trialVisitValidationResult]);

  useEffect(() => {
    if (errorFlag) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = trialVisitData.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setRows(filteredData);
    } else {
      setRows(trialVisitData);
    }
  }, [trialVisitData]);

  useEffect(() => {
    setPageReset(true);
    setImmediate(() => {
      setPageReset(false);
    });
  }, [errorFlag]);

  useEffect(() => {
    if (errorFlag && !editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = trialVisitData.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setRows(filteredData);
    } else if (!errorFlag && !editMode) {
      setRows(trialVisitData);
    } else if (errorFlag && editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = editedRows.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setEditedRows(filteredData);
    } else if (!errorFlag && editMode) {
      if (editedRows.length > 0 && trialVisitData.length > 0) {
        const idToIndexMap = {};
        const arr = [...trialVisitData];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            arr[idToIndexMap[data.id]] = data;
          } else {
            arr.push(data);
          }
        });
        setEditedRows(arr);
      }
    }
  }, [errorFlag, editMode]);
  const getSavePayload = (data, auditType) => {
    const trialVisits = data.map((item) => {
      let {
        visit,
        visitnum,
        visitdy,
        armcd,
        arm,
        epoch,
        tvpsttol,
        tvpentol,
        tvmsttol,
        tvmentol,
        tvrfpnt,
        tvgrpid,
        tvclrid,
        tvblflg,
        tvenrl,
        tvstrl,
        tvupflg
      } = item;
      return {
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: auditType,
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        mappingTrialVisitID: 'TV',
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionId: mappingId,
        domainName: '',
        visit: visit,
        visitnum: visitnum,
        visitdy: visitdy ? visitdy : null,
        armcd: armcd,
        arm: arm,
        epoch: epoch,
        tvpsttol: tvpsttol ? tvpsttol : null,
        tvpentol: tvpentol ? tvpentol : null,
        tvmsttol: tvmsttol ? tvmsttol : null,
        tvmentol: tvmentol ? tvmentol : null,
        tvrfpnt: tvrfpnt,
        tvgrpid: tvgrpid,
        tvclrid: tvclrid,
        tvblflg: tvblflg,
        tvenrl: tvenrl,
        tvstrl: tvstrl,
        tvupflg: tvupflg
      };
    });
    const payload = {
      trialVisits
    };
    return payload;
  };

  const onCancel = () => {
    setEditedRows([]);
  };
  const onSave = async () => {
    let payload;
    let validationResult;
    let newData = [...trialVisitData];
    setOpenConfirmModal(false);
    if (editMode && !errorFlag) {
      validationResult = trialVisitValidator(editedRows);
      setTrialVisitValidationResult(validationResult);
      payload = getSavePayload(editedRows, 'UPDATE');
    }
    // else if (isAdding) {
    //   const trialVisitDataNew = [{ ...newRow, id: uuidv4() }, ...trialVisitData];
    //   validationResult = trialVisitValidator(trialVisitDataNew);
    //   setTrialVisitValidationResult(validationResult);
    //   // setTrialVisitData(trialVisitDataNew);
    //   payload = getSavePayload(trialVisitDataNew, 'INSERT');
    // }
    else if (editMode && errorFlag) {
      const idToIndexMap = {};
      if (editedRows.length > 0 && trialVisitData.length > 0) {
        const arr = [...trialVisitData];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            newData[idToIndexMap[data.id]] = data;
          } else {
            newData.push(data);
          }
        });
      }
      if (newData.length > 0) {
        validationResult = trialVisitValidator(newData);
        setTrialVisitValidationResult(validationResult);
        payload = getSavePayload(newData, 'INSERT');
      }
    } else {
      validationResult = trialVisitValidator(trialVisitData);
      setTrialVisitValidationResult(validationResult);
      payload = getSavePayload(trialVisitData, 'INSERT');
    }

    if (validationResult?.getErrorCount() > 0) {
      if (editMode) {
        setRows(editedRows);
        setTrialVisitData(editedRows);
        if (errorFlag) {
          setTrialVisitData(newData);
          setRows(newData);
        }
      } else {
        setRows(rows);
      }

      dispatch(showBanner({ variant: 'error', message: 'Data still has some error' }));
    } else {
      const saveResponse = await dispatch(saveTrialVisits(payload));
      if (
        saveResponse &&
        saveResponse.payload &&
        saveResponse.payload.data &&
        saveResponse.payload.data.success
      ) {
        setReferenceData(TRIAL_VISIT, []);
        setReferenceData(TRIAL_VISIT_VALIDATION_RESULT, {});
        if (editMode && !errorFlag) {
          setRows(editedRows);
          setTrialVisitData(editedRows);
        } else if (editMode && errorFlag) {
          setRows(newData);
          setTrialVisitData(newData);
        } else {
          setRows(rows);
          setTrialVisitData(rows);
        }
        setIsPreviewDataUploaded(false);
        dispatch(showBanner({ variant: 'success', message: saveResponse.payload.data.message }));
      } else {
        if (editMode) {
          setRows(editedRows);
        } else {
          setRows(trialVisitData);
        }

        dispatch(showBanner({ variant: 'error', message: 'something went wrong' }));
      }
      setEditedRows([]);
      setEnableSave(false);
    }
  };
  const getconfirmationBeforeSave = () => {
    setConfirmAlert({
      enabled: true,
      title: 'Do you want to Save the Data?',
      message:
        'Please check the information before submitting as the edits/deletes cannot be retrieved once the data is saved',
      variant: 'warning',
      onConfirm: () => {
        onSave();
        setConfirmAlert({
          enabled: false
        });
      },
      onCancel: () => {
        setOpenConfirmModal(false);
        setConfirmAlert({
          enabled: false
        });
      }
    });
  };

  useEffect(() => {
    if (openConfirmModal) {
      getconfirmationBeforeSave();
    }
  }, [openConfirmModal]);

  const CustomButtonHeader = useCallback((props) => {
    const {
      onEditAll,
      toggleFilters,
      trialVisitData,
      // handleStepValue,
      handleShowPreview,
      setIsPreview,
      errorCount,
      setIsAdding,
      isAdding,
      setConfirmAlert,
      isPreviewDataUploaded
    } = props;

    const onHandleDownload = () => {
      let newFileArr = [];
      trialVisitData.map((obj) => {
        delete obj['id'];
        let upperObj = _.transform(obj, function (result, val, key) {
          result[key.toUpperCase()] = val;
        });
        newFileArr.push(upperObj);
      });
      jsonToExcel(newFileArr, 'TrialVisits.xlsx');
    };

    const handleErrorData = (e) => {
      setErrorFlag(e.target.checked);
    };

    const returnToReferenceData = () => {
      if (isPreviewDataUploaded) {
        setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to return to reference data?',
          message: 'Uploaded data would not saved',
          variant: 'warning',
          onConfirm: () => {
            setReferenceData(TRIAL_VISIT, []);
            setReferenceData(TRIAL_VISIT_VALIDATION_RESULT, {});
            navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
            setIsPreview(false);

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else if (errorCount) {
        setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to return to reference data?',
          message: 'Added or Edited data would not be saved',
          variant: 'warning',
          onConfirm: () => {
            navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
            setIsPreview(false);

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else {
        navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
        setIsPreview(false);
      }
    };

    return (
      <>
        <div style={{ width: '100%' }}>
          <div>
            <Button
              icon={<ChevronLeft />}
              size="small"
              onClick={returnToReferenceData}
              style={{
                marginRight: 10,
                marginBottom: '10px',
                marginTop: '10px'
              }}>
              Return to reference data upload
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 600,
                      fontSize: '20px',
                      float: 'left',
                      marginRight: '20px'
                    }}>
                    {cardTitle && cardTitle.length > 0 && cardTitle[0].displayName}
                  </Typography>
                  {errorCount > 0 && (
                    <div
                      style={{
                        backgroundColor: '#E20000',
                        float: 'left',
                        width: '83px',
                        paddingBottom: '2px',
                        paddingLeft: '2px',
                        border: '1px solid white',
                        borderRadius: '4px'
                      }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 300, fontSize: '14px', marginTop: '1px' }}>
                        <span
                          style={{
                            color: 'white'
                          }}>
                          <StatusExclamation
                            style={{
                              color: 'white',
                              float: 'left',
                              height: '16px',
                              marginTop: '2px'
                            }}
                          />
                          {`${errorCount} errors`}
                        </span>
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item xs={9} style={{ marginRight: '4px' }}>
                    {/* <TextField placeholder="Placeholder" fullWidth style={{ margin: 0 }} /> */}
                  </Grid>

                  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="secondary" size="small" onClick={toggleFilters}>
                      <Filter style={{ height: '18px', width: '18px', marginRight: '5px' }} />{' '}
                      Filter
                    </Button>
                    <Button size="small" onClick={onHandleDownload} style={{ color: 'black' }}>
                      <Download />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <hr style={{ margin: '22px 0px' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{ fontSize: '13px', margin: '4px 6px 0px 0px' }}
                variant="subtitle"
                gutterBottom>
                {errorFlag ? 'Show only rows with errors' : 'Show All'}
              </Typography>
              <Switch size={'small'} checked={errorFlag} onChange={handleErrorData} />
            </span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                disabled={isAdding || editMode}
                size="small"
                variant="secondary"
                icon={PlusIcon}
                onClick={() => setIsAdding((adding) => !adding)}
                style={{ marginRight: 8 }}>
                {'Add row'}
              </Button>
              {!editMode && (
                <Button size="small" variant="primary" onClick={onEditAll}>
                  {'Edit all'}
                </Button>
              )}
              {editMode && (
                <Button size="small" onClick={onCancel}>
                  {'Cancel'}
                </Button>
              )}
              {(enableSave || editMode || isPreviewDataUploaded) && (
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                  variant="primary"
                  onClick={() => setOpenConfirmModal(true)}>
                  {'Save'}
                </Button>
              )}
            </span>
          </div>
        </div>
      </>
    );
  });

  const getValidTitle = (data) => {
    return data.mandatory
      ? data.mandatory
      : data.number || data.unique || data.referencePoint || data.blank || data.flag;
  };

  const getErrorData = (getData, row, key) => {
    return (
      <Tooltip title={getValidTitle(getData)}>
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            backgroundColor: '#f9cccc',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key] === '' ? 'No Data' : row[key]}
        </Typography>
      </Tooltip>
    );
  };

  const EditableCell = useCallback(
    ({ row, column: { accessor: key } }) => {
      const getData =
        trialVisitValidationResult &&
        Object.keys(trialVisitValidationResult).length &&
        trialVisitValidationResult.isColumnValid(row['id'], key);
      return row.editMode ? (
        <TextField
          size="small"
          fullWidth
          value={row[key]}
          onChange={(e) => row.editRow(row.id, key, e.target.value)}
          {...fieldStyles}
          {...(Object.keys(getData).length > 0
            ? {
                helperText: getValidTitle(getData),
                error: true
              }
            : {})}
        />
      ) : Object.keys(getData).length > 0 ? (
        getErrorData(getData, row, key)
      ) : (
        // row[key]
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key]}
        </Typography>
      );
    },
    [trialVisitValidationResult]
  );

  const ActionCell = useCallback(({ row: { id, editMode, onDelete } }) => {
    let isDisabled = trialVisitData.length < 2;
    return (
      editMode || (
        <Tooltip title={!isDisabled ? 'Delete' : 'Table cannot be empty'} disableFocusListener>
          <Button
            size="small"
            style={isDisabled ? { cursor: 'not-allowed' } : {}}
            onClick={!isDisabled ? () => onDelete(id) : null}>
            <Trash
              style={
                isDisabled
                  ? {
                      color: 'grey'
                    }
                  : {
                      color: 'black'
                    }
              }
            />
          </Button>
        </Tooltip>
      )
    );
  });

  const columns = useMemo(
    () => [
      {
        header: 'VISIT',
        accessor: 'visit',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('visit'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'VISITNUM',
        accessor: 'visitnum',
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter('visitnum'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'VISITDY',
        accessor: 'visitdy',
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter('visitdy'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'ARMCD',
        accessor: 'armcd',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('armcd'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'EPOCH',
        accessor: 'epoch',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('epoch'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'ARM',
        accessor: 'arm',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('arm'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVPSTTOL',
        accessor: 'tvpsttol',
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter('tvpsttol'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVPENTOL',
        accessor: 'tvpentol',
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter('tvpentol'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVMSTTOL',
        accessor: 'tvmsttol',
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter('tvmsttol'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVMENTOL',
        accessor: 'tvmentol',
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter('tvmentol'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVRFPNT',
        accessor: 'tvrfpnt',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tvrfpnt'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVGRPID',
        accessor: 'tvgrpid',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tvgrpid'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVCLRID',
        accessor: 'tvclrid',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tvclrid'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVBLFLG',
        accessor: 'tvblflg',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tvblflg'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVSTRL',
        accessor: 'tvstrl',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tvstrl'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVENRL',
        accessor: 'tvenrl',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tvenrl'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVUPFLG',
        accessor: 'tvupflg',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tvupflg'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        accessor: 'action',
        customCell: ActionCell,
        align: 'right'
      }
    ],
    [EditableCell, ActionCell]
  );
  const onEditAll = () => {
    setEditedRows(rows);
  };

  const onDelete = (id) => {
    const updatedRows = trialVisitData.filter((row) => row.id !== id);
    const validationResult = trialVisitValidator(updatedRows);
    setTrialVisitData(updatedRows);
    setTrialVisitValidationResult(validationResult);
    setEnableSave(true);
  };

  const editRow = (id, key, value) => {
    setEditedRows((trialVisitData) =>
      trialVisitData.map((row) => (row.id === id ? { ...row, [key]: value } : row))
    );
  };

  const onAddNewRowSave = () => {
    const copyTrialVisitData = [...trialVisitData];
    if (newRow && Object.keys(newRow).length > 0) {
      const newRowData = { ...newRow, id: uuidv4() };
      const obj = { ...mapping_obj, ...newRowData };
      copyTrialVisitData.unshift(obj);
      const validationResult = trialVisitValidator(copyTrialVisitData);
      setTrialVisitValidationResult(validationResult);
      setTrialVisitData(copyTrialVisitData);
      setRows(copyTrialVisitData);
      if (validationResult?.getErrorCount() === 0) {
        setOpenConfirmModal(true);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <>
          <Card style={{ marginTop: '1rem' }}>
            <Table
              columns={columns}
              classes={classes}
              rows={(editMode ? editedRows : rows).map((row, i) => {
                return {
                  ...row,
                  onDelete,
                  editRow,
                  editMode,
                  key: row.id,
                  index: i
                };
              })}
              initialSortedColumn="visit"
              initialSortOrder="asc"
              page={resetPage ? 0 : undefined}
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                // labelDisplayedRows: ({ from, to, count }) =>
                //   `${count === 1 ? 'Item' : 'Items'} ${from}-${to} of ${count}`,
                truncate: true
              }}
              CustomHeader={(props) => <CustomButtonHeader {...props} />}
              headerProps={{
                onEditAll,
                onSave,
                onCancel,
                editMode,
                trialVisitData,
                // handleStepValue,
                // handleShowPreview,
                setIsPreview,
                setIsAdding,
                isAdding,
                errorCount,
                setConfirmAlert,
                isPreviewDataUploaded
              }}
              CustomSubHeader={() => (
                <TableNewRow
                  isAdding={isAdding}
                  setIsAdding={setIsAdding}
                  onSave={onAddNewRowSave}
                  newRow={newRow}
                  editNewRow={editNewRow}
                  setNewRow={setNewRow}
                  columns={columns}
                />
              )}
            />
          </Card>
          <CustomModal
            display={confirmAlert.enabled}
            title={confirmAlert.title}
            message={confirmAlert.message}
            body={confirmAlert.body}
            variant={confirmAlert.variant}
            buttonPrimaryLabel={'Ok'}
            handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
            buttonSecondardyLabel={'Cancel'}
            handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
          />
        </>
      )}
    </>
  );
};

export default TrialVisitsPreview;
