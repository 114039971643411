import TextField from 'apollo-react/components/TextField';

/**
 * Component to render the text input for filter
 * @props {string} accessor
 * @props {object} filters
 * @props {function} updateFilterValue
 */
export const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      inputProps={{ 'data-testid': `${accessor}-filter` }}
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

/**
 * Component to render the integer input for filter
 * @props {string} accessor
 * @props {object} filters
 * @props {function} updateFilterValue
 */
export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      inputProps={{ 'data-testid': `${accessor}-filter` }}
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: '100%' }}
      margin="none"
      size="small"
    />
  );
};
