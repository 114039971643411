/*eslint-disable*/
import Grid from 'apollo-react/components/Grid';
import Modal from 'apollo-react/components/Modal';
import Table, { compareStrings } from 'apollo-react/components/Table';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Notification } from 'Components/Common/Notification';
import Typography from 'apollo-react/components/Typography';
import { compareDates, dateFormatByType } from 'Utils';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Radio from 'apollo-react/components/Radio';
import classNames from 'classnames';
import { neutral7 } from 'apollo-react/colors';
import Tooltip from 'apollo-react/components/Tooltip';

const useStyles = makeStyles({
  modalContainer: {
    height: '90vh',
    overflow: 'hidden',
    maxWidth: '90vw',
    width: '90vw',
    '&>.MuiDialogContent-root': {
      marginBottom: '1.0rem',
      borderBottom: '1px solid #4442',
      padding: '0px 0px 7px 0px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  },
  tableContent: {
    margin: '1px',
    overflow: 'auto'
  },
  noteContent: {
    margin: '0px 10px 0px 10px'
  },
  loading: {
    width: '100%',
    height: 'calc(100% - 14rem)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9',
    backgroundColor: 'rgba(255,255,255,0.6)'
  },
  selected: {
    '& td': {
      backgroundColor: '#ECF3FF'
    }
  }
});

const CheckboxCell = ({ row }) => {
  return <div style={{ width: 16, paddingBottom: 16 }}>{<Radio checked={row.selected} />}</div>;
};

const EllipsisCell = ({ row, column: { accessor } }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '30rem' }}>
      <Tooltip variant="light" title={row.preferenceDesc} placement="bottom" open={open}>
        <Typography
          variant="bodyDefault"
          noWrap
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
          {row.preferenceDesc}
        </Typography>
      </Tooltip>
    </div>
  );
};

const EmptyTableComponent = () => {
  return (
    <Typography variant="body2" style={{ color: neutral7 }}>
      No Saved Preferences available
    </Typography>
  );
};

const columns = [
  {
    header: '',
    accessor: '',
    customCell: CheckboxCell,
    width: 40
  },
  {
    header: 'Name',
    accessor: 'preferenceName',
    sortFunction: compareStrings,
    width: 250
  },
  {
    header: 'Last Updated',
    accessor: 'iqAuditDate',
    sortFunction: compareDates,
    width: 200
  },
  {
    header: 'Description ',
    accessor: 'preferenceDesc',
    customCell: EllipsisCell,
    width: 500
  }
];

function SelectPreference(props) {
  const { preferences, onSelect, onCancel } = props;
  const [preferenceList, setPreferenceList] = useState(preferences?.length > 0 ? preferences : []);
  const [selectedPreference, setSelectedPreference] = useState({});
  const classes = useStyles();

  const handleRowSelected = (row) => {
    let _updatedPreferenceList = preferenceList?.map((item) => {
      if (row.preferenceId === item.preferenceId) {
        return { ...item, selected: true };
      } else return { ...item, selected: false };
    });
    setPreferenceList(_updatedPreferenceList);
    setSelectedPreference(row);
  };

  return (
    <Modal
      {...props}
      open={true}
      onClose={onCancel}
      title={'Saved Preferences'}
      subtitle={'Select a previously saved preference to import into your current destination.'}
      disableBackdropClick
      className={classes.modalContainer}
      buttonProps={[
        {
          label: 'Close',
          id: 'btn-cancel',
          variant: 'secondary',
          size: 'small',
          onClick: () => {
            onCancel && onCancel();
          }
        },
        {
          label: 'Load Preference',
          id: 'btn-preference',
          size: 'small',
          disabled: !selectedPreference?.preferenceId,
          onClick: () => {
            onSelect && onSelect({ ...selectedPreference });
          }
        }
      ]}>
      <Grid xs={12} className={classes.tableContent}>
        <Table
          columns={columns}
          rows={preferenceList.map((row) => ({
            ...row,
            className: classNames(row.selected && classes.selected)
          }))}
          initialSortOrder="asc"
          initialSortedColumn={columns[1].accessor}
          defaultRowsPerPage={10}
          rowsPerPageOptions={preferenceList?.length > 0 ? [10, 20, 50, 'All'] : ['All']}
          hasScroll
          isSticky
          maxHeight={410}
          tablePaginationProps={{
            labelDisplayedRows: DisplayedRowsLabel,
            truncate: true
          }}
          rowProps={{
            onClick: handleRowSelected
          }}
          emptyProps={{
            text: <EmptyTableComponent />
          }}
        />
      </Grid>
      <Grid xs={12} className={classes.noteContent}>
        <Notification
          variant={'info'}
          message={'This will overwrite any existing configuration for this export destination.'}
          style={{ margin: '0px' }}
        />
      </Grid>
    </Modal>
  );
}

export default SelectPreference;
