/*eslint-disable*/
import { unwrapResult } from '@reduxjs/toolkit';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import StatusCheck from 'apollo-react-icons/StatusCheck';
// Icons
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import ApolloProgress from 'apollo-react/components/ApolloProgress';
import Button from 'apollo-react/components/Button';
// UI Imports
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import Checkbox from 'apollo-react/components/Checkbox';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Grid from 'apollo-react/components/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import Table from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import Loader from 'Components/Loader/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPreviewSources, SourcesTestConnection } from 'Redux/Service/DomainPreviewService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import {
  setCacheFlag,
  setselectedDomainSourceData,
  setSelectedSubjects
} from 'Redux/Slice/DomainPeviewSlice';
import { setSelectSourceDeatils, setTestInfo } from 'Redux/Slice/PublishMappingSpecSlice';
// CSS
import './SourceData.css';
import { handleSourceData, rowCheckboxSelect } from './SourceUtilities';
import useDisplayName from 'Utils/useDisplayName';

const SourceData = (props) => {
  const { handleNext, type, handleBack, domainCode, publishPathflag } = props;
  const dispatch = useDispatch();
  const [sourceConfigData, setSourceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [testResponse, setTestResponse] = useState();
  const [testing, setTesting] = useState(false);
  const [errorConnectionCount, setErrorConnectionCount] = useState(0);
  const [selectAllRow, setSelectAllRow] = useState(true);
  const { selectedDomainSourceData } = useSelector((state) => state.DomainPreviewData);
  const { mappingSpecDetails } = useSelector((state) => state.PublishMappingSpecData);
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const screenSizeRef = useRef(null);
  const PROXIMA_NOVA_FONT = 'Proxima Nova';
  const getDisplayName = useDisplayName();

  const checkUpdatedSources = (data, selectedData) => {
    if (selectedData?.masterDataSources) {
      const updatedSources = data.masterDataSources.filter((item) => item.selected === true);
      const selectedSources = selectedData.masterDataSources.filter(
        (item) => item.selected === true
      );
      if (updatedSources.length !== selectedSources.length) {
        dispatch(setSelectedSubjects([]));
      }
    }
  };

  const handleNextButton = () => {
    if (type === 'Publish') {
      dispatch(setSelectSourceDeatils(sourceConfigData));
    } else {
      dispatch(setSelectSourceDeatils(sourceConfigData));
      checkUpdatedSources(sourceConfigData, selectedDomainSourceData);
      dispatch(setselectedDomainSourceData(sourceConfigData));
    }
    handleNext();
  };

  useEffect(() => {
    (async () => {
      let payload = {
        protocolName: protocol.protocolNumber,
        productID: rowDataForRuleEditor.productID,
        productVersion: rowDataForRuleEditor.productVersion
      };
      let publishPayload = {
        protocolName: protocol.protocolNumber,
        productID: mappingSpecDetails.productID,
        productVersion: mappingSpecDetails.productVersion,
        mappingRuleVersionID: mappingSpecDetails.mappingRuleVersionID
      };
      dispatch(setCacheFlag(false));
      const sourceData = await dispatch(
        GetPreviewSources(type === 'Publish' ? publishPayload : payload)
      ).then(unwrapResult);

      if (type !== 'Publish' && selectedDomainSourceData?.masterDataSources?.length) {
        setSourceData(selectedDomainSourceData);
      } else if (type === 'Publish' && selectedDomainSourceData?.masterDataSources?.length) {
        setSourceData(selectedDomainSourceData);
      } else {
        sourceData.data.success
          ? setSourceData(
              handleSourceData(
                sourceData.data,
                domainCode,
                type === 'Publish' ? false : rowDataForRuleEditor.targetDataModelType === 'Ruleset'
              )
            )
          : dispatch(showBanner({ variant: 'error', message: sourceData.data.message }));
      }

      setLoading(false);
    })();
  }, []);

  const testConnection = async () => {
    const subSourceData = sourceConfigData?.masterDataSources?.filter(
      (item) => item.parentSourceName === 'CDR' && item.selected === true
    );
    const cdrSelection = subSourceData?.length ? true : false;

    const payload = sourceConfigData?.masterDataSources
      ?.filter((el) => el.parentSourceName === undefined)
      .map((item) => {
        return {
          dataSourceID: item.dataSourceID,
          databaseName: item.databaseName,
          password: item.passwordValue.slice(1),
          sourceID: item.sourceID,
          sourceName: item.sourceName,
          userID: item.userID,
          selected: item.sourceName !== 'CDR' ? item.selected : cdrSelection
        };
      })
      .filter((value) => value.selected === true);

    setTesting(true);
    const testResponseData = await dispatch(
      SourcesTestConnection({
        masterDataSources: payload?.map((item) => ({
          dataSourceID: item.dataSourceID,
          databaseName: item.databaseName,
          password: item.password,
          sourceID: item.sourceID,
          sourceName: item.sourceName,
          userID: item.userID
        })),
        protocolNumber: protocol.protocolNumber
      })
    ).then(unwrapResult);
    if (testResponseData.data.success) {
      setTestResponse(testResponseData.data);
      dispatch(setTestInfo(testResponseData.data));
    } else {
      dispatch(showBanner({ variant: 'error', message: testResponseData.data.message }));
    }
    setTesting(false);
  };

  useEffect(() => {
    if (testResponse !== undefined) {
      const matchingData = sourceConfigData?.masterDataSources?.filter((item) => {
        const test = testResponse.masterDataSources.filter(
          (el) => el.sourceName === (item.parentSourceName || item.sourceName)
        );
        if (
          (item.parentSourceName || item.sourceName) === (test.length > 0 ? test[0].sourceName : '')
        ) {
          return true;
        } else {
          return false;
        }
      });

      const getConnectionStatus = (row, type) => {
        let filteredData;
        if (row.parentSourceName === undefined) {
          filteredData = testResponse.masterDataSources.filter(
            (item) => item.sourceName === row.sourceName
          );
        } else {
          filteredData = testResponse.masterDataSources.filter(
            (item) => item.sourceName === row.parentSourceName
          );
        }
        return filteredData[0][type];
      };

      const data = matchingData?.map((item) => {
        if (item.sourceName === 'CDR') {
          return {
            ...item,
            connectionMessage: getConnectionStatus(item, 'connectionMessage'),
            connectionStatus: getConnectionStatus(item, 'connectionStatus')
          };
        } else {
          return {
            ...item,
            connectionMessage: getConnectionStatus(item, 'connectionMessage'),
            connectionStatus: getConnectionStatus(item, 'connectionStatus'),
            connection:
              getConnectionStatus(item, 'connectionStatus') &&
              getConnectionStatus(item, 'connectionMessage') === 'Connected'
          };
        }
      });

      if (data) {
        data.filter((el) => el.connection === false)?.length
          ? setErrorConnectionCount(errorConnectionCount + 1)
          : setErrorConnectionCount(0);
      }

      const updatedData = sourceConfigData?.masterDataSources?.map((item) => {
        let item2 = data.find((i2) => i2.sourceName === item.sourceName);
        return item2 ? { ...item, ...item2 } : item;
      });
      setSourceData({
        mappingRuleVersionID: sourceConfigData.mappingRuleVersionID,
        masterDataSources: updatedData,
        studyDataSources: sourceConfigData.studyDataSources,
        isDataSourceDummy: sourceConfigData.isDataSourceDummy
      });
    }
  }, [testResponse]);

  const updateRow = (thisRow, valuesToUpdate) => {
    const keyName = Object.keys(valuesToUpdate).toString();
    const keyValue = Object.values(valuesToUpdate).toString();
    const sameUpdate = keyName === 'databaseName' ? thisRow[keyName] == keyValue : false;
    const value = keyName === 'databaseName' ? (item) => item === keyValue : undefined;

    let removeSubLibraries;
    let subSourceConChng;
    let newRows = sourceConfigData?.masterDataSources?.map((row) => {
      let subLibraries = [];
      if (row.sourceName === thisRow.sourceName && !sameUpdate) {
        row = rowCheckboxSelect(keyName, keyValue, value, thisRow, valuesToUpdate, row);

        if (!valuesToUpdate.showLibraries) {
          removeSubLibraries = row;
        } else {
          subLibraries = [...row.libraries];
        }
      } else if (thisRow === 'AllRowSelected') {
        setSelectAllRow(!selectAllRow);
        row = rowCheckboxSelect(keyName, keyValue, value, thisRow, valuesToUpdate, row);

        if (!valuesToUpdate.showLibraries) {
          removeSubLibraries = row;
        } else {
          subLibraries = [...row.libraries];
        }
      }
      return subLibraries.length ? [row, ...subLibraries] : [row];
    });

    if (keyName === 'databaseName' && thisRow.sourceName === 'CDR' && !sameUpdate) {
      const subrow = sourceConfigData?.masterDataSources?.filter(
        (row) => row.parentSourceName === thisRow.sourceName
      );

      subSourceConChng =
        subrow?.length &&
        subrow.map((el) => {
          if (el.connection === true) {
            return {
              ...el,
              connection: ''
            };
          } else {
            return {
              ...el
            };
          }
        });
    }
    if (subSourceConChng?.length) {
      newRows = newRows.flat().map((item) => {
        let item2 = subSourceConChng.find((i2) => i2.sourceName === item.sourceName);
        return item2 ? { ...item, ...item2 } : item;
      });
    }

    if (removeSubLibraries !== undefined && keyName === 'showLibraries') {
      let subLibraries = [],
        removeRowIndex,
        i = -1;
      newRows = newRows.flat().filter((row) => {
        i++;
        if (row.sourceName === removeSubLibraries.sourceName) {
          removeRowIndex = i;
        }
        if (row.parentSourceName !== removeSubLibraries.sourceName) {
          return true;
        } else {
          subLibraries.push({
            ...row
          });
          return false;
        }
      });
      newRows[removeRowIndex] = {
        ...removeSubLibraries,
        libraries: subLibraries
      };
    }

    setSourceData({
      mappingRuleVersionID: sourceConfigData.mappingRuleVersionID,
      masterDataSources: newRows.flat(),
      studyDataSources: sourceConfigData.studyDataSources,
      isDataSourceDummy: sourceConfigData.isDataSourceDummy
    });
  };

  useEffect(() => {
    let newRowVal = sourceConfigData?.masterDataSources
      ?.filter((row) => row.sourceName !== 'CDR')
      ?.every((row) => row.selected);
    newRowVal ? setSelectAllRow(true) : setSelectAllRow(false);
  }, [sourceConfigData.masterDataSources]);

  const checkUserSelection = (row) => {
    if (row.sourceName === 'CDR') {
      const subSourceSelection = sourceConfigData?.masterDataSources?.filter((item) => {
        if (item.parentSourceName === 'CDR') {
          return item.selected === true;
        }
      });
      return subSourceSelection?.length ? false : true;
    } else {
      return !row.selected;
    }
  };

  const checkErrorResponse = () => {
    return sourceConfigData?.masterDataSources?.filter(
      (item) => (item.connection === false || item.connection === '') && item.selected === true
    )?.length
      ? true
      : false;
  };

  const CheckboxCell = ({ row }) => {
    if (row.libraries) {
      return (
        <div
          style={{ width: 16, marginTop: -2, cursor: 'pointer' }}
          onClick={() => updateRow(row, { showLibraries: !row.showLibraries })}
          className="checkbox_domain_preview">
          {row.showLibraries ? <ChevronDown /> : <ChevronRight />}
        </div>
      );
    } else if (row.isSubLibrary) {
      return null;
    } else {
      return (
        <div style={{ width: 16, marginTop: -2, marginLeft: '0.5rem' }}>
          <Checkbox
            checked={row.selected}
            onChange={() => updateRow(row, { selected: !row.selected })}
          />
        </div>
      );
    }
  };
  const ConnectionCell = (props) => {
    if (props.row.connection === false) {
      return (
        <div style={{ padding: '15px 0px' }}>
          <div style={{ display: 'flex' }}>
            <Tooltip title={props.row.connectionMessage} subtitle={'Call Product Support'}>
              <StatusExclamation style={{ color: 'red' }} />
            </Tooltip>
            <Typography
              variant="title2"
              style={{
                fontSize: '14px',
                marginTop: '4px',
                marginLeft: '4px'
              }}>
              {errorConnectionCount > 3
                ? 'Please reach to product support team'
                : 'Connection Error'}
            </Typography>
          </div>
        </div>
      );
    } else if (props.row.connection && props.row.selected) {
      return (
        <div style={{ display: 'flex' }}>
          <StatusCheck style={{ color: '#00C221' }} />
          <Typography
            variant="title2"
            style={{
              fontSize: '14px',
              marginTop: '4px',
              marginLeft: '4px'
            }}>
            {'Connected'}
          </Typography>
        </div>
      );
    } else {
      return null;
    }
  };

  const SourceCell = ({ row, column }) => {
    if (row.isSubLibrary) {
      return (
        <>
          <Grid container style={{ paddingTop: row.editMode ? 12 : 0, alignItems: 'center' }}>
            {rowDataForRuleEditor.targetDataModelType !== 'Ruleset' && (
              <Grid item xs={2}>
                <Checkbox
                  checked={row.selected}
                  onChange={() => updateRow(row, { selected: !row.selected })}
                />
              </Grid>
            )}
            <Grid item xs={10}>
              {row[column.accessor]}
            </Grid>
          </Grid>
        </>
      );
    } else {
      return <div style={{ paddingTop: row.editMode ? 12 : 0 }}>{row[column.accessor]}</div>;
    }
  };

  const DatabaseCell = ({ row }) => {
    console.log(row.databaseOptions, 'data');
    return row.databaseName ? (
      <div className="db_domain_preview" style={{ paddingRight: '20px' }}>
        <Select
          canDeselect={false}
          value={row.databaseName}
          fullWidth
          disabled={checkUserSelection(row)}
          onChange={
            row.databaseOptions.length > 0
              ? (e) => updateRow(row, { databaseName: e.target.value })
              : null
          }>
          {row.databaseOptions.map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
    ) : null;
  };

  const PasswordCell = ({ row }) => {
    return (
      row.parentSourceName === undefined && (
        <div>
          <Typography variant="h2" gutterBottom>
            .......
          </Typography>
        </div>
      )
    );
  };

  const columns = [
    {
      header: '',
      accessor: '',
      customCell: CheckboxCell
    },
    {
      header: 'Source',
      accessor: 'sourceDisplayName',
      customCell: SourceCell,
      fixedWidth: false
    },
    {
      header: 'Database',
      accessor: 'databaseName',
      customCell: DatabaseCell,
      fixedWidth: false
    },
    {
      header: 'Username',
      accessor: 'userID',
      fixedWidth: false
    },
    {
      header: 'Password',
      accessor: 'password',
      customCell: PasswordCell,
      fixedWidth: false
    },
    {
      header: 'Connection',
      accessor: 'connection',
      customCell: ConnectionCell,
      width: 300,
      fixedWidth: false
    }
  ];

  useEffect(() => {
    if (
      rowDataForRuleEditor.targetDataModelType === 'Ruleset' &&
      Object.keys(sourceConfigData).length > 0
    ) {
      sourceConfigData?.masterDataSources?.forEach((item) => {
        if (item.sourceName === domainCode) {
          setSourceData({
            mappingRuleVersionID: sourceConfigData.mappingRuleVersionID,
            masterDataSources: [item],
            studyDataSources: sourceConfigData.studyDataSources,
            isDataSourceDummy: sourceConfigData.isDataSourceDummy
          });
        }
      });
    }
  }, [rowDataForRuleEditor]);

  return (
    <React.Fragment>
      <Typography
        variant="title1"
        gutterBottom
        style={{
          fontSize: '20px',
          color: '#000000',
          lineHeight: '48px',
          letterSpacing: 0,
          fontFamily: PROXIMA_NOVA_FONT,
          height: '24px'
        }}>
        Choose Source Connections
      </Typography>
      {loading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <Card style={{ marginTop: '3%' }} ref={screenSizeRef}>
          {testing && (
            <div
              style={{
                width: `${screenSizeRef.current?.offsetWidth}px`,
                height: `${screenSizeRef.current?.offsetHeight}px`,
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '9',
                backgroundColor: 'rgba(255,255,255)'
              }}>
              <ApolloProgress />
            </div>
          )}
          <CardContent style={{ padding: '0px' }}>
            <Grid container style={{ padding: '20px' }}>
              <Grid item xs={6} style={{ display: 'flex' }}>
                {rowDataForRuleEditor.targetDataModelType !== 'Ruleset' && (
                  <Checkbox
                    checked={selectAllRow}
                    data-testid="select-all"
                    onChange={() => updateRow('AllRowSelected', { selected: !selectAllRow })}
                  />
                )}
                <div>
                  <Typography
                    variant="title2"
                    gutterBottom
                    style={{
                      fontSize: '16px',
                      color: '#000000',
                      fontWeight: '600',
                      lineHeight: '24px',
                      fontFamily: PROXIMA_NOVA_FONT
                    }}>
                    Source Configuration
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Select the sources you want to configure for this study
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ marginTop: 5, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    className="test_domain_preview"
                    style={{
                      fontSize: '16px',
                      color: '#0768FD',
                      lineHeight: '24px',
                      fontWeight: '500',
                      letterSpacing: 0,
                      fontFamily: PROXIMA_NOVA_FONT
                    }}
                    onClick={testConnection}
                    variant="secondary"
                    disabled={
                      publishPathflag
                        ? false
                        : sourceConfigData.masterDataSources === undefined
                        ? false
                        : sourceConfigData.masterDataSources.length &&
                          sourceConfigData.masterDataSources.filter(
                            (item) => item.selected === true
                          ).length === 0
                    }>
                    Test Connection
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Table
                  columns={columns}
                  rows={sourceConfigData?.masterDataSources?.map((row) => ({
                    ...row,
                    sourceDisplayName: getDisplayName(row.sourceName)
                  }))}
                  rowsPerPageOptions={
                    sourceConfigData?.masterDataSources?.length > 5 ? ['All'] : [5, 10, 'All']
                  }
                  hidePagination
                  hasScroll
                  maxHeight={650}
                  tablePaginationProps={{
                    labelDisplayedRows: ({ from, to, count }) =>
                      `${count === 1 ? 'Connection' : 'Connections'} ${from}-${to} of ${count}`,
                    truncate: true
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="text"
          size="small"
          style={{ padding: 0, visibility: `${type !== 'Publish' ? 'hidden' : 'visible'}` }}
          onClick={handleBack}>
          Previous
        </Button>
        <Button
          onClick={handleNextButton}
          variant="primary"
          size="small"
          disabled={
            !testing && sourceConfigData.masterDataSources?.length && !checkErrorResponse()
              ? sourceConfigData.masterDataSources.filter(
                  (item) => item.selected === true && item.connection === true
                ).length < 1
              : true
          }>
          {'Next'}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SourceData;
