import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import ContentHolder from './ContentHolder';

const EnvironmentCard = ({ selectedEnvironment, selectedScehduler, handleClick }) => {
  const { comments, environmentName, mappingSpecName } = selectedEnvironment;
  const { schedulerType } = selectedScehduler;

  return (
    <Card style={{ width: '100%', marginBottom: '2rem' }}>
      <Grid container spacing={2} style={{ margin: '2%' }}>
        <Grid item xs={3}>
          <Typography variant="title1">Data Pipeline settings</Typography>
          <Button variant="text" style={{ padding: 0 }} size="small" onClick={handleClick}>
            Change Data Pipeline settings
          </Button>
        </Grid>
        <Grid item xs={9}>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Grid item xs={3}>
              <ContentHolder title={'Environment'} value={environmentName} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder
                title={'Active Mapping Spec'}
                value={mappingSpecName ? mappingSpecName : 'No Active Mapping Spec'}
              />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Comments/Notes'} value={comments} />
            </Grid>
          </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
            <Grid item xs={10.5}>
              <ContentHolder title={'Scheduler'} value={schedulerType} />
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default EnvironmentCard;
