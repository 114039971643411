/* eslint-disable */
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import { editGlobalLibrary } from '../../../../../Redux/Service/GlobalLibraryService';
import { fileByteToFileObject } from 'Utils';
import SelectDataSource from './Components/SelectDataSource';
import SupplementalQualifier from './Components/SupplementalQualifier';
import ReferenceData from './Components/ReferenceData';
import { MasteringRulesProvider } from './MasteringRulesProvider';
import { DomainRulesProvider } from './DomainRules/DomainRulesProvider';
import MappingSpec from './MappingSpec';
import { useLocation } from 'react-router-dom';

const validationSchema = yup.object({
  name: yup.string(),
  description: yup.string().max(400, 'Maximum ${max} characters are allowed')
});

const RuleEditor = forwardRef((props, ref) => {
  const { mappingRuleVersionID } = useSelector((state) => state.RuleEditorData);
  const dispatch = useDispatch();
  const targetModalData = useSelector((state) => state.DataProductStudyLibrary.targetModal);
  const [fileObj, setFileObj] = useState([]);
  const [mappingVersionId, setMappingVersioId] = useState();
  const [createMappingObj, setCreateMappingObj] = useState({});
  const location = useLocation();

  let steps = [
    'Data Sources',
    'Reference Data',
    'Mastering Settings',
    'Domain Rules',
    'Create Mapping Spec'
  ];

  useEffect(() => {
    const path = location.pathname;
    const route = path.substring(path.lastIndexOf('/') + 1, path.length);
    if (route === 'reference-data') {
      props.handleStepValue(1);
    } else if (route === 'mastering-rules') {
      props.handleStepValue(3);
    } else if (
      ['mds-domain-rules', 'domain-rules', 'batch-edit', 'domain-preview'].some((_path) =>
        path.match(_path)
      )
    ) {
      props.handleStepValue(4);
    } else if (route === 'create-mapping-spec') {
      props.handleStepValue(5);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: ''
    },
    validationSchema: validationSchema
  });

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-designer'
        },
        {
          title:
            location.pathname ===
            `/product-designer/rule-editor/${mappingRuleVersionID}/data-sources`
              ? 'Select Data Source'
              : location.pathname ===
                `/product-designer/rule-editor/${mappingRuleVersionID}/reference-data`
              ? 'Upload Reference Data'
              : location.pathname ===
                `/product-designer/rule-editor/${mappingRuleVersionID}/supplemenx x xtal-qualifiers`
              ? 'Supplemental Qualifiers'
              : location.pathname ===
                `/product-designer/rule-editor/${mappingRuleVersionID}/mastering-rules`
              ? 'Mastering Rules'
              : location.pathname ===
                `/product-designer/rule-editor/${mappingRuleVersionID}/domain-rules`
              ? 'Domain Rules'
              : location.pathname ===
                `/product-designer/rule-editor/${mappingRuleVersionID}/create-mapping-spec`
              ? 'Create Mapping Spec'
              : 'Add Data Source',
          path: '/product-designer/add-data-product/product-designer/rule-editor'
        }
      ])
    );

    return () => {
      localStorage.removeItem('stepCaching');
    };
  }, [location.pathname]);

  useEffect(() => {
    if (targetModalData && Object.keys(targetModalData).length > 0) {
      editGlobalLibrary(targetModalData)
        .then((res) => {
          return fileByteToFileObject(
            'data:application/octet-stream;base64,' + res.data.fileBytes,
            targetModalData.fileName
          );
        })
        .then((res1) => {
          setFileObj([res1]);
        });
    }
  }, [targetModalData]);

  const hasValidate = () => {
    if (!formik.dirty || Object.keys(formik.errors).length > 0 || !targetModalData) {
      return false;
    }
  };

  useImperativeHandle(ref, () => {
    return {
      handleSubmit: formik.handleSubmit,
      hasValidate,
      getPayload: () => {
        return { ...formik.values };
      },
      // handleStepperback: (step) => {
      //   props.handleStepValue(step);
      // },
      onNextClick: (step) => {
        // props.handleStepValue(step);
      }
    };
  });

  const setMappingId = (id) => {
    setMappingVersioId(id);
  };

  const handleStepFn = (step) => {
    const { ruleEditorCards } = useRuleEditorCards();
    let modCards = ruleEditorCards.map((card, i) => {
      return { ...card, order: i };
    });

    const filteredValue = modCards.filter((item) => {
      return item.order === step;
    });
    if (filteredValue[0].module === 'data-sources') {
      return (
        <SelectDataSource
          handleStepValue={props.handleStepValue}
          setMappingId={setMappingId}
          handleStepperValidate={props.handleStepperValidate}
          stepperValidate={props.stepperValidate}
        />
      );
    } else if (filteredValue[0].module === 'create-mapping-spec') {
      return (
        <MappingSpec
          handleStepValue={props.handleStepValue}
          setCreateMappingObj={setCreateMappingObj}
          handleStepperValidate={props.handleStepperValidate}
          stepperValidate={props.stepperValidate}
        />
      );
    } else if (filteredValue[0].module === 'domain-rules') {
      return (
        <DomainRulesProvider
          handleShowPreview={props.handleShowPreview}
          handleStepValue={props.handleStepValue}
          step={props.step}
          setMiddlePanelElement={props.setMiddlePanelElement}
          createMappingObj={createMappingObj}
          handleStepperValidate={props.handleStepperValidate}
          stepperValidate={props.stepperValidate}
          handleShowBulkEdit={props.handleShowBulkEdit}
        />
      );
    } else if (filteredValue[0].module === 'mastering-rules') {
      return (
        <MasteringRulesProvider
          handleStepValue={props.handleStepValue}
          step={props.step}
          setMiddlePanelElement={props.setMiddlePanelElement}
          handleStepperValidate={props.handleStepperValidate}
          stepperValidate={props.stepperValidate}
          createMappingObj={createMappingObj}
          setUnsavedChangesFlag={props.setUnsavedChangesFlag}
        />
      );
    } else if (filteredValue[0].module === 'supplemental-qualifiers') {
      return (
        <SupplementalQualifier
          handleShowPreview={props.handleShowPreview}
          handleSQPreviewFlag={props.handleSQPreviewFlag}
          // mappingId={mappingVersionId}
          handleStepValue={props.handleStepValue}
          step={props.step}
          handleStepperValidate={props.handleStepperValidate}
          stepperValidate={props.stepperValidate}
          createMappingObj={createMappingObj}
        />
      );
    } else if (filteredValue[0].module === 'reference-data') {
      return (
        <ReferenceData
          handleShowPreview={props.handleShowPreview}
          mappingId={mappingVersionId}
          handleStepValue={props.handleStepValue}
          step={props.step}
          handleStepperValidate={props.handleStepperValidate}
          stepperValidate={props.stepperValidate}
          createMappingObj={createMappingObj}
        />
      );
    }
  };

  const renderSteps = () => {
    const stepValue = handleStepFn(props.step);
    return stepValue;
    // switch (props.step) {
    //   case 0:
    //     return (
    //       <SelectDataSource
    //         handleStepValue={props.handleStepValue}
    //         setMappingId={setMappingId}
    //         handleStepperValidate={props.handleStepperValidate}
    //         stepperValidate={props.stepperValidate}
    //       />
    //     );
    //   case 1:
    //     return (
    //       <ReferenceData
    //         handleShowPreview={props.handleShowPreview}
    //         mappingId={mappingVersionId}
    //         handleStepValue={props.handleStepValue}
    //         step={props.step}
    //         handleStepperValidate={props.handleStepperValidate}
    //         stepperValidate={props.stepperValidate}
    //         createMappingObj={createMappingObj}
    //       />
    //     );
    //   case 2:
    //     return (
    //       <SupplementalQualifier
    //         handleShowPreview={props.handleShowPreview}
    //         handleSQPreviewFlag={props.handleSQPreviewFlag}
    //         // mappingId={mappingVersionId}
    //         handleStepValue={props.handleStepValue}
    //         step={props.step}
    //         handleStepperValidate={props.handleStepperValidate}
    //         stepperValidate={props.stepperValidate}
    //         createMappingObj={createMappingObj}
    //       />
    //     );
    //   case 3:
    //     return (
    //       <MasteringRulesProvider
    //         handleStepValue={props.handleStepValue}
    //         step={props.step}
    //         setMiddlePanelElement={props.setMiddlePanelElement}
    //         handleStepperValidate={props.handleStepperValidate}
    //         stepperValidate={props.stepperValidate}
    //         createMappingObj={createMappingObj}
    //         setUnsavedChangesFlag={props.setUnsavedChangesFlag}
    //       />
    //     );
    //   case 4:
    //     return (
    //       <DomainRulesProvider
    //         handleShowPreview={props.handleShowPreview}
    //         handleStepValue={props.handleStepValue}
    //         step={props.step}
    //         setMiddlePanelElement={props.setMiddlePanelElement}
    //         createMappingObj={createMappingObj}
    //         handleStepperValidate={props.handleStepperValidate}
    //         stepperValidate={props.stepperValidate}
    //         handleShowBulkEdit={props.handleShowBulkEdit}
    //       />
    //     );
    //   case 5:
    //     return (
    //       <MappingSpec
    //         handleStepValue={props.handleStepValue}
    //         setCreateMappingObj={setCreateMappingObj}
    //         handleStepperValidate={props.handleStepperValidate}
    //         stepperValidate={props.stepperValidate}
    //       />
    //     );
    // }
  };
  // const renderSteps = (cards) => {
  //   let uniqueCards = [...new Set(cards)];
  //   return uniqueCards.map((card) => {
  //     return getCards(card.order);
  //   });
  // };

  // const getCards = (order) => {
  //   if (props.step === 0 && order === 0) {
  //     return (
  //       <SelectDataSource
  //         handleStepValue={props.handleStepValue}
  //         mappingVersionId={mappingVersionId}
  //         setMappingId={setMappingId}
  //         handleStepperValidate={props.handleStepperValidate}
  //         stepperValidate={props.stepperValidate}
  //       />
  //     );
  //   } else if (props.step === 1 && order === 1) {
  //     return (
  //       <ReferenceData
  //         handleShowPreview={props.handleShowPreview}
  //         mappingId={mappingVersionId}
  //         handleStepValue={props.handleStepValue}
  //         step={props.step}
  //         handleStepperValidate={props.handleStepperValidate}
  //         stepperValidate={props.stepperValidate}
  //         createMappingObj={createMappingObj}
  //       />
  //     );
  //   } else if (props.step === 2 && order === 2) {
  //     return (
  //       <SupplementalQualifier
  //         handleShowPreview={props.handleShowPreview}
  //         handleSQPreviewFlag={props.handleSQPreviewFlag}
  //         // mappingId={mappingVersionId}
  //         handleStepValue={props.handleStepValue}
  //         step={props.step}
  //         handleStepperValidate={props.handleStepperValidate}
  //         stepperValidate={props.stepperValidate}
  //         createMappingObj={createMappingObj}
  //       />
  //     );
  //   } else if (props.step === 3 && order === 3) {
  //     return (
  //       <MasteringRulesProvider
  //         handleStepValue={props.handleStepValue}
  //         step={props.step}
  //         setMiddlePanelElement={props.setMiddlePanelElement}
  //         handleStepperValidate={props.handleStepperValidate}
  //         stepperValidate={props.stepperValidate}
  //         createMappingObj={createMappingObj}
  //         setUnsavedChangesFlag={props.setUnsavedChangesFlag}
  //       />
  //     );
  //   } else if (props.step === 4 && order === 4) {
  //     return (
  //       <DomainRulesProvider
  //         handleShowPreview={props.handleShowPreview}
  //         handleStepValue={props.handleStepValue}
  //         step={props.step}
  //         setMiddlePanelElement={props.setMiddlePanelElement}
  //         createMappingObj={createMappingObj}
  //         handleStepperValidate={props.handleStepperValidate}
  //         stepperValidate={props.stepperValidate}
  //         handleShowBulkEdit={props.handleShowBulkEdit}
  //       />
  //     );
  //   } else if (props.step === 5 && order === 5) {
  //     return (
  //       <MappingSpec
  //         handleStepValue={props.handleStepValue}
  //         setCreateMappingObj={setCreateMappingObj}
  //         handleStepperValidate={props.handleStepperValidate}
  //         stepperValidate={props.stepperValidate}
  //       />
  //     );
  //   }
  // };

  return <>{renderSteps()}</>;
});

export default RuleEditor;
