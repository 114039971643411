import XLSX from 'xlsx';
import { dateFormatByType } from 'Utils';

export const jsonToExcelDomainPreview = (jsonResponse, filename, sheetNames, columnOrder = []) => {
  let wb = XLSX.utils.book_new();
  jsonResponse.forEach((item, i) => {
    if (item) {
      let ws;
      if (i === 0) {
        ws = XLSX.utils.json_to_sheet(item, { skipHeader: 1, header: columnOrder[i] });
      } else {
        ws = XLSX.utils.json_to_sheet(item, { header: columnOrder[i] });
      }
      XLSX.utils.book_append_sheet(wb, ws, sheetNames[i]);
    }
  });
  XLSX.writeFile(wb, filename);
};

export const downloadPreviewData = (downloadPayload) => {
  const {
    selectedDomainSourceData,
    protocol,
    errorlist,
    domainData,
    subjectData,
    visitData,
    visitMasteredData,
    domainCode,
    masteredData,
    rowDataForRuleEditor,
    domainDataHeaders
  } = downloadPayload;

  const selectedSources = selectedDomainSourceData?.masterDataSources
    .filter((item) => item.connection === true && item.selected)
    .map((row) => {
      if (row.parentSourceName === 'CDR') {
        const CDR = selectedDomainSourceData.masterDataSources.filter(
          (item) => item.sourceName === 'CDR'
        )[0];
        return {
          databaseName: CDR.databaseName,
          sourceName: `${CDR.sourceName} (${row.sourceName})`,
          userID: CDR.userID
        };
      } else {
        return {
          databaseName: row.databaseName,
          sourceName: row.sourceName,
          userID: row.userID
        };
      }
    });

  const set = new Set();
  const previewSelectedDatasources = selectedSources.filter((el) => {
    const duplicate = set.has(el.sourceName);
    set.add(el.sourceName);
    return !duplicate;
  });

  const checkErrorData = (data) => {
    if (data.length) {
      return data.map((item) => {
        return {
          errorCode: item.errorCode,
          isError: item.isError,
          category: item.category,
          message: item.message
        };
      });
    } else {
      return [{ 'No Errors found': '' }];
    }
  };
  const currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const errorData = checkErrorData(errorlist);
  const domainExcelData = domainData?.length ? domainData : [{ 'No Data Available': '' }];
  const subjectExcelData = subjectData?.length ? subjectData : [{ 'No Data Available': '' }];
  const visitExcelData = visitData?.length ? visitData : [{ 'No Data Available': '' }];
  const visitMasterExcelData = visitMasteredData?.length
    ? visitMasteredData
    : [{ 'No Data Available': '' }];
  const protocolNumber = selectedDomainSourceData?.studyDataSources[0]?.protocolNumber;
  const productMnemonic = rowDataForRuleEditor.productNnemonic;
  const masteredSubjects = masteredData?.length ? masteredData : [{ 'No Data Available': '' }];
  const dateTime = dateFormatByType(new Date().toISOString(), '');
  const SOURCE_CONNECTIONS = 'Source Connections';
  const MASTERED_SUBJECTS = 'Mastered Subjects';
  const TITLE = 'Title';
  const ERRORS = 'Errors';
  const title = [
    { title: `Protocol Number: ${protocolNumber}` },
    { title: `Project Code: ${protocol.projectCode}` },
    { title: `Sponsor: ${protocol.sponsor}` },
    { title: `Product Mnemonic: ${productMnemonic}` },
    { title: `Date Created: ${dateTime}` }
  ];
  switch (currentPath) {
    case 'mds-domain-preview': {
      title.push({ title: `Domain: ${domainCode}` });
      jsonToExcelDomainPreview(
        [title, previewSelectedDatasources, domainExcelData, errorData],
        `${protocolNumber}-${productMnemonic}-${domainCode}-Preview.xlsx`,
        [TITLE, SOURCE_CONNECTIONS, 'Domain Data', ERRORS],
        [undefined, undefined, domainDataHeaders, undefined]
      );
      break;
    }
    case 'subject-setting-preview': {
      jsonToExcelDomainPreview(
        [title, previewSelectedDatasources, masteredSubjects, subjectExcelData, errorData],
        `${protocolNumber}-${productMnemonic}-${domainCode}-Preview.xlsx`,
        [TITLE, SOURCE_CONNECTIONS, MASTERED_SUBJECTS, 'Subject Locators', ERRORS]
      );
      break;
    }
    case 'visit-setting-preview': {
      jsonToExcelDomainPreview(
        [
          title,
          previewSelectedDatasources,
          masteredSubjects,
          visitExcelData,
          visitMasterExcelData,
          errorData
        ],
        `${protocolNumber}-${productMnemonic}-${domainCode}-Preview.xlsx`,
        [TITLE, SOURCE_CONNECTIONS, MASTERED_SUBJECTS, 'Visit Locators', 'Visit Mastered', ERRORS]
      );
      break;
    }
    default: {
      jsonToExcelDomainPreview(
        [title, previewSelectedDatasources, masteredSubjects, domainExcelData, errorData],
        `${protocolNumber}-${productMnemonic}-${domainCode}-Preview.xlsx`,
        [TITLE, SOURCE_CONNECTIONS, MASTERED_SUBJECTS, domainCode, ERRORS]
      );
      break;
    }
  }
};
