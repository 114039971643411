/*eslint-disable */
import { makeStyles } from '@mui/styles';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import FilterIcon from 'apollo-react-icons/Filter';
import { neutral8, primaryLight } from 'apollo-react/colors';
import Box from 'apollo-react/components/Box';
import Button from 'apollo-react/components/Button';
import { Checkbox } from 'apollo-react/components/Checkbox/Checkbox';
import DatePicker from 'apollo-react/components/DatePicker';
import IconButton from 'apollo-react/components/IconButton';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import classNames from 'classnames';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import React, { useEffect, useMemo, useState } from 'react';
import { compareDates, dateFilter } from 'Utils';
import './SelectSource.css';
import { DateFilter } from 'Components/Common/DateFilter';
import { DATA_FLOW_TYPES } from 'Constants/DataFlowTypes';
import useDisplayName from 'Utils/useDisplayName';

const CDR_TABULAR = 'CDR Tabular';
const CDISC_ODM = 'CDISC ODM';
const ECRF_VIEW = 'eCRF View';

const styles = {
  selected: {
    '& td': {
      backgroundColor: primaryLight
    }
  },
  error: {
    '& td': {
      backgroundColor: 'rgba(226,0,0,0.06)'
    }
  }
};

const useStyles = makeStyles(styles);
export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const ExpandCell = ({ row: { key, handleToggleRow, expanded, libraryType } }) => {
  return (
    libraryType === CDR_TABULAR && (
      <div style={{ width: 12, marginRight: '0.5rem' }}>
        <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
          <IconButton id="expand" size="small" onClick={() => handleToggleRow(key)}>
            {expanded ? <ChevronDown /> : <ChevronRight />}
          </IconButton>
        </Tooltip>
      </div>
    )
  );
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

const SourceCell = ({
  row: { libraryType, isCDRTestFlow, source, isCustomLibrary, customSQLDataSource, edcTrailName, ecrfTrialName, dataSetType }
}) => {
  return libraryType === CDR_TABULAR ? (
    <div>
      {isCustomLibrary && customSQLDataSource === CDISC_ODM
        ? edcTrailName
        : isCDRTestFlow && dataSetType === ECRF_VIEW && ecrfTrialName !== ''
        ? `${DATA_FLOW_TYPES.TEST} - ${ecrfTrialName}`
        : isCDRTestFlow
        ? DATA_FLOW_TYPES.TEST
        : DATA_FLOW_TYPES.PROD}
    </div>
  ) : (
    <div>{source.toString()}</div>
  );
};

const CustomButtonHeader = (props) => {
  const { disableSave, haveUnsavedRecords, toggleFilters, handleSave } = props;
  return (
    <Box display={'flex'} flexDirection={'row'}>
      <Button
        size="small"
        variant="secondary"
        icon={FilterIcon}
        style={{ marginRight: '1rem' }}
        onClick={toggleFilters}>
        Filter
      </Button>
      <Tooltip
        title={
          disableSave
            ? 'Select atleast one record to save'
            : !haveUnsavedRecords && 'All changes are saved'
        }>
        <span>
          <Button
            size="small"
            variant="primary"
            data-testid="btn-save"
            onClick={handleSave}
            disabled={disableSave || !haveUnsavedRecords}>
            Save
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

const CheckboxCell = (props) => {
  if (props?.row?.libraryType === CDR_TABULAR) {
    return (
      <Tooltip
        title={props.row.tooltipTitle}
        placement="top">
        <span>
          <Checkbox
            checked={!!props?.row?.checked}
            disabled={props.row.disabled}
            indeterminate={!props?.row?.checked && props?.row?.indeterminate}
            onChange={(e) => props.row.handleSelectAllRows(props?.row, e)}
          />
        </span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        title={props.row.tooltipTitle}
        placement="top">
        <span>
          <Checkbox
            checked={!!props?.row?.checked}
            disabled={props.row.disabled}
            onChange={(e) => props?.row?.handleSelectRow(props?.row, e)}
          />
        </span>
      </Tooltip>
    );
  }
};

const columns = [
  {
    accessor: 'expand',
    customCell: ExpandCell
  },
  {
    accessor: 'action',
    customCell: CheckboxCell,
    width: 40
  },
  {
    header: 'Library Name',
    accessor: 'libraryName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('libraryName'),
    fixedWidth: false
  },
  {
    header: 'Library Type',
    accessor: 'libraryTypeDisplayName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('libraryTypeDisplayName'),
    fixedWidth: false
  },
  {
    header: 'Trial Name/Data Flow Type',
    accessor: 'trialName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    customCell: SourceCell,
    filterFunction: createStringSearchFilter('trialName'),
    fixedWidth: false
  },
  {
    header: 'Data Set Type',
    accessor: 'dataSetType',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('dataSetType'),
    fixedWidth: false
  },
  {
    header: 'Version',
    accessor: 'version',
    sortFunction: compareNumbers,
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('version'),
    fixedWidth: false
  },
  {
    header: 'Vendor',
    accessor: 'vendor',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('vendor'),
    fixedWidth: false
  },
  {
    header: 'Library ID',
    accessor: 'libraryID',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('libraryID'),
    fixedWidth: false
  },
  {
    header: 'User',
    accessor: 'studyLibraryUserID',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('studyLibraryUserID'),
    fixedWidth: false
  },
  {
    header: 'Date Created',
    accessor: 'date',
    sortFunction: compareDates,
    filterFunction: dateFilter('date'),
    filterComponent: DateFilter,
    fixedWidth: false
  }
];

const DetailRow = (row) => {
  const {
    row: { subSource, handleSelectRow }
  } = row;
  return (
    <div>
      <Typography
        style={{
          fontWeight: 500,
          color: neutral8,
          marginLeft: '8rem',
          width: '10rem',
          padding: '0.5rem'
        }}>
        Source
      </Typography>

      {subSource.map((subRow) => (
        <div
          style={{ display: 'flex', padding: '8px 0px 8px 8px', marginLeft: '4rem' }}
          key={subRow.source}>
          <Checkbox
            checked={subRow.checked}
            onChange={(e) => handleSelectRow(subRow, e)}
            disabled={subRow.disabled}></Checkbox>
          <Typography
            style={{
              fontWeight: 500,
              color: neutral8,
              marginLeft: '1rem',
              width: '10rem',
              marginTop: '0.3rem'
            }}>
            {subRow.source}
          </Typography>
        </div>
      ))}
    </div>
  );
};

const formatTableRowData = (data) => {
  let updatedData = [];
  const nonCDRData = data.filter((item) => item.libraryType !== CDR_TABULAR);
  const CDRSources = data.filter((item) => item.libraryType === CDR_TABULAR);

  const CDR_UNIQUE_LIBRARY_DATA = [
    ...new Map(CDRSources.map((item) => [item.libraryName, item])).values()
  ];

  const CDRData = CDR_UNIQUE_LIBRARY_DATA.map((item) => {
    const subSource = CDRSources.filter((el) => el.libraryName === item.libraryName);
    return {
      ...item,
      source: item.libraryType !== CDR_TABULAR ? subSource.map((el) => el.source).toString() : '-',
      subSource: subSource
    };
  });
  updatedData.push(...nonCDRData);
  updatedData.push(...CDRData);
  return updatedData;
};

/**
 * SelectSourceTable
 * @param {*} props
 * @returns
 */
function SelectSourceTable(props) {
  const {
    dataSources,
    setSelectedRowsForSave,
    selectedRowsForSave,
    disableSave,
    haveUnsavedRecords,
    handleSave
  } = props;
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [specificRowError, setSpecificRowError] = React.useState('');
  const [CDRType, setCDRType] = React.useState();
  const classes = useStyles();
  const [formatedDataSources, setFormatedDataSources] = useState([]);
  const getDisplayName = useDisplayName();

  useEffect(() => {
    setFormatedDataSources(formatTableRowData(dataSources));
    const _selectedRows = {};
    dataSources.forEach((row) => {
      if (row.isMappingRuleConfigured) {
        _selectedRows[row.key] = row;
      }
    });
    setSelectedRows(_selectedRows);
  }, [dataSources]);

  useEffect(() => {
    setSelectedRowsForSave(Object.values(selectedRows));
    let data = Object.values(selectedRows);
    let flag = true;
    data.map((selectedRow) => {
      if (selectedRow.libraryType === CDR_TABULAR && !selectedRow.isCustomLibrary) {
        setCDRType(selectedRow.isCDRTestFlow);
        flag = false;
      }
    });
    if (flag) {
      setCDRType(undefined);
    }
  }, [selectedRows]);

  const checkDuplicate = (row, e) => {
    setSpecificRowError('');
    if (e.target.checked) {
      let copyOfSelectedRows = JSON.parse(JSON.stringify(selectedRows));
      let refArray = ['CDISC ODM', 'QECG', 'Q2LAB'];
      let newArray = [];
      Object.values(copyOfSelectedRows).map((el) => {
        newArray.push(el.libraryType);
      });
      if (newArray.includes(row.libraryType) && refArray.includes(row.libraryType)) {
        setSpecificRowError(row.libraryType);
        return false;
      } else if (
        !row.isCustomLibrary &&
        row.libraryType === CDR_TABULAR &&
        row.isCDRTestFlow !== row.CDRType &&
        row.CDRType !== undefined
      ) {
        return false;
      }
       else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleToggleRow = (rowId) => {
    setExpandedRows((expandedRows) =>
      expandedRows.includes(rowId)
        ? expandedRows.filter((id) => id !== rowId)
        : [...expandedRows, rowId]
    );
  };

  const handleSelectRow = async (row, e) => {
    let flag = checkDuplicate(row, e);
    if (flag) {
      //   let copyOfSelectedRows = JSON.parse(JSON.stringify(selectedRows));
      //   dataSources.forEach((el) => {
      //     if (el.key === row.key && e.target.checked) {
      //       setSelectedRows(selectedRows.length > 0 ? [...selectedRows, el] : [el]);
      //     } else if (el.key === row.key && !e.target.checked) {
      //       const rowIndex = copyOfSelectedRows.findIndex((el) => el.key === row.key);
      //       copyOfSelectedRows.splice(rowIndex, 1);
      //       setSelectedRows(copyOfSelectedRows);
      //     }
      //   });
      setSelectedRows((_selectedRows) => {
        const _modifiedSelectedRows = { ..._selectedRows };
        if (_modifiedSelectedRows[row.key]) {
          delete _modifiedSelectedRows[row.key];
        } else {
          _modifiedSelectedRows[row.key] = row;
        }
        return _modifiedSelectedRows;
      });
    }
  };

  const removeDuplicates = (data) => {
    let jsonObject = data.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    return Array.from(uniqueSet).map(JSON.parse);
  };

  const handleSelectAllRows = (row, e) => {
    // const NON_SELECTED_CDR_SOURCES = dataSources.filter(
    //   (item) => item.libraryName === name && !item.isMappingRuleConfigured
    // );
    // const selectAll =
    //   dataSources.filter((item) => item.libraryName === name).length ===
    //   selectedRows.filter((el) => el.libraryName === name).length;
    // if (dataSources && !selectAll) {
    //   const updatedSelectedRows = selectedRows.concat(NON_SELECTED_CDR_SOURCES);
    //   setSelectedRows(removeDuplicates(updatedSelectedRows));
    // } else if (dataSources && selectAll) {
    //   const OTHER_SELECTED_SOURCE_DATA = selectedRows.filter((item) => item.libraryName !== name);
    //   const updatedSelectedRows = selectedRows.filter(
    //     (item) =>
    //       !NON_SELECTED_CDR_SOURCES.map((el) => el.source).includes(item.source) &&
    //       item.libraryName === name
    //   );
    //   setSelectedRows(updatedSelectedRows.concat(OTHER_SELECTED_SOURCE_DATA));
    // }
    let flag = checkDuplicate(row, e);
    if (flag) {
      if ('subSource' in row) {
        setSelectedRows((_selectedRows) => {
          const hasEverySelected = row.subSource.every((subRow) => _selectedRows[subRow.key]);
          const _modifiedSelectedRows = { ..._selectedRows };
          row.subSource.forEach((subRow) => {
            if (hasEverySelected) {
              delete _modifiedSelectedRows[subRow.key];
            } else {
              _modifiedSelectedRows[subRow.key] = subRow;
            }
          });

          return _modifiedSelectedRows;
        });
      }
    }
  };

  const rowData = useMemo(() => {
    let selectedEDCTrialName, selectedCDRDataFlow, selectedECRFDataFlow;

    Object.values(selectedRows || {})?.forEach((row) => {
      if (
        row.isCustomLibrary
          ? row.customSQLDataSource === CDR_TABULAR
          : row.libraryType === CDR_TABULAR
      ) {
        selectedCDRDataFlow = row?.isCDRTestFlow;
        if(row?.isCDRTestFlow && row.dataSetType === ECRF_VIEW){
          selectedECRFDataFlow = row?.ecrfTrialName;
        }
      } else if (
        row.isCustomLibrary ? row.customSQLDataSource === CDISC_ODM : row.libraryType === CDISC_ODM
      ) {
        selectedEDCTrialName = row.isCustomLibrary ? row.edcTrailName : row.source;
      }
    });

    return formatedDataSources.map((row) => {
      const _row = {
        ...row,
        expanded: expandedRows.includes(row?.key) ? true : false,
        selectedRows,
        handleSelectRow,
        handleToggleRow,
        handleSelectAllRows,
        CDRType: row.libraryType === CDR_TABULAR && CDRType,
        specificRowError: row.libraryType === specificRowError && specificRowError,
        libraryTypeDisplayName: row.isCustomLibrary
          ? row.displayName
          : getDisplayName(row.libraryType)
      };
      if (row.libraryType === CDR_TABULAR && 'subSource' in row) {
        const hasEverySubSourceSelected = row.subSource.every((subRow) => selectedRows[subRow.key]);
        const hasSomeSubSourceSelected = row.subSource.some((subRow) => selectedRows[subRow.key]);
        const modifiedSubSource = row.subSource.map((subRow) => ({
          ...subRow,
          checked: !!selectedRows[subRow.key],
          disabled:
            (subRow?.customSQLDataSource === CDISC_ODM
              ? selectedEDCTrialName && subRow?.edcTrailName !== selectedEDCTrialName
              : selectedCDRDataFlow !== undefined && subRow?.isCDRTestFlow !== selectedCDRDataFlow) || 
              (subRow?.isCDRTestFlow &&
                subRow?.dataSetType === ECRF_VIEW &&
                selectedECRFDataFlow !== undefined &&
                subRow?.ecrfTrialName !== selectedECRFDataFlow)
        }));
        _row.checked = hasEverySubSourceSelected;
        _row.indeterminate = hasSomeSubSourceSelected;
        _row.subSource = modifiedSubSource;
        _row.disabled =
          (_row?.customSQLDataSource === CDISC_ODM
            ? selectedEDCTrialName && _row?.edcTrailName !== selectedEDCTrialName
            : selectedCDRDataFlow !== undefined && _row?.isCDRTestFlow !== selectedCDRDataFlow) ||
          (_row?.isCDRTestFlow &&
            _row?.dataSetType === ECRF_VIEW &&
            selectedECRFDataFlow !== undefined &&
            _row?.ecrfTrialName !== selectedECRFDataFlow);
        _row.tooltipTitle =
          _row?.disabled &&
          (_row?.customSQLDataSource !== CDISC_ODM
            ? `This is ${
                _row.isCDRTestFlow ? DATA_FLOW_TYPES.TEST : DATA_FLOW_TYPES.PROD
              } trial type`
            : _row?.customSQLDataSource === CDISC_ODM && `Only ${getDisplayName(CDISC_ODM)} with trial name ${selectedEDCTrialName} allowed`);
      } else {
        _row.checked = !!selectedRows[row.key];
        _row.disabled =
          (_row?.libraryType === CDISC_ODM &&
            selectedEDCTrialName &&
            _row?.source !== selectedEDCTrialName) ||
          Object.values(_row?.selectedRows || {})?.some(
            (_selectedRow) =>
              _selectedRow.libraryType === _row?.libraryType &&
              _selectedRow.libraryID !== _row?.libraryID
          );
        _row.tooltipTitle = (_row?.disabled) && `${getDisplayName(_row.libraryType)} already selected`
      }
      return _row;
    });
  }, [formatedDataSources, expandedRows, specificRowError, selectedRows, selectedRowsForSave]);

  return (
    <Table
      title="Select Data Sources"
      columns={columns}
      rows={rowData}
      initialSortedColumn={columns[2].accessor}
      initialSortOrder="asc"
      rowsPerPageOptions={[10, 20, 50, 100, 'All']}
      hasScroll
      maxHeight={650}
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      headerProps={{ disableSave, haveUnsavedRecords, handleSave }}
      ExpandableComponent={DetailRow}
      CustomHeader={CustomButtonHeader}
      // hidePagination
    />
  );
}

export default SelectSourceTable;
