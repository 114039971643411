/*eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DomainRulesContext } from './DomainRulesProvider';
import { useDispatch, useSelector } from 'react-redux';
import { SaveVariableMappingRules } from 'Redux/Service/MasteringRuleService';
import { unwrapResult } from '@reduxjs/toolkit';
import Filter from '../Components/VariableRules/Filter';
import { useParams } from 'react-router-dom';
import CodelistEditor from '../Components/VariableRules/CodelistEditor';
import Cookies from 'js-cookie';

const VariableRules = (props) => {
  const contextData = useContext(DomainRulesContext);
  const { formMetaData, deleteDomainVariableExpression, getVariableMappingRules } = props;
  const {
    filters,
    setFilters,
    parentNodeName,
    selectNodeText,
    setDomainData,
    domainData,
    domainFormattedData,
    getCodeListDataMapping,
    isSuppQual
  } = contextData;
  const dispatch = useDispatch();
  const { id: mappingRuleVersionID } = useParams();
  const userId = Cookies.get('user.id');
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [displayCodelistEditor, setCodelistEditorDisplay] = useState({
    display: false,
    formItems: []
  });

  const hideViewCodelist = useMemo(() => {
    const domainVariableData = domainFormattedData[parentNodeName];
    const variableData = domainVariableData?.find(
      (_variable) => _variable.variableName === selectNodeText
    );

    //checking if controlledTerms is having some value in between paranthesis
    let startIndex = variableData?.controlledTerms.indexOf('(');
    let endIndex = variableData?.controlledTerms.indexOf(')');
    return startIndex < 0 || startIndex + 1 >= endIndex;
  }, [domainFormattedData, selectNodeText]);

  useEffect(() => {
    if (!displayCodelistEditor?.display) {
      getCodeListDataMapping(true);
    }
  }, [displayCodelistEditor?.display]);

  const saveVariableRuleExp = async (isNoMap, onSave, filterName, modifiedFilter) => {
    const domainVariableRules = [];
    if (isNoMap) {
      Object.keys(filters).length > 0 &&
        filters[parentNodeName].map((filter, filIndex) => {
          if (filter && Object.keys(filter).length > 0) {
            if (filterName === filter.filterName) {
              return domainVariableRules.push({
                iqCreateDate: new Date().toISOString(),
                iqUpdateDate: new Date().toISOString(),
                iqCreatedBy: userId,
                iqUpdatedBy: userId,
                iqAuditType: 'INSERT',
                iqAuditDate: new Date().toISOString(),
                iqActiveFlag: true,
                protocolNumber: protocol.protocolNumber,
                mappingRuleVersionId: mappingRuleVersionID,
                variableName: selectNodeText,
                rowName: filter.filterName,
                domainCode: parentNodeName,
                ruleSeq: 0,
                expression: '"$NOMAP"',
                concatenate: 'N',
                rowSeq: filter.rowSeq,
                isSuppqual: isSuppQual ? 'Y' : 'N',
                defaultForm: filter.defaultForm,
                sourceName: filter.sourceName,
                sourceSYSID: 0,
                isMapRowActive: typeof filter.active === 'boolean' ? filter.active : true
              });
            }
          }
        });
    } else {
      // filters[parentNodeName].map((filter, filIndex) => {
      if (modifiedFilter && Object.keys(modifiedFilter).length > 0) {
        modifiedFilter.variables[selectNodeText].expressions.length > 0 &&
          modifiedFilter.variables[selectNodeText].expressions.map((varExp, index) => {
            domainVariableRules.push({
              iqCreateDate: new Date().toISOString(),
              iqUpdateDate: new Date().toISOString(),
              iqCreatedBy: userId,
              iqUpdatedBy: userId,
              iqAuditType: 'INSERT',
              iqAuditDate: new Date().toISOString(),
              iqActiveFlag: true,
              protocolNumber: protocol.protocolNumber,
              mappingRuleVersionId: mappingRuleVersionID,
              variableName: selectNodeText,
              rowName: modifiedFilter.filterName,
              domainCode: parentNodeName,
              ruleSeq: 0,
              expression: varExp.expression.trim(),
              concatenate: 'N',
              rowSeq: modifiedFilter.rowSeq,
              isSuppqual: isSuppQual ? 'Y' : 'N',
              defaultForm: modifiedFilter.defaultForm,
              sourceName: varExp.sourceName || modifiedFilter.sourceName,
              sourceSYSID: 0,
              isMapRowActive:
                typeof modifiedFilter.active === 'boolean' ? modifiedFilter.active : true
            });
          });
      }
    }

    const payload = {
      itemMappingRules: domainVariableRules
    };
    const response = await dispatch(SaveVariableMappingRules(payload))
      .then(unwrapResult)
      .catch((error) => {
        console.log('Error while SaveVariableMappingRules ::', error);
        return error;
      });

    if (response && response?.data?.success) {
      updateDomainData();
      getVariableMappingRules(parentNodeName);
      onSave({
        variant: 'success',
        message: 'Your expression has been saved!'
      });
      return;
    } else {
      onSave({ variant: 'error', message: response.message });
      return;
    }
  };

  const updateDomainData = () => {
    let copyOfDomainData = JSON.parse(JSON.stringify(domainData));
    let count = 0;
    filters[parentNodeName].filter(Boolean).map((filter) => {
      copyOfDomainData.map((domain) => {
        if (domain.nodeText === parentNodeName) {
          domain.childNodes.map((childNode) => {
            if (childNode.nodeText === selectNodeText) {
              if (filter.variables[selectNodeText].noMap) {
                count = count + 1;
              } else if (filter.variables[selectNodeText].expressions) {
                if (filter.variables[selectNodeText].expressions.length) {
                  count = count + 1;
                }
              }
              if (count === filters[parentNodeName].length) {
                childNode.progress = 1;
              } else if (count !== 0 && filters[parentNodeName].length !== count) {
                childNode.progress = 0.5;
              } else {
                childNode.progress = 0;
              }
            }
          });
        }
      });
    });
    setDomainData(copyOfDomainData);
  };

  const handleVariableUpdate = (
    type,
    noMap,
    modifiedExps,
    filterID,
    selectNodeText,
    onSave = () => null,
    filterName
  ) => {
    if (type === 'save') {
      let modifiedFilter;
      setFilters((filters = {}) => {
        const modifiedFilters = { ...filters };

        modifiedFilters[parentNodeName].forEach((filter) => {
          if (filter?.id === filterID) {
            filter.variables[selectNodeText].expressions = modifiedExps;
            filter.variables[selectNodeText].noMap = noMap;
            filter.variables[selectNodeText].progress = 1;
            modifiedFilter = filter;
          }
        });

        return modifiedFilters;
      });
      modifiedFilter && saveVariableRuleExp(noMap, onSave, filterName, modifiedFilter);
    } else if (type === 'delete') {
      setFilters((filters = {}) => {
        const modifiedFilters = { ...filters };
        modifiedFilters[parentNodeName].forEach((filter, index) => {
          if (filter?.id === filterID) {
            filter.variables[selectNodeText].expressions = modifiedExps;
            filter.variables[selectNodeText].noMap = noMap;
          }
        });
        return modifiedFilters;
      });
    }
  };

  return (
    <>
      {filters &&
        parentNodeName &&
        selectNodeText &&
        filters[parentNodeName]?.length > 0 &&
        (filters[parentNodeName] || []).map((filter, index) => {
          if (
            filter &&
            Object.keys(filter.variables).length > 0 &&
            filter.variables[selectNodeText] &&
            filter.variables[selectNodeText].expressions
          ) {
            return (
              filter.filterName !== '' && (
                <Filter
                  key={'Filter-' + filter?.id}
                  index={index}
                  {...(filter.variables || {})}
                  formMetaData={formMetaData}
                  handleVariableUpdate={handleVariableUpdate}
                  expressions={
                    filter.variables[selectNodeText]
                      ? filter.variables[selectNodeText].expressions
                      : []
                  }
                  filterName={filter.filterName}
                  filterExp={filter.expressions}
                  filterID={filter.id}
                  defaultForm={filter.defaultForm}
                  sourceName={filter.sourceName}
                  operator={filter.operator}
                  deleteDomainVariableExpression={deleteDomainVariableExpression}
                  hideViewCodelist={hideViewCodelist}
                  setCodelistEditorDisplay={setCodelistEditorDisplay}
                />
              )
            );
          } else {
            return null;
          }
        })}
      {displayCodelistEditor?.display ? (
        <CodelistEditor
          setCodelistEditorDisplay={setCodelistEditorDisplay}
          mappingRuleVersionID={mappingRuleVersionID}
          {...displayCodelistEditor}
        />
      ) : null}
    </>
  );
};
export default VariableRules;
