/* eslint-disable */
import EllipsisVerticalIcon from 'apollo-react-icons/EllipsisVertical';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import {
  compareStrings,
  createStringSearchFilter,
  createSelectFilterComponent,
  compareNumbers
} from 'apollo-react/components/Table';
import ElapsedTime from 'Pages/Dashboard/Components/ElapsedTime';
import { LoaderStatus, QueueStatus } from 'Pages/Dashboard/Components/Status';
import { useMappingSpecActivity } from 'Pages/ProductDesigner/Components/PublishMappingSpec/Components/UseMappingSpecActivity';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setReloadData, setView } from 'Redux/Slice/DashboardSlice';
import { setRefreshHistoryProtocol } from 'Redux/Slice/DataRefreshHistorySlice';
import {
  setDisplayChangeScheduler,
  setMappingSpecDetails
} from 'Redux/Slice/PublishMappingSpecSlice';
import { compareDates, dateFilter } from 'Utils';
import { IntegerFilter, TextFieldFilter } from '../../Components/Filters';
import { DateFilter } from 'Components/Common/DateFilter';
import './columns.css';
import { hasDashboardPipelineAccess } from 'service/AppPermissions';
import ActivateDataPipeline from 'Pages/Dashboard/Components/ActivateDataPipeline';

const dataPipelineStatusArray = [
  'Scheduled',
  'Not Scheduled',
  'Completed',
  'In Progress',
  'In Queue'
];

const schedulerJobTypes = ['Smart', 'Standard', 'External'];

const activatePipelineArray = ['Active', 'Inactive'];
const ActionCell = ({ row }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openTooltip, setTooltipOpen] = useState(false);
  const [handleFetchMappingSpecActivity] = useMappingSpecActivity();
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const onViewData = async (row) => {
    const mappSpec = {
      protocolName: row.protocolNumber,
      mappingRuleVersionID: row.mappingRuleVersionID,
      createdBy: row.createdBy,
      createdDate: row.createdDate,
      comments: row.comments,
      title: row.title,
      mappingSpecVersionName: row.mappingSpecVersionName,
      productID: row.productId,
      productMnemonic: row.productMnemonic,
      productVersion: row.productVersion,
      targetDataModelName: row.targetDataModel,
      description: row.description,
      targetDataModelVersion: row.targetDataModelVersion
    };

    const mappingSpecActivity = await handleFetchMappingSpecActivity(mappSpec);
    dispatch(
      setMappingSpecDetails({
        ...mappSpec,
        activity: mappingSpecActivity
      })
    );
    dispatch(setView(true));
  };

  const handleRefreshData = (rowData) => {
    dispatch(
      setRefreshHistoryProtocol({
        protocolNumber: rowData.protocolNumber,
        sponsor: rowData.sponsor,
        productMnemonic: rowData.productMnemonic,
        mappingSpecVersion: rowData.mappingSpecVersionName,
        environment: rowData.environment,
        mappingRuleVersionID: rowData.mappingRuleVersionID,
        targetDataModelType: rowData.targetDataModelType,
        dataSources: rowData?.dataSources,
        trialName: rowData?.trialName,
        endTime: rowData.endTime
      })
    );
    if (Object.keys(protocol).length !== 0) {
      navigate('/manage-data-pipelines/data-refresh-history');
    } else {
      navigate('/dashboard/data-product-pipelines/data-refresh-history');
    }
  };

  const handleChangeScheduler = (row) => {
    dispatch(setDisplayChangeScheduler({ display: true, row }));
  };

  const handleOpen = () => {
    setTooltipOpen(true);
  };

  let menuItems = [
    {
      text: 'View',
      onClick: () => onViewData(row),
      disabled: hasDashboardPipelineAccess(row[row.protocolNumber], 'viewActivityLog')
    },
    {
      text: 'Data Refresh History',
      href: 'javascript:void(0);',
      onClick: () => handleRefreshData(row)
    },
    {
      text: 'Change Scheduler',
      disabled:
        row.isActivePipeline === 'Inactive' ||
        hasDashboardPipelineAccess(row[row.protocolNumber], 'changeScheduler'),
      onClick: () => handleChangeScheduler(row)
    },
    {
      text: 'Refresh Data',
      disabled:
        row.isActivePipeline === 'Inactive'
          ? true
          : row.environment === 'PROD'
          ? hasDashboardPipelineAccess(row[row.protocolNumber], 'refreshDataProduction')
          : hasDashboardPipelineAccess(row[row.protocolNumber], 'refreshDataNonProduction'),
      onClick: () => dispatch(setReloadData({ type: 'Refresh', selectedRow: row }))
    },
    {
      text: 'Reload Data',
      disabled:
        row.isActivePipeline === 'Inactive'
          ? true
          : row.environment === 'PROD'
          ? hasDashboardPipelineAccess(row[row.protocolNumber], 'reloadDataProduction')
          : hasDashboardPipelineAccess(row[row.protocolNumber], 'reloadDataNonProduction'),
      onClick: () => dispatch(setReloadData({ type: 'Reload', selectedRow: row }))
    }
  ];

  return (
    // <Tooltip title="Actions" disableFocusListener>
    <IconMenuButton id="actions" menuItems={menuItems} size="small">
      <EllipsisVerticalIcon />
    </IconMenuButton>
    // </Tooltip>
  );
};

const STATUS_SORT_PRIORITY = {
  queueinprogress: 3,
  queued: 2,
  completed: 1
};

const compareQueueStatus = (...args) => {
  return (row1, row2) => {
    if (row1.queueStatusType === row2.queueStatusType) {
      return new Date(row2.queuedDateTime) - new Date(row1.queuedDateTime);
    } else if (
      STATUS_SORT_PRIORITY[row1.queueStatusType] > STATUS_SORT_PRIORITY[row2.queueStatusType]
    ) {
      return args[1] === 'asc' ? 1 : -1;
    } else if (
      STATUS_SORT_PRIORITY[row1.queueStatusType] < STATUS_SORT_PRIORITY[row2.queueStatusType]
    ) {
      return args[1] === 'asc' ? -1 : 1;
    }
  };
};

const createSelectDropdownFilter = (accessor, list) => {
  return function (row, filters) {
    if (!filters[accessor].length || filters[accessor].length === list.length) {
      return true;
    } else {
      let rowVal = filters[accessor].includes(row[accessor]);
      if (rowVal) return true;
    }
  };
};

const numberSearchFilter = (accessor) => {
  return function (row, filters) {
    if (!filters[accessor]) {
      return true;
    }
    if (!row[accessor] && row[accessor] !== 0) {
      return false;
    }
    var rowVal = row[accessor].toString();
    var filterVal = filters[accessor].toString();
    return rowVal.includes(filterVal);
  };
};

const columns = [
  {
    header: 'Active',
    accessor: 'isActivePipeline',
    // width: 120,
    filterFunction: createSelectDropdownFilter('isActivePipeline', activatePipelineArray),
    filterComponent: createSelectFilterComponent(activatePipelineArray, {
      size: 'small',
      multiple: true
    }),
    fixedWidth: false,
    customCell: ActivateDataPipeline
  },
  {
    header: 'Protocol #',
    accessor: 'protocolNumber',
    frozen: true,
    width: 120,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('protocolNumber'),
    fixedWidth: false
  },
  {
    header: 'Product Mnemonic',
    accessor: 'productMnemonic',
    frozen: true,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('productMnemonic'),
    fixedWidth: false
  },
  {
    header: 'Environment',
    accessor: 'environment',
    frozen: true,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('environment'),
    fixedWidth: false
  },
  {
    header: 'Mapping Spec Version',
    accessor: 'mappingSpecVersionName',
    frozen: true,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('mappingSpecVersionName'),
    fixedWidth: false
  },
  {
    header: 'Data Pipeline Status',
    frozen: true,
    accessor: 'queueStatusMessageLable',
    customCell: QueueStatus,
    align: 'center',
    filterFunction: createSelectDropdownFilter('queueStatusMessageLable', dataPipelineStatusArray),
    filterComponent: createSelectFilterComponent(dataPipelineStatusArray, {
      size: 'small',
      multiple: true
    }),
    // filterFunction: createStringSearchFilter('queueStatusMessageLable'),
    fixedWidth: false
  },
  {
    header: 'Scheduler Type',
    frozen: true,
    accessor: 'schedulerType',
    filterFunction: createSelectDropdownFilter('schedulerType', schedulerJobTypes),
    filterComponent: createSelectFilterComponent(schedulerJobTypes, {
      size: 'small',
      multiple: true
    }),
    fixedWidth: false
  },
  {
    header: 'Data Refresh End Time',
    accessor: 'endTime',
    align: 'center',
    width: 170,
    filterComponent: DateFilter,
    filterFunction: dateFilter('endTime'),
    fixedWidth: false
  },
  {
    header: 'Refresh Type',
    accessor: 'loaderRunType',
    hidden: true,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('loaderRunType'),
    fixedWidth: false
  },
  {
    header: 'Elapsed Time',
    accessor: 'elapsedTime',
    customCell: ElapsedTime,
    hidden: true,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('elapsedTime'),
    fixedWidth: false
  },
  {
    header: 'Data Refresh Status',
    accessor: 'loaderStatusMessageLable',
    customCell: LoaderStatus,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('loaderStatusMessageLable'),
    fixedWidth: false
  },
  {
    header: 'Data Sources',
    accessor: 'dataSources',
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('dataSources'),
    fixedWidth: false
  },
  {
    header: 'VLC Rules',
    accessor: 'vlcCount',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('vlcCount'),
    fixedWidth: false
  },
  {
    header: 'Trial Name/Data Flow Type',
    accessor: 'trialName',
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('trialName'),
    fixedWidth: false
  },
  {
    header: '',
    customCell: ActionCell,
    align: 'right'
  }
];

const columnsWithSorting = columns.map((column) => {
  if (column.accessor === 'endTime') {
    return { ...column, sortFunction: compareDates, fixedWidth: false };
  } else if (column.accessor === 'queueStatusMessageLable') {
    return { ...column, sortFunction: compareQueueStatus, fixedWidth: false };
  } else if (column.accessor === 'vlcCount') {
    return { ...column, sortFunction: compareNumbers, fixedWidth: false };
  } else {
    return { ...column, sortFunction: compareStrings, fixedWidth: false };
  }
});

export { columnsWithSorting, compareQueueStatus, ActionCell };

export default columns;
