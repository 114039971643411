import FilterIcon from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import React from 'react';
import { dateFilter, compareDates } from 'Utils';
import { DateFilter } from 'Components/Common/DateFilter';

const CustomButtonHeader = ({ toggleFilters }) => {
  return (
    <div>
      <Button
        size="small"
        variant="secondary"
        icon={FilterIcon}
        style={{ marginRight: 15 }}
        onClick={toggleFilters}>
        Filter
      </Button>
    </div>
  );
};

const AuditTable = (props) => {
  const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
    return (
      <TextField
        value={filters[accessor]}
        name={accessor}
        onChange={updateFilterValue}
        type="number"
        style={{ width: 74 }}
        margin="none"
        size="small"
      />
    );
  };

  const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
    return (
      <TextField
        value={filters[accessor]}
        name={accessor}
        onChange={updateFilterValue}
        fullWidth
        // style={{ width: 160 }}
        margin="none"
        size="small"
      />
    );
  };

  const columns = [
    {
      header: 'User',
      accessor: 'userId',
      sortFunction: compareStrings,
      filterFunction: createStringSearchFilter('userId'),
      filterComponent: TextFieldFilter,
      fixedWidth: false
    },
    {
      header: 'Date',
      accessor: 'auditDate',
      sortFunction: compareDates,
      filterFunction: dateFilter('auditDate'),
      filterComponent: DateFilter,
      fixedWidth: false
    },
    {
      header: 'Version',
      accessor: 'libraryVersion',
      sortFunction: compareNumbers,
      filterFunction: numberSearchFilter('libraryVersion'),
      filterComponent: IntegerFilter,
      fixedWidth: false
    },
    {
      header: 'Reason For Change',
      accessor: 'reasonForChange',
      sortFunction: compareStrings,
      filterFunction: createStringSearchFilter('reasonForChange'),
      filterComponent: TextFieldFilter,
      fixedWidth: false
    },
    {
      header: 'Audit ',
      accessor: 'auditType',
      sortFunction: compareStrings,
      filterFunction: createStringSearchFilter('auditType'),
      filterComponent: TextFieldFilter,
      fixedWidth: false
    }
  ];
  return (
    <Table
      columns={columns}
      rows={props.rows ? props.rows : []}
      rowsPerPageOptions={[10, 20, 50, 100, 'All']}
      hasScroll
      maxHeight={650}
      tablePaginationProps={{
        truncate: true
      }}
      CustomHeader={(prop) => <CustomButtonHeader {...prop} />}
      defaultRowsPerPage={10}
      initialSortedColumn="libraryVersion"
      initialSortOrder="asc"
    />
  );
};

export default AuditTable;
