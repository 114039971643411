import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  filterType: {
    width: '100%',
    marginBottom: '1rem !important',
    boxShadow: '0px 4px 16px rgba(0,0,0,0.04)',
    border: '1px solid #E9E9E9',
    borderRadius: '4px'
  },
  filterTypeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '48px',
    width: '97%'
  },
  filterTypeTitle: {
    fontSize: '18px',
    fontWeight: '600'
  },
  filterExpressionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #F6F7FB',
    padding: '0rem'
  },
  primaryButton: {
    height: '40px',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif',
    fontWeight: '500',
    color: '#FFF',
    border: '1px solid #d9d9d9',
    backgroundColor: '#0768fd',
    boxShadow: '0 4px 16px 0 rgb(0 0 0 / 4%)',
    fontSize: '16px',
    borderRadius: '4px',
    padding: '0 16px',
    cursor: 'pointer',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      backgroundColor: '#0557d5',
      boxShadow: '0 4px 8px 0 rgb(5 85 252 / 32%), 0 4px 16px 0 rgb(0 0 0 / 4%)'
    }
  },
  secondaryButton: {
    height: '40px',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif',
    fontWeight: '500',
    color: '#0768fd',
    border: '1px solid #d9d9d9',
    backgroundColor: '#FFF',
    boxShadow: '0 4px 16px 0 rgb(0 0 0 / 4%)',
    fontSize: '16px',
    borderRadius: '4px',
    padding: '0 16px',
    cursor: 'pointer',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      border: '1px solid #0768fd',
      backgroundColor: '#ecf3ff'
    }
  },
  andButton: {
    borderRadius: 0,
    margin: 0,
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px',
    width: '5rem'
  },
  orButton: {
    borderRadius: 0,
    margin: 0,
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px',
    width: '5rem'
  },
  disabledButton: {
    opacity: '0.4',
    cursor: 'default',
    pointerEvents: 'none'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    marginRight: '-24px',
    color: '#444444'
  },
  switch: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '13px'
  },
  pencilIcon: {
    height: '16px',
    width: '16px',
    color: '#B5B5B5',
    marginRight: '1rem',
    fontSize: '1.2rem'
  },
  editIcon: {
    height: '16px',
    width: '16px',
    opacity: '0.4',
    marginRight: '1rem',
    fontSize: '1.2rem'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 2rem',
    borderBottom: '1px solid #F6F7FB'
  },
  accordianSummary: {
    paddingRight: 0
  }
});

export { useStyles };
