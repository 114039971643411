/* eslint-disable  */
// const isoRegArray = ["DD-MM-YYYY", "YYYY", "MMM-YYYY", "DDMMMYYYY", "MM-DD-YY/YYYY", "MM-DD-YYYY", "YYYY-MM-DD", "DD-MMM-YY", "DD-MMM-YYYY", "yyyy-MM-dd HH:mm:ss"];
// let flags = "gi";
// new RegExp("^" + `"(${isoRegArray.join('|')})"` + "$", flags)
const METHODS = {
  min: {
    numberOfArguments: 1,
    min: 0,
    max: 1,
    regex: {
      0: /^(\s*"[^"]*"\s*|)$/i
    }
  },
  replace: {
    numberOfArguments: 2,
    min: 2,
    max: 2,
    regex: {
      0: /^\s*"[^"]*"\s*$/,
      1: /^\s*"[^"]*"\s*$/
    }
  },
  doesvalueexist: {
    numberOfArguments: 3,
    min: 2,
    max: 3,
    regex: {
      0: /^\s*"[^"]*"\s*$/,
      1: /^\s*"[^"]*"\s*$/,
      2: /^(\s*("\$AND"|"\$OR")\s*)/
    }
  },
  max: {
    numberOfArguments: 1,
    min: 0,
    max: 1,
    regex: {
      0: /^(\s*"[^"]*"\s*|)$/i
    }
  },
  substr: {
    numberOfArguments: 2,
    min: 2,
    max: 2,
    regex: {
      0: /^\s*\d+\s*$/,
      1: /^\s*\d+\s*$/
    }
  },
  pad: {
    numberOfArguments: 3,
    min: 3,
    max: 3,
    regex: {
      0: /^\d+/,
      1: /^\d+/,
      2: /^(left|right)/i
    }
  },
  choose: {
    numberOfArguments: 2,
    min: 2,
    max: 2,
    regex: {
      0: /[^,]{1,1}/,
      1: /^\d+$/
    }
  },
  decode: {
    numberOfArguments: 1,
    min: 0,
    max: 1,
    regex: {
      0: /^(|[\s\w]+)$/i
    }
  },
  decodemodifiedtext: {
    numberOfArguments: 1,
    min: 0,
    max: 1,
    regex: {
      0: /^(|[\s\w]+)$/i
    }
  },
  remove: {
    numberOfArguments: 2,
    min: 1,
    max: -1,
    regex: {
      '-1': /^[^,]*$/i
    }
  },
  units: {
    numberOfArguments: 1,
    min: 0,
    max: 1,
    regex: {
      0: /^(|TEXT|NAME)$/i
    }
  },
  doesvisitexist: {
    numberOfArguments: 1,
    min: 1,
    max: 1,
    regex: {
      0: /^"[^\"]*"$/
    }
  },
  length: {
    numberOfArguments: 0,
    min: 0,
    max: 0
  },
  odmeventcreatedate: {
    numberOfArguments: 0,
    min: 0,
    max: 0
  },
  upcase: {
    numberOfArguments: 0,
    min: 0,
    max: 0
  },
  specifyvalue: {
    numberOfArguments: 0,
    min: 0,
    max: 0
  },
  datepart: {
    numberOfArguments: 0,
    min: 0,
    max: 0
  },
  timepart: {
    numberOfArguments: 0,
    min: 0,
    max: 0
  },
  odmeventsequence: {
    numberOfArguments: 1,
    min: 0,
    max: 1,
    regex: {
      0: /^\d+/
    }
  },
  last: {
    numberOfArguments: 1,
    min: 1,
    max: 1,
    regex: {
      0: /^\d+$/
    }
  },
  dateentrydatetime: {
    numberOfArguments: 0,
    min: 0,
    max: 0
  },
  odmeventname: {
    numberOfArguments: 0,
    min: 0,
    max: 0
  },
  isodatetime: {
    numberOfArguments: 1,
    min: 0,
    max: 1,
    regex: {
      0: /^\s*"[^"]*"\s*$/
    }
  }
};

export default METHODS;
