/* eslint-disable */
import './App.css';
import React, { useEffect, useState } from 'react';
import routes from './RootRoutes';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import isAuth from 'Redux/Service/AuthService';
import './style/tooltip.css';
import { setPermissions } from 'service/AppPermissions';
import Interceptor from 'interceptor/interceptor';
import Cookies from 'js-cookie';
import { setUserToken } from 'service/user-token.service';
import { GetLoginInfo } from 'Redux/Service/LoginFormService';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import SessionTimeout from 'Components/SessionTimeout/SessionTimeout';
import { Loader } from 'apollo-react';
import { GetCDMNameMappings } from 'Redux/Service/AppService';

export const goToCore = () => {
  console.log('got to core');
  window.location.href = process.env.REACT_APP_CORE_URL;
};

const App = () => {
  const dispatch = useDispatch();
  const [hasPermissions, setHasPermissions] = useState(false);

  useEffect(() => {
    let func = async () => {
      try {
        const userId = Cookies.get('user.id');
        console.log('TTTTTTT', process.env);
        const permissionData = await dispatch(GetLoginInfo(userId)).then(unwrapResult);
        if (permissionData?.data.success) {
          let filteredJson = {};
          let permissionJson = permissionData.data;
          filteredJson = Object.assign(
            {},
            {
              application: permissionJson.application,
              globalLibrary: permissionJson.globalLibrary,
              appSwitcher: permissionJson.appSwitcher
            }
          );
          setPermissions(filteredJson);
          setHasPermissions(true);
        } else {
          setHasPermissions(true);
        }
      } catch (error) {
        console.log('AUTH API FAILED ERROR:', error);
      }
    };
    func();
  }, []);
  
  useEffect(() => {
    dispatch(GetCDMNameMappings());
  }, []);
  
  const checkAuth = (auth) => {
    if (!auth) {
      return true;
    }
    if (auth && isAuth()) {
      return true;
    }
    return false;
  };

  const RouteWrapper = (props) => {
    const { route } = props;
    return checkAuth(route.authentication) ? (
      <route.layout>
        <route.component {...props} />
      </route.layout>
    ) : (
      goToCore()
    );
  };

  return !hasPermissions ? (
    <Loader />
  ) : (
    <ErrorBoundary>
      <BrowserRouter>
        <SessionTimeout isAuthenticated={isAuth} />
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          {routes.map((route, index) => {
            return <Route key={index} path={route.path} element={<RouteWrapper route={route} />} />;
          })}
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
