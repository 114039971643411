import { Box, IconButton, IconMenuButton, Switch } from 'apollo-react';
import { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table/utils';
import Tooltip from 'apollo-react/components/Tooltip';
import EllipsisVerticalIcon from 'apollo-react-icons/EllipsisVertical';
import Terminal from '@mui/icons-material/Terminal';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { Error } from '@mui/icons-material';
import { createSelectFilterComponent } from 'apollo-react/components/Table';
import { DateFilter } from 'Components/Common/DateFilter';
import { compareDates, dateFilter } from 'Utils';

const ActiveCell = ({ row }) => {
  return (
    <Switch
      data-testid="switch-btn"
      checked={row.isActive}
      size="small"
      onClick={() => row.onActiveToggle(!row.isActive, row.vlcRuleName, row.vlcRuleID)}
    />
  );
};

const ExpressionCell = ({ row }) => {
  return (
    <Tooltip
      variant="light"
      title="Expression"
      body={
        <Box sx={{ color: '#0557D5', fontFamily: 'Menlo, Courier New, Monospace' }}>
          {row?.expression}
        </Box>
      }
      disableFocusListener>
      <Terminal sx={{ color: '#0557D5' }} />
    </Tooltip>
  );
};

const ActionCell = ({ row }) => {
  let menuItems = [
    {
      text: 'Edit',
      onClick: () => row.onEdit(row)
    },
    {
      text: 'Delete',
      onClick: () => row.onDelete(row.vlcRuleName, row.vlcRuleID)
    }
  ];

  return (
    <IconMenuButton id="actions" menuItems={menuItems} size="small">
      <EllipsisVerticalIcon />
    </IconMenuButton>
  );
};

const ErrorCell = ({ row }) => {
  return row?.hasError ? (
    <IconButton data-testid="error-btn" size={'small'} onClick={() => row.onEdit(row)}>
      <Error sx={{ height: '1rem', width: '1rem', color: '#E20000', mb: '-4px' }} />
    </IconButton>
  ) : (
    <></>
  );
};

const ColumnIssueCell = ({ row }) => {
  return row.columns.join(', ');
};

const RULE_ACTIVE_OPTIONS = ['Active', 'In-Active'];

const createSelectDropdownFilter = (accessor) => {
  return function (row, filters) {
    if (!filters[accessor].length || filters[accessor].length === RULE_ACTIVE_OPTIONS.length) {
      return true;
    } else {
      let rowVal = filters[accessor].includes(
        row[accessor] ? RULE_ACTIVE_OPTIONS[0] : RULE_ACTIVE_OPTIONS[1]
      );
      if (rowVal) return true;
    }
  };
};

const createArrayStringSearchFilter = (accessor) => {
  return function (row, filters) {
    if (!filters[accessor]) {
      return true;
    }
    if (!row[accessor]) {
      return false;
    }
    let rowVal = row[accessor].join(', ').toUpperCase();
    let filterVal = filters[accessor].toUpperCase();
    return rowVal.includes(filterVal);
  };
};

export const columns = [
  {
    header: 'Active',
    accessor: 'isActive',
    sortFunction: compareStrings,
    filterFunction: createSelectDropdownFilter('isActive'),
    filterComponent: createSelectFilterComponent(RULE_ACTIVE_OPTIONS, {
      size: 'small',
      multiple: true
    }),
    customCell: ActiveCell,
    width: '4rem'
  },
  {
    header: 'VLC Rule Name',
    accessor: 'vlcRuleName',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('vlcRuleName'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Dataset',
    accessor: 'dataSet',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('dataSet'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Columns to Raise Issue Against',
    accessor: 'columns',
    // sortFunction: compareStrings,
    filterFunction: createArrayStringSearchFilter('columns'),
    filterComponent: TextFieldFilter,
    customCell: ColumnIssueCell
  },
  {
    header: 'Expression',
    accessor: 'expression',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('expression'),
    filterComponent: TextFieldFilter,
    customCell: ExpressionCell
  },
  {
    header: 'VLC Action Condition',
    accessor: 'vlcActionCondition',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('vlcActionCondition'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Last Updated',
    accessor: 'iqAuditDate',
    sortFunction: compareDates,
    filterFunction: dateFilter('iqAuditDate'),
    filterComponent: DateFilter
  },
  {
    header: '',
    customCell: ActionCell,
    align: 'right'
  },
  {
    header: '',
    customCell: ErrorCell,
    width: '2rem',
    align: 'right'
  }
];

export {
  ActionCell,
  ActiveCell,
  ExpressionCell,
  ErrorCell,
  createSelectDropdownFilter,
  ColumnIssueCell,
  createArrayStringSearchFilter
};
