/*eslint-disable */
import { makeStyles } from '@mui/styles';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import { neutral8, primaryLight } from 'apollo-react/colors';
import { Checkbox } from 'apollo-react/components/Checkbox/Checkbox';
import IconButton from 'apollo-react/components/IconButton';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import classNames from 'classnames';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import React, { useEffect, useState, useMemo } from 'react';
import { getCDRStudlyLibraries, getLatestCDRStudies } from 'Redux/Service/StudyLibraryGridService';
import { compareDates, dateFilter, uuidv4, dateFormatByType } from 'Utils';
import '../../../../ProductDesigner/Components/RuleEditor/SelectDataSource/SelectSource.css';
import { useDispatch, useSelector } from 'react-redux';
import Filter from 'apollo-react-icons/Filter';
import { DateFilter } from 'Components/Common/DateFilter';
import _ from 'lodash';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { DATA_FLOW_TYPES } from '../../../../../Constants/DataFlowTypes';
import useDisplayName from 'Utils/useDisplayName';

const styles = {
  selected: {
    '& td': {
      backgroundColor: primaryLight
    }
  },
  error: {
    '& td': {
      backgroundColor: 'rgba(226,0,0,0.06)'
    }
  },
  root: {
    '& .MuiTable-root': {
      marginTop: '60px'
    }
  }
};

const useStyles = makeStyles(styles);
export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

function StudyLibrariesTable(props) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [keyForRows, setKeyForRows] = useState([]);
  const [studyTableData, setStudyTableData] = useState([]);
  const [CDRType, setCDRType] = React.useState();
  const classes = useStyles();
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const CDR_TABULAR = 'CDR Tabular';
  const eCRF_VIEW = 'eCRF View';
  const dispatch = useDispatch();
  const getDisplayName = useDisplayName();

  const tableRowDataFormat = (data) => {
    const CDR_UNIQUE_LIBRARY_DATA = [
      ...new Map(data.map((item) => [item.libraryID, item])).values()
    ];

    const CDRData = CDR_UNIQUE_LIBRARY_DATA.map((item) => {
      const subSource = data.filter((el) => el.libraryID === item.libraryID);

      return {
        ...item,
        source:
          item.libraryType !== CDR_TABULAR ? subSource.map((el) => el.source).toString() : '-',
        subSource: subSource,
        libraryTypeDisplayName: item.isCustomSqlLib
          ? item.displayName
          : getDisplayName(item.libraryType)
      };
    });
    if (_.isEmpty(props.formik.values.study) && !_.isEmpty(props.editDataProduct)) {
      handleCheckboxEditFlow(CDRData);
    }
    return CDRData;
  };

  const getCDRStudy = () => {
    dispatch(getCDRStudlyLibraries(protocol.protocolNumber)).then((res) => {
      if (res.payload.data.success) {
        const { cdrStudyLibraries } = res.payload.data;
        let studyLibrariesData = cdrStudyLibraries.map((el) => ({
          ...el,
          id: uuidv4(),
          datasetTypeDisplayName: el.datasetTypeDisplayName || 'N/A',
          trialName:
            el.customSQLDataSource === 'CDISC ODM'
              ? el.edcTrailName
              : el.isCDRTestFlow &&
                el.datasetTypeDisplayName === eCRF_VIEW &&
                el.ecrfTrialName !== ''
              ? `${DATA_FLOW_TYPES.TEST} - ${el.ecrfTrialName}`
              : el.isCDRTestFlow
              ? DATA_FLOW_TYPES.TEST
              : DATA_FLOW_TYPES.PROD,
          createdDate: dateFormatByType(el.auditDate, 'Table')
        }));
        setStudyTableData(tableRowDataFormat(studyLibrariesData));
      } else {
        dispatch(showBanner({ variant: 'error', message: res.payload.data.message }));
      }
    });
  };

  const getLatestCDR = () => {
    getLatestCDRStudies({
      mappingRuleVersionID: props?.editDataProduct?.mappingRuleversion,
      protocolName: protocol.protocolNumber
    }).then((res) => {
      if (res?.data?.success) {
        const { latestStudyLibraries } = res.data;
        let studyLibrariesData = latestStudyLibraries.map((el) => ({
          ...el,
          id: uuidv4(),
          datasetTypeDisplayName: el.datasetTypeDisplayName || 'N/A',
          trialName:
            el.customSQLDataSource === 'CDISC ODM'
              ? el.edcTrailName
              : el.dataFlowType === DATA_FLOW_TYPES.TEST &&
                el.datasetTypeDisplayName === eCRF_VIEW &&
                el.ecrfTrialName !== ''
              ? `${el.dataFlowType} - ${el.ecrfTrialName}`
              : el.dataFlowType,
          createdDate: dateFormatByType(el.lastUpdatedDate, 'Table'),
          updateAvailable: el.newVersion !== el.currentVersion,
          user: el.lastUpdatedUser
        }));
        setStudyTableData(tableRowDataFormat(studyLibrariesData));
      } else {
        dispatch(showBanner({ variant: 'error', message: res?.data?.message }));
      }
    });
  };

  useEffect(() => {
    if (props.editDataProduct !== '' && _.isEmpty(props.formik.values.study)) {
      getLatestCDR();
    } else {
      props.cdrRules?.length > 0 ? setStudyTableData(props.cdrRules) : getCDRStudy();
    }
    if (!_.isEmpty(props.formik.values.study)) {
      getUpdatedCheckboxList(props.formik.values.study);
    }
  }, []);

  const handleToggleRow = (rowId) => {
    setExpandedRows((expandedRows) =>
      expandedRows.includes(rowId)
        ? expandedRows.filter((id) => id !== rowId)
        : [...expandedRows, rowId]
    );
  };

  const ExpandCell = ({ row: { handleToggleRow, expanded, libraryType, id } }) => {
    return (
      libraryType === CDR_TABULAR && (
        <div style={{ width: 12, marginRight: '0.5rem' }}>
          <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
            <IconButton id="expand" size="small" onClick={() => handleToggleRow(id)}>
              {expanded ? <ChevronDown /> : <ChevronRight />}
            </IconButton>
          </Tooltip>
        </div>
      )
    );
  };

  const getUpdatedCheckboxList = (tableData) => {
    let parentRowFlag = false;
    let partialRowFlag = false;
    tableData.length &&
      tableData.forEach((item) => {
        parentRowFlag = item.subSource.every((item) => item.checked);
        partialRowFlag = item.subSource.some((item) => item.checked);
        if (parentRowFlag) {
          item.checked = true;
          item.partial = false;
        } else if (partialRowFlag) {
          item.partial = true;
          item.checked = false;
        } else {
          item.checked = false;
          item.partial = false;
        }
      });
    setStudyTableData(tableData);
    props.formik.values.study = tableData;
  };

  const handleCheckboxEditFlow = (tableData) => {
    let data = [...tableData];
    let updatedData = [];
    data.forEach((item) => {
      item.checked = item.isConfigured;
      item.subSource?.forEach((subItem) => {
        subItem.checked = subItem.isConfigured;
      });
      updatedData.push(item);
    });
    getUpdatedCheckboxList(updatedData);
  };

  const checkDuplicate = (row) => {
    let copyOfSelectedRows = JSON.parse(JSON.stringify(props.previewRulesetData));
    let selectedcustomSqlEdcDatasets = [];
    Object.values(copyOfSelectedRows).forEach((row) => {
      row.edcTrailName && selectedcustomSqlEdcDatasets.push(row.edcTrailName, row.libraryID);
    });
    if (row.customSQLDataSource !== 'CDISC ODM') {
      if (
        (props.editDataProduct === '' &&
          row.isCDRTestFlow != row.CDRType &&
          row.CDRType != undefined) ||
        (props.editDataProduct !== '' && row.dataFlowType === DATA_FLOW_TYPES.PROD && CDRType) ||
        (props.editDataProduct !== '' &&
          row.dataFlowType === DATA_FLOW_TYPES.TEST &&
          !CDRType &&
          CDRType !== undefined)
      ) {
        return true;
      } else if (
        ((props.editDataProduct !== '' && row.dataFlowType === DATA_FLOW_TYPES.TEST) ||
          row.isCDRTestFlow) &&
        row.datasetTypeDisplayName === eCRF_VIEW &&
        Object.values(copyOfSelectedRows || {}).some(
          (val) =>
            val.ecrfTrialName !== row.ecrfTrialName &&
            val.datasetTypeDisplayName === row.datasetTypeDisplayName
        )
      ) {
        return true;
      }
    } else if (
      !selectedcustomSqlEdcDatasets.includes(row.edcTrailName) &&
      !selectedcustomSqlEdcDatasets.includes(row.libraryID) &&
      selectedcustomSqlEdcDatasets.length > 0
    ) {
      return true;
    } else return false;
  };

  const handleSelectRow = (flag, row) => {
    const { id } = row;
    let data = [...studyTableData];
    data.forEach((item) => {
      if (item.id === id) {
        item.checked = flag;
        item.subSource?.forEach((subItem) => {
          subItem.checked = flag;
        });
      }
    });
    getUpdatedCheckboxList(data);
  };

  useEffect(() => {
    let tableData = [...studyTableData];
    let previewRulesetData = [];
    let flagCheck = true;
    tableData.forEach((item) => {
      item.subSource?.forEach((subItem) => {
        subItem.libraryTypeDisplayName = getDisplayName(item.libraryType);
        subItem.libraryVersion = subItem.libraryVersion || subItem.newVersion;
        subItem.checked && previewRulesetData.push(subItem);
        if (subItem.checked && subItem.customSQLDataSource !== 'CDISC ODM') {
          props.editDataProduct === ''
            ? setCDRType(subItem.isCDRTestFlow)
            : setCDRType(subItem.dataFlowType === DATA_FLOW_TYPES.TEST ? true : false);
          flagCheck = false;
        }
      });
    });
    if (flagCheck) {
      setCDRType(undefined);
    }
    previewRulesetData.length ? props.setDisableGlobal(true) : props.setDisableGlobal(false);
    props.setPreviewRulesetData(previewRulesetData);
    props.setRulesetData(tableData);
  }, [studyTableData]);

  const handleSelectSubRow = (flag, row, parentSubRow) => {
    let studyFormatData = [...studyTableData];
    const { id } = row;
    studyFormatData.forEach((item) => {
      if (parentSubRow.row.id === item.id) {
        item.subSource.forEach((subItem) => {
          if (subItem.id === id) {
            subItem.checked = flag;
          }
        });
      }
    });

    getUpdatedCheckboxList(studyFormatData);
  };

  const CheckboxCell = ({ row }) => {
    return (
      <div style={{ width: 16, marginTop: row.editMode ? 8 : -2 }}>
        <Checkbox
          checked={row.checked}
          onChange={(event) => row.handleSelectRow(event.target.checked, row)}
          indeterminate={row.partial}
          disabled={checkDuplicate(row)}
        />
      </div>
    );
  };

  const CheckboxSubCell = (row, parentRow) => {
    return (
      <div style={{ width: 16, marginTop: row?.editMode ? 8 : -2 }}>
        <Checkbox
          checked={row.checked}
          onChange={(event) => handleSelectSubRow(event.target.checked, row, parentRow)}
          disabled={checkDuplicate(parentRow.row)}
        />
      </div>
    );
  };

  const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
    return (
      <TextField
        value={filters[accessor]}
        name={accessor}
        onChange={updateFilterValue}
        fullWidth
        margin="none"
        size="small"
      />
    );
  };

  const columns = [
    {
      accessor: 'expand',
      customCell: ExpandCell
    },
    {
      header: 'Library Name',
      accessor: 'libraryName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryName'),
      fixedWidth: false
    },
    {
      header: 'Library Type',
      accessor: 'libraryTypeDisplayName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryTypeDisplayName'),
      fixedWidth: false
    },
    {
      header: 'Data Flow Type',
      accessor: 'trialName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('trialName'),
      fixedWidth: false
    },
    {
      header: 'Data Set Type',
      accessor: 'datasetTypeDisplayName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('datasetTypeDisplayName'),
      fixedWidth: false
    },
    props.editDataProduct === '' && {
      header: 'Version',
      accessor: 'libraryVersion',
      sortFunction: compareNumbers,
      filterComponent: IntegerFilter,
      filterFunction: numberSearchFilter('libraryVersion'),
      fixedWidth: false
    },
    props.editDataProduct !== '' && {
      header: 'Current Version',
      accessor: 'currentVersion',
      sortFunction: compareNumbers,
      filterComponent: IntegerFilter,
      filterFunction: numberSearchFilter('currentVersion'),
      fixedWidth: false
    },
    props.editDataProduct !== '' && {
      header: 'New Version',
      accessor: 'newVersion',
      sortFunction: compareNumbers,
      filterComponent: IntegerFilter,
      filterFunction: numberSearchFilter('newVersion'),
      fixedWidth: false
    },
    props.editDataProduct !== '' && {
      header: 'Reason for Change',
      accessor: 'reasonForChange',
      sortFunction: compareNumbers,
      filterFunction: createStringSearchFilter('reasonForChange'),
      filterComponent: TextFieldFilter,
      fixedWidth: false,
      customCell: props.ellipsisCell
    },
    {
      header: 'Library ID',
      accessor: 'libraryID',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryID'),
      fixedWidth: false
    },
    {
      header: 'Description',
      accessor: 'description',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('description'),
      fixedWidth: false
    },
    {
      header: 'Last Updated User',
      accessor: 'user',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('user'),
      fixedWidth: false
    },
    {
      header: 'Last updated Date',
      accessor: 'createdDate',
      sortFunction: compareDates,
      filterFunction: dateFilter('createdDate'),
      filterComponent: DateFilter,
      fixedWidth: false
    }
  ];

  const DetailRow = (row) => {
    const {
      row: { subSource }
    } = row;
    return (
      <div>
        <Typography
          style={{
            fontWeight: 500,
            color: neutral8,
            marginLeft: '8rem',
            width: '10rem',
            padding: '0.5rem'
          }}>
          Source
        </Typography>

        {subSource.map((item) => (
          <div
            style={{ display: 'flex', padding: '8px 0px 8px 8px', marginLeft: '4rem' }}
            key={item.source}>
            {CheckboxSubCell(item, row)}
            <Typography
              style={{
                fontWeight: 500,
                color: neutral8,
                marginLeft: '1rem',
                width: '10rem',
                marginTop: '0.3rem'
              }}>
              {item.source}
            </Typography>
          </div>
        ))}
      </div>
    );
  };

  const checkboxColumn = {
    header: '',
    accessor: '',
    customCell: CheckboxCell
  };

  return (
    <Table
      classes={!_.isEmpty(props.editDataProduct) && { root: classes.root }}
      title="Select Target Data Model"
      columns={[checkboxColumn, ...columns]}
      rows={studyTableData?.map((row) => ({
        ...row,
        expanded: expandedRows.includes(row?.id) ? true : false,
        handleSelectRow,
        handleToggleRow,
        CDRType: row.libraryType === CDR_TABULAR && CDRType,
        id: row?.id,
        className: classNames(keyForRows.includes(row?.id) && classes.selected, handleToggleRow)
      }))}
      initialSortedColumn={columns[2].accessor}
      initialSortOrder="asc"
      rowsPerPageOptions={[10, 20, 50, 100, 'All']}
      hasScroll
      maxHeight={650}
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      ExpandableComponent={DetailRow}
      CustomHeader={props.CustomButtonHeader}
      // hidePagination
    />
  );
}

export default StudyLibrariesTable = React.memo(StudyLibrariesTable);
