/*eslint-disable*/
import React from 'react';
import TreeItem from 'apollo-react/components/TreeItem';
import TreeView from 'apollo-react/components/TreeView';
import InProgress from 'Images/status-inprogress.svg';
import Queued from 'Images/status-queued.svg';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import { makeStyles } from '@mui/styles';
import * as colors from 'apollo-react/colors';
import Tooltip from 'apollo-react/components/Tooltip';

const useTreeItemStyles = (isMandatory, isCDRMandatory, isSUPPQUAL) =>
  makeStyles({
    treeItem: {
      '& .MuiTreeItem-label > span::after':
        isMandatory === false
          ? isCDRMandatory
            ? {
                content: "' (CDR Req)'"
              }
            : {
                content: "' (Optional)'"
              }
          : isSUPPQUAL
          ? { content: "' (Supp Qual)'" }
          : {}
    }
  });

const checkIcons = (
  nodeId,
  nodeText,
  childNodes,
  progress,
  mappedBy,
  reqLibCompleted,
  isCDRMandatory
) => {
  if (childNodes.length !== 0) return null;

  if (isCDRMandatory && reqLibCompleted && progress !== 1) {
    return (
      <Tooltip title={'CDR Completed'} disableFocusListener>
        <StatusCheck
          style={{
            color: colors['orange'],
            height: '19px'
          }}></StatusCheck>
      </Tooltip>
    );
  } else if (progress === 1) {
    return (
      <Tooltip title={'Completed'} disableFocusListener>
        <StatusCheck
          style={{
            color: mappedBy === 'R' ? colors['neutral8'] : colors['green'],
            height: '19px'
          }}></StatusCheck>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={progress === 0 ? 'Queued' : 'In Progress'} disableFocusListener>
        <span>
          <img src={`${progress === 0 ? Queued : InProgress}`} style={{ height: '15px' }} />
        </span>
      </Tooltip>
    );
  }
};

const TreeNode = ({
  node: {
    nodeId,
    nodeText,
    childNodes,
    progress,
    mappedBy,
    restrictedVariable,
    disableVariablesOnMaptype,
    isMandatory,
    reqLibCompleted,
    isCDRMandatory,
    isSUPPQUAL,
    defaultFormNotSelected
  },
  disabledVarNode = false,
  parentNodeName,
  domainsConfigured
}) => {
  const treeItemClasses = useTreeItemStyles(isMandatory, isCDRMandatory, isSUPPQUAL)();
  return (
    <Tooltip
      key={nodeId}
      title={
        disableVariablesOnMaptype && restrictedVariable
          ? 'Cannot map variables for TV, TE, TI, SV, TA Domains'
          : disableVariablesOnMaptype
          ? 'Cannot map variables for System Defined and No Mapping type'
          : disabledVarNode
          ? 'Domain Rules not configured'
          : mappedBy === 'R'
          ? 'This variable is reserved'
          : defaultFormNotSelected
          ? `${parentNodeName} not Configured`
          : undefined
      }
      disableFocusListener>
      <div>
        <TreeItem
          key={nodeId}
          nodeId={nodeId}
          label={nodeText}
          icon={checkIcons(
            nodeId,
            nodeText,
            childNodes,
            progress,
            mappedBy,
            reqLibCompleted,
            isCDRMandatory
          )}
          className={treeItemClasses.treeItem}
          disabled={
            disableVariablesOnMaptype ||
            restrictedVariable ||
            mappedBy === 'R' ||
            disabledVarNode ||
            defaultFormNotSelected
          }>
          {Array.isArray(childNodes)
            ? childNodes.map((node) => (
                <TreeNode
                  key={node?.nodeText + node?.nodeId}
                  node={node}
                  disabledVarNode={
                    domainsConfigured &&
                    !domainsConfigured[nodeText]?.filter(Boolean)[0]?.filterName
                  }
                  parentNodeName={nodeText}
                  domainsConfigured={domainsConfigured}
                />
              ))
            : null}
        </TreeItem>
      </div>
    </Tooltip>
  );
};

export const TreeViewExample = (props) => {
  let data = props.data;
  let domainsConfigured = props.domainsConfigured;

  return (
    <TreeView
      style={{ textAlign: 'left' }}
      expanded={props.expanded}
      selected={props.selected}
      onNodeToggle={props.handleToggle}
      onNodeSelect={props.handleSelect}
      multiSelect>
      {data && data.map((node) => <TreeNode node={node} domainsConfigured={domainsConfigured} />)}
    </TreeView>
  );
};
