/*eslint-disable*/
import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from 'apollo-react/components/Button';
import Typography from 'apollo-react/components/Typography';
import Grid from 'apollo-react/components/Grid';
import Radio from 'apollo-react/components/Radio';
import CloseCircle from 'apollo-react-icons/CloseCircle';

import { makeStyles } from '@mui/styles';

import { DomainRulesMiddlePanel } from '../Components/DomainRulesComponents';
import SequenceOrder from './SequenceOrder';
import { DomainRulesContext } from './DomainRulesProvider';
import { useDispatch, useSelector } from 'react-redux';
import { uuidv4 } from 'Utils';
import Tooltip from 'apollo-react/components/Tooltip';
import {
  GetRuleMetadataFormItems,
  GetVariableMappingRules,
  SaveVariableMappingRules
} from 'Redux/Service/MasteringRuleService';
import { unwrapResult } from '@reduxjs/toolkit';
import Filter from '../Components/DomainRules/Filter';
import { downloadGlobalLibrary } from 'Redux/Service/GlobalLibraryService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import {
  GetDomainMapType,
  SaveDomainMapType,
  GetRuleExpressionTemplates,
  DeleteVariableRule,
  GetSystemDefinedDomains,
  GetDomainMessages
} from 'Redux/Service/DomainRuleService';
import CustomModal from 'Components/Modal';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import VariableRules from './VariableRules';
import { GetRuleExpressionMethodsAndConstants } from 'Redux/Service/MasteringRuleService';
import SearchFields from '../Components/DomainRules/SearchFields';
import Pencil from 'apollo-react-icons/Pencil';
import { ToggleItemMappingRow } from 'Redux/Service/DomainRuleService';
import { DeleteDomainVariableRule } from 'Redux/Service/DomainRuleService';
import BookLibrary from 'Images/book-library.svg';
import { GetProductDataSourcesByMappingRuleVersionID } from 'Redux/Service/RuleEditorService';

import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import Footer from 'Components/Footer';
import { setCacheFlag } from 'Redux/Slice/DomainPeviewSlice';
import { setCreateMappingObj } from 'Redux/Slice/RuleEditorSlice';
import { getMethodsList, getRuleList } from '../Utils/DomainUtils';
import Cookies from 'js-cookie';
import { Notification } from 'Components/Common/Notification';
import CircularProgress from 'apollo-react/components/CircularProgress';
import { LoaderOverlay } from '../Components/LoaderOverlay';

const SYSTEM_DEFINED_RULES_UNAVAILABLE_MSG =
  'This domain does not have any System Defined rules, hence no data will be available to view in the domain table.';
const BORDER_BOX = 'border-box';
const PROXIMA_NOVA_FONT = 'Proxima Nova';
const DOMAIN_MESSAGE =
  'The system will automatically apply standard transformation rules to this domain’s variables.';
const useStyles = makeStyles({
  header: {
    display: 'flex',
    width: '100%',
    boxSizing: BORDER_BOX,
    flexDirection: 'column',
    padding: '0px 22px',
    fontFamily: PROXIMA_NOVA_FONT
  },
  body: {
    boxSizing: BORDER_BOX,
    display: 'flex',
    flexDirection: 'column',
    padding: '22px',
    width: '100%',
    fontFamily: PROXIMA_NOVA_FONT
  },
  footer: {
    boxSizing: BORDER_BOX,
    padding: '1rem 1rem 0 1rem',
    fontFamily: PROXIMA_NOVA_FONT
  },
  loader: {
    '&>div': {
      marginTop: 'calc(50vh - 113px)'
    }
  }
});

const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959'
};
const EmptyTableTypographyStyleTitle2 = {
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '20px',
  color: '#999999',
  marginTop: '5px'
};

const SYSTEM_DEFINED = 'System Defined';
const USER_DEFINED = 'User Defined';
const NO_MAPPING = 'No Mapping';
const CDR_TABULAR = 'CDR Tabular';
const PSEUDO_FORMS = 'PSeudo Forms';

const DomainRules = (props) => {
  const contextData = useContext(DomainRulesContext);
  const { handleStepperValidate } = props;
  const {
    setExpanded,
    filters,
    setFilters,
    formMetaData,
    setFormMetaData,
    setParentNodeName,
    parentNodeName,
    parentNodeID,
    selectNodeText,
    setDomainData,
    domainData,
    setDomainFormattedData,
    domainFormattedData,
    confirmAlert,
    // setConfirmAlert,
    setNewDomainFormattedData,
    newDomainFormattedData,
    setMethodsAndConstants,
    setExpressionTemplates,
    description,
    setDescription,
    sourceFormItems,
    searchFieldsEnabled,
    setSelectedNodeText,
    setSelected,
    mappingError,
    setMappingError,
    isSuppQual,
    setIsSuppQual
  } = contextData;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: mappingRuleVersionID } = useParams();

  const location = useLocation();
  // const { createMappingObj = {} } = location?.state || {};
  const { createMappingObj = {} } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const [addFiltersDisabled, disableAddFilters] = useState(false);
  const [disabledFlag, setDisabledFlag] = useState(false);
  const [noMapDisabled, setNoMapDisabled] = useState(false);
  const [disablePreview, setPreviewDisable] = useState(true);
  const [productDataSources, setDataSources] = useState([]);

  const userId = Cookies.get('user.id');
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [mappingFieldsDisabled, disableMappingFields] = useState(false);
  const [domainRulesData, setDomainRules] = useState([]);
  const [systemDefinedDomains, setSystemDefinedDomains] = useState([]);
  const [mappedDomainType, setMappedDomainType] = useState({});
  const [enableDomainForPreview, setEnableDomainForPreview] = useState([]);
  const [restrictedDomainList, setRestrictedDomainList] = useState([]);
  const [domainMessageData, setDomainMessageData] = useState({});
  const [domainMessageConfig, setDomainMessageConfig] = useState(false);
  const [domainMessage, setDomainMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();

  const getDomainMessagesData = async () => {
    const domainNameData = await dispatch(GetDomainMessages()).then(unwrapResult);
    let newArr = [];
    if (domainNameData && domainNameData.data && domainNameData.data.success) {
      Object.keys(domainNameData.data.domainMessages).forEach((key) => {
        newArr[key] = domainNameData.data.domainMessages[key];
      });
      setDomainMessageData(newArr);
    } else {
      dispatch(showBanner({ variant: 'error', message: domainNameData.data.message }));
    }
  };

  useEffect(() => {
    let matchedMessage = null;

    Object.entries(domainMessageData).some(([key, values]) => {
      values.find((value) => {
        if (mappedDomainType[parentNodeName] === key) {
          if (value.domainName === parentNodeName || value.domainName === '*') {
            matchedMessage =
              value.domainName === '*' ? matchedMessage || value.message : value.message;
          }
        }
      });
      return matchedMessage;
    });

    if (matchedMessage) {
      setDomainMessageConfig(true);
      setDomainMessage(matchedMessage);
    } else {
      setDomainMessageConfig(false);
    }
  }, [parentNodeName, mappedDomainType]);

  const getSourceData = async () => {
    const getTableData = await dispatch(
      GetProductDataSourcesByMappingRuleVersionID(mappingRuleVersionID)
    ).then(unwrapResult);
    if (getTableData.data.success) {
      setDataSources(getTableData.data.ruleStudyLibrary);
    } else {
      dispatch(showBanner({ variant: 'error', message: getTableData.data.message }));
    }
  };

  useEffect(() => {
    getSourceData();
  }, [mappingRuleVersionID]);

  useEffect(() => {
    let previewDisabled = true;
    if (
      mappedDomainType[parentNodeName] === SYSTEM_DEFINED &&
      !restrictedDomainList.includes(parentNodeName) &&
      enableDomainForPreview.includes(parentNodeName)
    ) {
      previewDisabled = false;
    } else if (mappedDomainType[parentNodeName] === USER_DEFINED) {
      let count = 0;
      domainData.length > 0 &&
        domainData.forEach((domain) => {
          if (domain.nodeText === parentNodeName) {
            domain.childNodes.forEach((childNode) => {
              if (childNode.progress !== 1) {
                count = count + 1;
              }
            });
          }
        });

      previewDisabled = count !== 0;
    }
    setPreviewDisable(previewDisabled);
  }, [domainData, mappedDomainType, parentNodeName, selectNodeText]);

  const handleDomainPreviewToggle = () => {
    navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/domain-preview`, {
      state: {
        domainCode: parentNodeName,
        nodeId: parentNodeID
      }
    });
  };

  useEffect(() => {
    if (domainData && domainData.length > 0 && !parentNodeName) {
      if (Object.keys(createMappingObj).length > 0 && createMappingObj['domainName']) {
        const nodeData = domainData.find((domain) => {
          return domain.nodeText === createMappingObj['domainName'];
        });
        if (nodeData) {
          if (
            createMappingObj['variableName'] &&
            !createMappingObj['variableName'].includes('Q_ROW_CONDITION')
          ) {
            const childNodeData = nodeData.childNodes.find((childNode) => {
              return childNode.nodeText === createMappingObj['variableName'];
            });
            setExpanded([nodeData?.nodeId]);
            setParentNodeName(createMappingObj['domainName']);
            setSelectedNodeText(createMappingObj['variableName']);
            setSelected([childNodeData?.nodeId]);
            setMappingError(createMappingObj['message']);
          } else {
            setExpanded([nodeData?.nodeId]);
            setParentNodeName(createMappingObj['domainName']);
            setSelectedNodeText('');
            setSelected([nodeData?.nodeId]);
            setMappingError(createMappingObj['message']);
          }
        } else {
          setMappingError('');
          setExpanded([domainData[0]?.nodeId]);
          setParentNodeName(domainData[0]?.nodeText);
        }
        let tempMappingObj = {};
        dispatch(setCreateMappingObj(tempMappingObj));
      } else {
        setMappingError('');
        setExpanded([domainData[0]?.nodeId]);
        setParentNodeName(domainData[0]?.nodeText);
      }
    }
  }, [domainData]);

  useEffect(() => {
    const copyDomainData = JSON.parse(JSON.stringify(domainData));
    if (Object.keys(filters).length && copyDomainData.length && systemDefinedDomains.length) {
      let userDefinedDomains = [];
      for (let domain in mappedDomainType) {
        if (mappedDomainType[domain] === 'User Defined') userDefinedDomains.push(domain);
      }

      let count = 0;

      //  remove domains from userDefinedDomains if they are mapped as system or no mapped domains //
      userDefinedDomains.forEach((userDomain) => {
        if (systemDefinedDomains.includes(userDomain)) {
          const index = userDefinedDomains.indexOf(userDomain);
          if (index > -1) {
            userDefinedDomains.splice(index, 1);
          }
        }
      });

      copyDomainData.forEach((domain) => {
        if (userDefinedDomains.includes(domain.nodeText)) {
          let childNodesOfDomain = domain.childNodes;
          childNodesOfDomain.length &&
            childNodesOfDomain.map((eachNode) => {
              if (eachNode.progress !== 1) {
                count = count + 1;
              }
            });
        }
      });

      handleStepperValidate(count !== 0);
    }
  }, [domainData, systemDefinedDomains, filters]);

  const getDomainData = () => {
    downloadGlobalLibrary({
      libraryId: rowDataForRuleEditor.targetDataModelID,
      libraryVersion: rowDataForRuleEditor.targetDataModelVersion,
      isAllColumns: true,
      mappingRuleVersionID: mappingRuleVersionID
    })
      .then((res) => {
        if (res.data.success) {
          let { downloadDomainColumns } = res.data;
          let modifiedDomainObj = {};

          downloadDomainColumns.forEach((item) => {
            if (!modifiedDomainObj[item.domainPrefix]) modifiedDomainObj[item.domainPrefix] = [];

            if (
              modifiedDomainObj[item.domainPrefix].findIndex(
                (domain) => domain.variableName === item.variableName
              ) !== -1
            )
              return;

            modifiedDomainObj[item.domainPrefix].push({
              domainName: item.domainPrefix,
              restrictedFlag: restrictedDomainList.includes(item.domainPrefix),
              variableName: item.variableName,
              mappedBy: item.mapped_By,
              core: item.core,
              seqForOrder: item.seqForOrder,
              role: item?.role,
              controlledTerms: item.controlledTerms,
              disableVariablesOnMaptype:
                mappedDomainType[item.domainPrefix] === undefined
                  ? false
                  : mappedDomainType[item.domainPrefix] !== undefined &&
                    mappedDomainType[item.domainPrefix] !== USER_DEFINED
                  ? true
                  : false
            });
          });
          /**
           * Extract Key from the object
           * Check whether the new object has the key or not
           * Sort the data set based on the role type
           * sort both suppalData and not suppual data
           * merge them to the data
           */

          let objectKeys = Object.keys(modifiedDomainObj);
          let sortedDomainObj = {};

          for (let key of objectKeys) {
            if (sortedDomainObj[key] === undefined) {
              sortedDomainObj[key] = [];
              let suppqualData = [];
              let notSuppqualData = [];

              modifiedDomainObj[key].forEach((item) => {
                if (item?.role === 'SUPPQUAL') {
                  suppqualData.push({ ...item, isSUPPQUAL: true });
                } else {
                  notSuppqualData.push({ ...item, isSUPPQUAL: false });
                }
              });

              suppqualData = suppqualData.sort(
                (variable1, variable2) => variable1?.seqForOrder - variable2?.seqForOrder
              );
              notSuppqualData = notSuppqualData.sort(
                (variable1, variable2) => variable1?.seqForOrder - variable2?.seqForOrder
              );

              sortedDomainObj = {
                ...sortedDomainObj,
                [key]: [...notSuppqualData, ...suppqualData]
              };
            }
          }

          modifiedDomainObj = sortedDomainObj;
          setDomainFormattedData(modifiedDomainObj);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDomainMessagesData();
    setNewDomainFormattedData({});
    dispatch(setCacheFlag(false));
  }, []);

  const generateChildArray = (item, idx) => {
    let childArray = [];
    let lastIndex = idx;
    item.forEach((el, index) => {
      childArray.push({
        nodeId: index + idx,
        nodeText: el.variableName,
        childNodes: [],
        progress: el.mappedBy === 'R' ? 1 : 0,
        required: el.core,
        mappedBy: el.mappedBy,
        controlledTerms: el.controlledTerms,
        restrictedVariable: el.restrictedFlag,
        disableVariablesOnMaptype: el.disableVariablesOnMaptype,
        isSUPPQUAL: el?.isSUPPQUAL
      });
      lastIndex = index + idx;
    });

    return { childArray, lastIndex };
  };

  useEffect(() => {
    let objIndex = 1;
    let filteredDomainData =
      Object.keys(newDomainFormattedData).length > 0 ? newDomainFormattedData : domainFormattedData;
    let filteredData = Object.keys(filteredDomainData);
    let domainTreeView = [];
    filteredData.forEach((el) => {
      const childObj = generateChildArray(filteredDomainData[el], objIndex + 1);
      const childrenArray = filteredDomainData[el] ? childObj.childArray : [];
      const childCounter = filteredDomainData[el] ? childObj.lastIndex : undefined;
      domainTreeView.push({
        nodeId: objIndex,
        nodeText: el,
        childNodes: childrenArray
      });
      objIndex = childCounter ? childCounter + 1 : objIndex + 1;
    });

    let domainTreeData = JSON.parse(JSON.stringify(domainTreeView));
    let filtersList = Object.keys(filters);
    filtersList.forEach((eachFilter) => {
      domainTreeData.forEach((domain) => {
        if (domain.nodeText === eachFilter) {
          domain.childNodes.forEach((childNode) => {
            if (childNode.progress === 1 && childNode.mappedBy === 'R') {
              return;
            } else {
              let count = 0;
              filters &&
                filters[eachFilter].forEach((filter) => {
                  if (
                    filter?.variables[childNode.nodeText] &&
                    (filter.variables[childNode.nodeText].expressions.length > 0 ||
                      filter.variables[childNode.nodeText].noMap)
                  ) {
                    count = count + 1;
                  }
                });
              if (count !== 0 && count === filters[eachFilter]?.filter(Boolean).length) {
                childNode.progress = 1;
              } else if (count !== 0 && filters[eachFilter]?.filter(Boolean).length !== count) {
                childNode.progress = 0.5;
              } else {
                childNode.progress = 0;
              }
            }
          });
        }
      });
    });

    setDomainData(domainTreeData);
  }, [domainFormattedData, newDomainFormattedData, filters]);

  const getDomainMapType = async () => {
    setSystemDefinedDomains([]);
    const payload = {
      mappingRuleVersionID,
      protocolNumber: protocol.protocolNumber
    };
    const response = await dispatch(GetDomainMapType(payload))
      .then(unwrapResult)
      .catch((error) => {
        console.log('Error while updating mapping type :: ', error);
        disableMappingFields(false);
      });
    let mappedDomainTypeObj = {};
    let systemDefinedArr = [];
    if (response.data.success) {
      response.data.mappings.forEach((domainType) => {
        mappedDomainTypeObj[domainType.domainName] = domainType.domainMapType;
        if (
          domainType.domainMapType === SYSTEM_DEFINED ||
          domainType.domainMapType === NO_MAPPING
        ) {
          systemDefinedArr.push(domainType.domainName);
        }
      });
    }
    systemDefinedArr.push('QSP', 'SUBJMAST', 'TI', 'TE', 'TA', 'TV', 'SV');
    setSystemDefinedDomains(systemDefinedArr);
    setMappedDomainType(mappedDomainTypeObj);

    disableMappingFields(false);
  };

  const getRuleMetadataFormItems = async () => {
    try {
      const libraryTypes = [];
      const dataSources = [];
      for (let library of productDataSources) {
        if (library.isMappingRuleConfigured) {
          libraryTypes.includes(library.libraryType) || libraryTypes.push(library.libraryType);
          if (library.libraryType === CDR_TABULAR)
            dataSources.includes(library.source) || dataSources.push(library.source);
        }
      }

      const payload = {
        mappingRuleVersionID,
        forms: []
      };
      libraryTypes.includes('CDISC ODM') && payload.forms.push(1);
      libraryTypes.includes(CDR_TABULAR) && payload.forms.push(2);
      libraryTypes.includes('Q2LAB') && payload.forms.push(3);
      libraryTypes.includes('QECG') && payload.forms.push(4);

      const { data: { formItemsDict } = { formItemsDict: {} } } = await dispatch(
        GetRuleMetadataFormItems(payload)
      ).then(unwrapResult);

      const ruleMetaDataFormItems = {};

      if (formItemsDict['CDISC ODM'])
        ruleMetaDataFormItems['CDISC ODM'] = formItemsDict['CDISC ODM'];
      if (formItemsDict['ECG']) ruleMetaDataFormItems['QECG'] = formItemsDict['ECG'];
      if (formItemsDict['LAB']) ruleMetaDataFormItems['Q2LAB'] = formItemsDict['LAB'];
      if (formItemsDict[CDR_TABULAR]) {
        ruleMetaDataFormItems[CDR_TABULAR] = [];
        formItemsDict[CDR_TABULAR].forEach(
          (_formItem) =>
            dataSources.includes(_formItem.formName) &&
            ruleMetaDataFormItems[CDR_TABULAR].push(_formItem)
        );
      }

      if (formItemsDict[PSEUDO_FORMS])
        ruleMetaDataFormItems[PSEUDO_FORMS] = formItemsDict[PSEUDO_FORMS];

      setFormMetaData(ruleMetaDataFormItems);
    } catch (error) {
      console.log('Failed to retrive FormItems MetaData :: ', error);
    }
  };

  useEffect(() => {
    getDomainData();
  }, [mappedDomainType]);

  const deleteDomainVariableExpression = useCallback(async (variableRuleIds, onDelete) => {
    const payload = {
      variableRuleIds
    };

    const response = await dispatch(DeleteVariableRule(payload))
      .then(unwrapResult)
      .catch((error) => {
        console.log('Error while DeleteVariableRule ::', error);
        return error;
      });

    onDelete && onDelete(response?.data?.success ? 'success' : 'error', response?.data?.message);
  }, []);

  const handleAddFilter = () => {
    setFilters((oldFilters = {}) => {
      const modifiedFilters = { ...oldFilters };
      if (modifiedFilters[parentNodeName]?.length > 0) {
        modifiedFilters[parentNodeName].push({
          id: uuidv4(),
          expressions: [],
          filterName: '',
          operator: '"$OR"',
          active: undefined,
          inProgress: true,
          variables: {}
        });
      } else {
        modifiedFilters[parentNodeName] = [
          {
            id: uuidv4(),
            expressions: [],
            filterName: '',
            operator: '"$OR"',
            active: undefined,
            inProgress: true,
            variables: {}
          }
        ];
      }
      return modifiedFilters;
    });
  };

  const getVariableMappingRules = async (specificDomain) => {
    setLoading(true);
    let mappedDomains = Object.keys(domainFormattedData);
    let payload = {
      mappingRuleVersionID,
      mappedDomains
    };
    let domainRules = {};
    if (specificDomain) {
      payload.mappedDomains = [specificDomain];
      domainRules = { ...filters };
      delete domainRules[specificDomain];
    }
    const MappingRules =
      mappedDomains.length && (await dispatch(GetVariableMappingRules(payload)).then(unwrapResult));
    if (MappingRules && MappingRules.data && MappingRules.data.success) {
      let rules = MappingRules.data.itemMappingRules;

      setDomainRules(rules);
      rules.forEach((expression) => {
        if (!domainRules[expression.domainCode]) {
          domainRules[expression.domainCode] = [];
        }

        if (domainRules[expression.domainCode][expression.rowSeq - 1]) {
          if (expression.variableName === 'Q_ROW_CONDITION') {
            domainRules[expression.domainCode][expression.rowSeq - 1].expressions.push({
              id: expression.variableRuleId || uuidv4(),
              expression: expression.expression,
              isValid: true,
              inProgress: false,
              sourceName: expression.sourceName
            });
          } else if (expression.variableName === 'Q_ROW_CONDITION_OP') {
            domainRules[expression.domainCode][expression.rowSeq - 1].operator =
              expression.expression;
            domainRules[expression.domainCode][expression.rowSeq - 1].sourceName =
              expression.sourceName;
            domainRules[expression.domainCode][expression.rowSeq - 1].active =
              expression.isMapRowActive;
            domainRules[expression.domainCode][expression.rowSeq - 1].defaultForm =
              expression.defaultForm;
            domainRules[expression.domainCode][expression.rowSeq - 1].filterName =
              expression.rowName;
            domainRules[expression.domainCode][expression.rowSeq - 1].id =
              expression.variableRuleId;
            domainRules[expression.domainCode][expression.rowSeq - 1].rowSeq = expression.rowSeq;
          } else {
            if (
              domainRules[expression.domainCode][expression.rowSeq - 1].variables[
                expression.variableName
              ]
            ) {
              domainRules[expression.domainCode][expression.rowSeq - 1].variables[
                expression.variableName
              ].expressions.push({
                id: expression.variableRuleId || uuidv4(),
                expression: expression.expression,
                isValid: true,
                inProgress: false,
                sourceName: expression.sourceName
              });
            } else {
              domainRules[expression.domainCode][expression.rowSeq - 1].variables[
                expression.variableName
              ] = {
                noMap: expression.expression === '"$NOMAP"',
                expressions:
                  expression.expression === '"$NOMAP"'
                    ? []
                    : [
                        {
                          id: expression.variableRuleId || uuidv4(),
                          expression: expression.expression,
                          isValid: true,
                          inProgress: false,
                          sourceName: expression.sourceName
                        }
                      ]
              };
            }
          }
        } else {
          if (expression.variableName === 'Q_ROW_CONDITION') {
            domainRules[expression.domainCode][expression.rowSeq - 1] = {
              id: expression.variableRuleId || uuidv4(),
              expressions: [
                {
                  id: expression.variableRuleId || uuidv4(),
                  expression: expression.expression,
                  isValid: true,
                  inProgress: false,
                  sourceName: expression.sourceName
                }
              ],
              filterName: expression.rowName,
              operator: '',
              active: expression.isMapRowActive,
              inProgress: false,
              defaultForm: expression.defaultForm,
              variables: {}
            };
          } else if (expression.variableName === 'Q_ROW_CONDITION_OP') {
            domainRules[expression.domainCode][expression.rowSeq - 1] = {
              id: expression.variableRuleId || uuidv4(),
              expressions: [],
              filterName: expression.rowName,
              operator: expression.expression,
              active: expression.isMapRowActive,
              inProgress: false,
              defaultForm: expression.defaultForm,
              variables: {},
              sourceName: expression.sourceName,
              rowSeq: expression.rowSeq
            };
          } else {
            domainRules[expression.domainCode][expression.rowSeq - 1] = {
              id: expression.variableRuleId || uuidv4(),
              expressions: [],
              filterName: expression.rowName,
              operator: '',
              inProgress: false,
              defaultForm: expression.defaultForm,
              variables: {
                [expression.variableName]: {
                  noMap: expression.expression === '"$NOMAP"',
                  expressions:
                    expression.expression === '"$NOMAP"'
                      ? []
                      : [
                          {
                            id: expression.variableRuleId || uuidv4(),
                            expression: expression.expression,
                            isValid: true,
                            inProgress: false,
                            sourceName: expression.sourceName
                          }
                        ]
                }
              }
            };
          }
        }
      });
    }

    if (specificDomain) {
      domainRules[parentNodeName]?.length > 0 &&
        domainRules[parentNodeName].forEach((filter) => {
          if (filter?.variables && !filter.variables[selectNodeText]) {
            let nodeVariableData = {
              [selectNodeText]: {
                id: uuidv4(),
                expressions: [],
                noMap: false,
                inProgress: false
              }
            };
            filter.variables = Object.assign(filter.variables, nodeVariableData);
          }
        });
    }

    setFilters(domainRules);
    if (
      parentNodeName &&
      !domainRules[parentNodeName]?.length &&
      mappedDomainType[parentNodeName] === USER_DEFINED
    ) {
      handleAddFilter();
    }
    setLoading(false);
  };

  useEffect(() => {
    let copyOfDomainData = JSON.parse(JSON.stringify(domainData));
    if (copyOfDomainData.length && domainRulesData.length) {
      domainRulesData.forEach((variableRule) => {
        copyOfDomainData.forEach((domain) => {
          if (domain.nodeText === variableRule.domainCode) {
            domain.childNodes.forEach((childNode) => {
              if (
                childNode.nodeText === variableRule.variableName &&
                variableRule.expression !== ''
              ) {
                childNode.progress = 1;
              }
            });
          }
        });
      });
    }
  }, [domainRulesData]);

  const saveRuleExpressions = async (_filters, onSave) => {
    setLoading(true);
    const domainVariableRules = [];
    _filters[parentNodeName]?.forEach((filter, index) => {
      if (!filter) return;

      domainVariableRules.push({
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: 'INSERT',
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionId: mappingRuleVersionID,
        variableName: 'Q_ROW_CONDITION_OP',
        rowName: filter.filterName,
        domainCode: parentNodeName,
        ruleSeq: 0,
        expression: filter.operator,
        defaultForm: filter.defaultForm,
        sourceName: filter.sourceName,
        sourceSYSID: 0,
        concatenate: 'N',
        rowSeq: filter.rowSeq,
        isSuppqual: 'N',
        isMapRowActive: typeof filter.active === 'boolean' ? filter.active : true
      });
      filter.expressions.forEach((expression) => {
        domainVariableRules.push({
          iqCreateDate: new Date().toISOString(),
          iqUpdateDate: new Date().toISOString(),
          iqCreatedBy: userId,
          iqUpdatedBy: userId,
          iqAuditType: 'INSERT',
          iqAuditDate: new Date().toISOString(),
          iqActiveFlag: true,
          protocolNumber: protocol.protocolNumber,
          mappingRuleVersionId: mappingRuleVersionID,
          variableName: 'Q_ROW_CONDITION',
          rowName: filter.filterName,
          domainCode: parentNodeName,
          ruleSeq: 0,
          expression: expression.expression.trim(),
          defaultForm: filter.defaultForm,
          sourceName: expression.sourceName || filter.sourceName,
          sourceSYSID: 0,
          concatenate: 'N',
          rowSeq: filter.rowSeq,
          isSuppqual: 'N',
          isMapRowActive: typeof filter.active === 'boolean' ? filter.active : true
        });
      });
    });
    const payload = {
      itemMappingRules: domainVariableRules
    };
    const response = await dispatch(SaveVariableMappingRules(payload))
      .then(unwrapResult)
      .catch((error) => {
        console.log('Error while SaveVariableMappingRules ::', error);
        return error;
      });

    if (response && response?.data?.success) {
      onSave({
        enabled: true,
        variant: 'success',
        message: 'Edits to filter have been saved'
      });
      setTimeout(() => onSave({ enabled: false }), 3000);
      getVariableMappingRules(parentNodeName);
    } else {
      onSave({ variant: 'error', message: response.message });
      setTimeout(() => onSave({ enabled: false }), 3000);
    }
    setLoading(false);
  };

  const getMethodsAndConstants = async () => {
    const methodsAndConstantsResponse = await dispatch(GetRuleExpressionMethodsAndConstants()).then(
      unwrapResult
    );
    const method = getMethodsList(methodsAndConstantsResponse);
    method === false
      ? dispatch(
          showBanner({ variant: 'error', message: methodsAndConstantsResponse.data.message })
        )
      : setMethodsAndConstants(method.methodsAndConstants);
  };

  const getRuleExpressionTemplates = async () => {
    const expressionTemplatesResponse = await dispatch(GetRuleExpressionTemplates(false)).then(
      unwrapResult
    );
    const ruleExp = getRuleList(expressionTemplatesResponse);
    ruleExp === false
      ? dispatch(
          showBanner({ variant: 'error', message: expressionTemplatesResponse.data.message })
        )
      : setExpressionTemplates(ruleExp);
  };

  const getSystemDefinedDomains = async () => {
    const enableDomainPreview = [];
    const systemDefinedDomainsList = [];
    const systemDefinedDomainsResponse = await dispatch(GetSystemDefinedDomains()).then(
      unwrapResult
    );
    if (systemDefinedDomainsResponse?.data?.success) {
      systemDefinedDomainsResponse.data.systemDefinedDomains.forEach((item) => {
        if (item.enablePreview) {
          enableDomainPreview.push(item.domainName);
        }
        if (item.mappingType === SYSTEM_DEFINED) {
          systemDefinedDomainsList.push(item.domainName);
        }
      });
      setRestrictedDomainList(systemDefinedDomainsList);
      setEnableDomainForPreview(enableDomainPreview);
    } else {
      dispatch(
        showBanner({ variant: 'error', message: systemDefinedDomainsResponse.data.message })
      );
    }
  };

  useEffect(() => {
    (async () => {
      await getDomainMapType();
      getDomainData();
      getMethodsAndConstants();
      getRuleExpressionTemplates();

      getSystemDefinedDomains();
    })();
  }, []);

  useEffect(() => {
    getRuleMetadataFormItems();
  }, [productDataSources]);

  useEffect(() => {
    getVariableMappingRules();
  }, [domainFormattedData]);

  useEffect(() => {
    disableAddFilters(
      filters[parentNodeName]?.reduce((prevVal, filter) => {
        if (filter?.inProgress) {
          return true;
        }
        return prevVal;
      }, false) || false
    );
  }, [filters, parentNodeName]);

  const handleMappingChange = async (modifiedMappingType) => {
    disableMappingFields(true);
    let domainMapType = 0;
    if (modifiedMappingType === SYSTEM_DEFINED) {
      domainMapType = 1;
    } else if (modifiedMappingType === USER_DEFINED) {
      domainMapType = 2;
    } else if (modifiedMappingType === NO_MAPPING) {
      domainMapType = 3;
    } else {
      disableMappingFields(false);
      return;
    }

    const payload = {
      mappingRuleVersionID,
      protocolNumber: protocol.protocolNumber,
      domainName: parentNodeName,
      domainMapType,
      iqCreateDate: new Date().toISOString(),
      iqCreatedBy: Cookies.get('user.id')
    };

    const response = await dispatch(SaveDomainMapType(payload))
      .then(unwrapResult)
      .catch((error) => {
        console.log('Error while updating mapping type :: ', error);
        dispatch(
          showBanner({
            variant: 'error',
            message: error?.message || 'Failed to save Domain Mapping.'
          })
        );
        disableMappingFields(false);
      });
    if (response?.data?.success) {
      getDomainMapType();
    } else {
      disableMappingFields(false);
      dispatch(
        showBanner({
          variant: 'error',
          message: response?.data?.message || 'Failed to save Domain Mapping.'
        })
      );
    }
  };

  useEffect(() => {
    if (parentNodeName !== '') {
      disableMappingFields(false);
      setNoMapDisabled(false);
      if (
        restrictedDomainList.includes(parentNodeName) &&
        (mappedDomainType[parentNodeName] === '' || mappedDomainType[parentNodeName] === undefined)
      ) {
        handleMappingChange(SYSTEM_DEFINED);
        setDisabledFlag(true);
      } else if (
        restrictedDomainList.includes(parentNodeName) &&
        mappedDomainType[parentNodeName] !== ''
      ) {
        setDisabledFlag(true);
      } else if (
        parentNodeName.toUpperCase() === 'DM' &&
        (mappedDomainType[parentNodeName] === '' || mappedDomainType[parentNodeName] === undefined)
      ) {
        handleMappingChange(USER_DEFINED);
        disableMappingFields(true);
      } else if (parentNodeName.toUpperCase() === 'DM' && mappedDomainType[parentNodeName] !== '') {
        disableMappingFields(true);
      } else if (parentNodeName.toUpperCase() === 'SV') {
        setNoMapDisabled(true);
      } else {
        setDisabledFlag(false);
      }
    }
    if (parentNodeName === 'CTP_DRUGDEV') {
      if (!(parentNodeName in mappedDomainType)) {
        handleMappingChange(SYSTEM_DEFINED);
      }
    }
  }, [parentNodeName, mappedDomainType]);

  useEffect(() => {
    const coreMapping = {
      REQ: 'Required',
      EXP: 'Expected',
      PERM: 'Permissable'
    };

    if (
      parentNodeName &&
      !filters[parentNodeName]?.length &&
      mappedDomainType[parentNodeName] === USER_DEFINED
    ) {
      handleAddFilter();
    }
    selectNodeText &&
      domainFormattedData[parentNodeName].forEach((variableData) => {
        if (variableData.variableName === selectNodeText) {
          setDescription(coreMapping[variableData.core] || variableData.core || '');
          setIsSuppQual(variableData.isSUPPQUAL);
        }
      });
  }, [parentNodeName, selectNodeText]);

  const handleFilterUpdate = (
    type,
    filterId,
    modifiedFilterData,
    saveToRemote = false,
    onSave = () => null
  ) => {
    switch (type) {
      case 'update': {
        setFilters((oldFilters = {}) => {
          let filterToSave;
          const modifiedFilters = { ...oldFilters };
          modifiedFilters[parentNodeName]?.forEach((filter, index) => {
            let rowSeq = filter?.rowSeq;
            if (!rowSeq) {
              rowSeq =
                1 +
                Math.max(
                  0,
                  ...modifiedFilters[parentNodeName]
                    ?.filter(Boolean)
                    .map((_filter) => _filter.rowSeq)
                    .filter(Boolean)
                );
            }
            if (filter?.id === filterId) {
              modifiedFilters[parentNodeName][index] = { ...filter, ...modifiedFilterData, rowSeq };
              filterToSave = { [parentNodeName]: [] };
              filterToSave[parentNodeName][index] = { ...filter, ...modifiedFilterData, rowSeq };
            }
          });
          if (saveToRemote && filterToSave) saveRuleExpressions(filterToSave, onSave);

          return modifiedFilters;
        });
        break;
      }
      case 'delete': {
        setFilters((oldFilters = {}) => {
          const modifiedFilters = { ...oldFilters };
          modifiedFilters[parentNodeName]?.splice(
            modifiedFilters[parentNodeName].findIndex((filter) => filter?.id === filterId),
            1
          );
          return modifiedFilters;
        });
        break;
      }
    }
  };

  const handleIsActiveUpdate = useCallback(
    async (isActive, filterId, filterName, rowSeq, disableActiveToggle, setBanner) => {
      disableActiveToggle(true);
      const payload = {
        itemMappingRows: [
          {
            iqCreateDate: new Date().toISOString(),
            iqUpdateDate: new Date().toISOString(),
            iqCreatedBy: userId,
            iqUpdatedBy: userId,
            iqAuditType: 'UPDATE',
            iqAuditDate: new Date().toISOString(),
            iqActiveFlag: true,
            protocolNumber: protocol.protocolNumber,
            mappingRuleVersionId: mappingRuleVersionID,
            domainCode: parentNodeName,
            rowName: filterName,
            rowSeq,
            isMapRowActive: isActive
          }
        ]
      };

      const response = await dispatch(ToggleItemMappingRow(payload))
        .then(unwrapResult)
        .catch((error) => {
          console.log('Error while ToggleItemMappingRow ::', error);
          return error;
        });

      if (response && response?.data?.success) {
        handleFilterUpdate('update', filterId, {
          active: isActive
        });
        setBanner({
          enabled: true,
          variant: 'success',
          message: `Active flag for filter ${filterName} saved.`
        });
      } else {
        setBanner({
          enabled: true,
          variant: 'error',
          message: `Failed to save Active flag for filter ${filterName}.`
        });
      }
      disableActiveToggle(false);
      setTimeout(() => setBanner({ enabled: false }), 3000);
    },
    [userId, protocol, mappingRuleVersionID, parentNodeName, domainFormattedData, filters]
  );

  const deleteDomainVariableRule = useCallback(
    async (filterId, filterName, rowSeq) => {
      const payload = {
        itemMappingRows: [
          {
            iqCreateDate: new Date().toISOString(),
            iqUpdateDate: new Date().toISOString(),
            iqCreatedBy: userId,
            iqUpdatedBy: userId,
            iqAuditType: 'DELETE',
            iqAuditDate: new Date().toISOString(),
            iqActiveFlag: true,
            protocolNumber: protocol.protocolNumber,
            mappingRuleVersionId: mappingRuleVersionID,
            domainCode: parentNodeName,
            rowName: filterName,
            rowSeq
          }
        ]
      };

      const response = await dispatch(DeleteDomainVariableRule(payload))
        .then(unwrapResult)
        .catch((error) => {
          console.log('Error while DeleteDomainVariableRule ::', error);
          return error;
        });

      if (response && response?.data?.success) {
        handleFilterUpdate('delete', filterId);
        dispatch(showBanner({ variant: 'success', message: response.data.message }));
      } else {
        dispatch(showBanner({ variant: 'error', message: response.data.message }));
      }
    },
    [userId, protocol, mappingRuleVersionID, parentNodeName, domainFormattedData, filters]
  );

  const renderMappingUI = ({ mappingType }) => {
    switch (mappingType) {
      case SYSTEM_DEFINED: {
        return (
          <Grid container spacing={0} style={{ padding: '4rem', justifyContent: 'center' }}>
            <span style={{ width: '40%', textAlign: 'center' }}>
              {!enableDomainForPreview.includes(parentNodeName)
                ? SYSTEM_DEFINED_RULES_UNAVAILABLE_MSG
                : DOMAIN_MESSAGE}
            </span>
          </Grid>
        );
      }
      case NO_MAPPING: {
        return (
          <Grid container spacing={0} style={{ padding: '4rem', justifyContent: 'center' }}>
            <span style={{ width: '40%', textAlign: 'center' }}>
              No mapping rules will be applied to this domain’s variables.
            </span>
          </Grid>
        );
      }
      case USER_DEFINED: {
        return (
          <>
            <SequenceOrder />
            <Grid container spacing={0} style={{ padding: '1rem 0rem' }}>
              {filters[parentNodeName]?.map((filter, index) =>
                filter ? (
                  <Filter
                    key={filter.id}
                    rowSeq={filter.rowSeq}
                    formMetaData={formMetaData}
                    handleFilterUpdate={handleFilterUpdate}
                    handleIsActiveUpdate={handleIsActiveUpdate}
                    deleteDomainVariableRule={deleteDomainVariableRule}
                    deleteDomainVariableExpression={deleteDomainVariableExpression}
                    {...(filter || {})}
                    isLoading={isLoading}
                  />
                ) : null
              )}
              <span
                style={{
                  marginTop: '1rem',
                  width: '100%',
                  minWidth: '100%',
                  backgroundColor: '#D9D9D9',
                  height: '1px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Tooltip title="Add a new filter" disableFocusListener>
                  <CloseCircle
                    onClick={() => addFiltersDisabled || handleAddFilter()}
                    style={{
                      transform: 'rotate(45deg)',
                      color: '#0768FD',
                      cursor: 'pointer',
                      ...(addFiltersDisabled ? { opacity: '0.5', cursor: 'default' } : {})
                    }}
                  />
                </Tooltip>
              </span>
            </Grid>
          </>
        );
      }
      default: {
        return (
          <>
            <Grid
              container
              style={{
                display: 'grid',
                placeItems: 'center',
                position: 'relative',
                padding: '13rem'
              }}>
              <img width="23" height="15" src={BookLibrary} style={{ marginBottom: '1rem' }} />
              <Grid xs={6}>
                <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
                  Nothing to See Here
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
                  Select a domain to begin.
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      }
    }
  };

  useEffect(() => {
    if (parentNodeName && selectNodeText) {
      let copyOFFilters = JSON.parse(JSON.stringify(filters));
      copyOFFilters[parentNodeName]?.length > 0 &&
        copyOFFilters[parentNodeName].forEach((filter) => {
          if (
            filter &&
            filter['variables'][selectNodeText] === undefined &&
            Object.keys(filter['variables'].length > 0)
          ) {
            let appendObj = {
              [selectNodeText]: {
                id: uuidv4(),
                expressions: [],
                noMap: false,
                inProgress: false
              }
            };
            filter['variables'] = Object.assign(filter['variables'], appendObj);
          }
        });
      setFilters(copyOFFilters);
    }
  }, [parentNodeName && selectNodeText]);

  const renderHeader = useCallback(() => {
    return parentNodeName && selectNodeText ? 'Variable Rules' : 'Domain Rules';
  }, [parentNodeName, selectNodeText]);

  const onBulkEditClick = () => {
    navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/batch-edit`, {
      state: {
        domainCode: parentNodeName,
        nodeId: parentNodeID
      }
    });
  };

  const renderBody = () => {
    if (parentNodeName && !selectNodeText) {
      return (
        <>
          <Grid
            container
            spacing={0}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
            <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid item>
                <Radio
                  disabled={mappingFieldsDisabled}
                  value="System Defined"
                  label="System Defined"
                  checked={mappedDomainType[parentNodeName] === SYSTEM_DEFINED}
                  onChange={() => handleMappingChange(SYSTEM_DEFINED)}
                />
              </Grid>
              <Grid item>
                <Radio
                  disabled={disabledFlag || mappingFieldsDisabled}
                  value="User Defined"
                  label="User Defined"
                  checked={mappedDomainType[parentNodeName] === USER_DEFINED}
                  onChange={() => handleMappingChange(USER_DEFINED)}
                />
              </Grid>
              <Grid item>
                <Radio
                  disabled={disabledFlag || mappingFieldsDisabled || noMapDisabled}
                  value="No Mapping"
                  label="No Mapping"
                  checked={mappedDomainType[parentNodeName] === NO_MAPPING}
                  onChange={() => handleMappingChange(NO_MAPPING)}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                size="small"
                disabled={disablePreview}
                onClick={handleDomainPreviewToggle}
                data-testid="preview">
                Preview
              </Button>
              <Button
                variant="secondary"
                size="small"
                icon={Pencil}
                disabled={
                  mappedDomainType[parentNodeName] !== USER_DEFINED ||
                  !filters ||
                  !filters[parentNodeName] ||
                  !filters[parentNodeName]?.filter(Boolean)[0]?.filterName
                }
                onClick={onBulkEditClick}
                data-testid="batch-edit">
                Batch Edit
              </Button>
            </Grid>
          </Grid>
          {renderMappingUI({ mappingType: mappedDomainType[parentNodeName] })}
        </>
      );
    } else if (parentNodeName && selectNodeText) {
      return (
        <>
          <VariableRules
            formMetaData={formMetaData}
            deleteDomainVariableExpression={deleteDomainVariableExpression}
            getVariableMappingRules={getVariableMappingRules}
            isSuppQual={isSuppQual}
          />
        </>
      );
    } else return null;
  };

  return (
    <>
      {isLoading ? (
        <LoaderOverlay>
          <CircularProgress variant="indeterminate" size={51} />
        </LoaderOverlay>
      ) : null}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            width: '25%',
            backgroundColor: 'white',
            position: 'sticky',
            top: '0px',
            minHeight: 'calc(100vh - 113px)',
            height: 'fit-content'
          }}>
          <DomainRulesMiddlePanel />
        </div>
        <div
          style={{
            width: '75%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 113px)'
          }}>
          <div>
            <Breadcrumb style={{ background: 'transparent', padding: '10px 0px' }} />

            <Box className={classes.header}>
              <Typography variant="h3">{renderHeader()}</Typography>
              <Typography
                variant="title"
                style={{ fontSize: '18px', margin: '3px 0', color: '#444444' }}>
                {parentNodeName}
                {selectNodeText && `: ${selectNodeText} (${description}) `}
              </Typography>
              <Box mt={2}>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                      Product Mnemonic
                    </Typography>
                    <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                      {rowDataForRuleEditor && rowDataForRuleEditor.productNnemonic}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                      Description
                    </Typography>
                    <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                      {rowDataForRuleEditor && rowDataForRuleEditor.description}
                    </Typography>
                  </Grid>
                </Grid>
                {domainMessageConfig && (
                  <Notification
                    message={domainMessage}
                    style={{ margin: '1rem 0 0 0', padding: '0' }}
                  />
                )}
                <Typography style={{ color: 'red', marginTop: '10px' }}>
                  {mappingError || null}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.body}>{renderBody()}</Box>
          </div>
          <Footer />
        </div>
      </div>

      <CustomModal
        display={confirmAlert.enabled}
        {...(confirmAlert || {})}
        buttonPrimaryLabel={'Ok'}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
      {searchFieldsEnabled?.enabled && (
        <SearchFields sourceFormItems={sourceFormItems} {...searchFieldsEnabled} />
      )}
    </>
  );
};

export default DomainRules;
