/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { GetCDMNameMappings } from 'Redux/Service/AppService';

/**
 * Application Slice - To handle the redux operations for common data, that is used accross the application
 */
export const AppSlice = createSlice({
  name: 'ApplicationCommonData',

  initialState: {
    nameMappings: {}
  },
  reducers: {},

  extraReducers: {
    [GetCDMNameMappings.fulfilled](state, { payload }) {
      if (payload?.data?.cdmNamingConversionsLookUp) {
        const _nameMappings = {};
        payload.data.cdmNamingConversionsLookUp.forEach((nameMapping) => {
          _nameMappings[nameMapping.actualValue] = nameMapping.displayValue;
        });
        state.nameMappings = _nameMappings;
        console.log('nameMappings', state.nameMappings);
      }
    }
  }
});
