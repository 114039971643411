/*eslint-disable */
import React, { useContext, useMemo, useState } from 'react';
import Search from 'apollo-react/components/Search';
import Divider from 'apollo-react/components/Divider';
import InProgress from 'Images/status-inprogress.svg';
import Queued from 'Images/status-queued.svg';
import Tooltip from 'apollo-react/components/Tooltip/Tooltip';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import { makeStyles } from '@mui/styles';
import * as colors from 'apollo-react/colors';
import Panel from 'apollo-react/components/Panel/Panel';
import Modal from 'apollo-react/components/Modal/Modal';
import { BulkEditContext } from 'Layout/BulkEditLayout/BulkEditLayout';
import { debounce } from 'lodash';

const useStyles = makeStyles({
  tile: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 10px 35px',
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(35, 114, 253, 0.08) !important'
    }
  }
});

const DomainFiltersMiddlePanel = () => {
  const classes = useStyles();
  const context = useContext(BulkEditContext);
  const {
    parentNodeName,
    bulkEditFilters,
    setSelectedFilterNameInBulkScreen,
    selectedFilterNameInBulkScreen,
    filterToVariablesMapping,
    setBulkEditDomain,
    isVariablesSame
  } = context;
  const [openSidepanel, setOpenSidepanel] = useState(true);
  const [showFilterChangeModal, setShowFilterChangeModal] = useState(false);
  const [clickedFilter, setClickedFilter] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [selectedFilterID, setSelectedFilterID] = useState(bulkEditFilters[0]?.id);

  const handleFilterClick = (id) => {
    const index = bulkEditFilters.findIndex((_filter) => _filter?.id === id);
    if (index === clickedFilter) return;
    if (isVariablesSame) {
      setClickedFilter(index);
      setSelectedFilterNameInBulkScreen(index);
      setBulkEditDomain(
        filterToVariablesMapping[bulkEditFilters[index].filterName].variables
      );
      setSelectedFilterID(bulkEditFilters[index].id);
    } else {
      setShowFilterChangeModal(true);
      setClickedFilter(index);
    }
  };

  const handleCancelModal = () => {
    setClickedFilter(selectedFilterNameInBulkScreen);
    setShowFilterChangeModal(false);
  };

  const handleLeaveModal = () => {
    setShowFilterChangeModal(false);
    setSelectedFilterNameInBulkScreen(clickedFilter);
    setBulkEditDomain(
      filterToVariablesMapping[bulkEditFilters[clickedFilter].filterName].variables
    );
    setSelectedFilterID(bulkEditFilters[clickedFilter].id);
  };

  const checkIcons = (progress) => {
    return progress === 1 ? (
      <Tooltip title={'Completed'} disableFocusListener>
        <StatusCheck
          style={{
            color: colors['green'],
            height: '18px',
            marginTop: '-10px',
            marginRight: '6px'
          }}></StatusCheck>
      </Tooltip>
    ) : (
      <Tooltip title={progress === 0 ? 'Queued' : 'In Progress'} disableFocusListener>
        <span>
          <img
            src={`${progress === 0 ? Queued : InProgress}`}
            style={{ height: '15px', marginRight: '5px' }}
          />
        </span>
      </Tooltip>
    );
  };

  const searchedBulkEditFilters = useMemo(() => {
    return bulkEditFilters.filter((_filter) =>
      `${_filter.filterName} (${_filter.defaultForm})`
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  }, [bulkEditFilters, searchText]);

  return (
    <Panel
      width={350}
      onClose={() => setOpenSidepanel(false)}
      onOpen={() => setOpenSidepanel(true)}
      open={openSidepanel}>
      <div
        style={{
          height: 'calc(100vh - 128px)',
          backgroundColor: '#fff',
          overflow: 'auto'
        }}>
        <div style={{ width: '75%', marginLeft: '35px', marginBottom: '30px', marginTop: '15px' }}>
          <div
            style={{
              fontSize: '16px',
              fontWeight: 500
            }}>
            {parentNodeName} Filter Names
          </div>
          <div>{searchedBulkEditFilters.length} Filters</div>
          <Search
            placeholder="Search"
            fullWidth
            onChange={debounce((e) => setSearchText(e.target.value), 300)}
          />
          <Divider
            style={{
              width: '113%',
              marginLeft: '-2px',
              marginTop: '10px'
            }}
          />
        </div>
        {searchedBulkEditFilters.map((_filter, index) => (
          <div
            key={_filter.id}
            className={classes.tile}
            style={{
              backgroundColor: _filter.id === selectedFilterID ? 'rgba(35, 114, 253, 0.08)' : '#fff'
            }}
            onClick={() => handleFilterClick(_filter.id)}>
            <div>
              <span style={{ fontWeight: 'normal' }}>{_filter.filterName}</span>
              <span> ({_filter.defaultForm})</span>
            </div>
            <span>{checkIcons(filterToVariablesMapping[_filter.filterName].status)}</span>
          </div>
        ))}
      </div>
      <Modal
        open={showFilterChangeModal}
        variant="warning"
        onClose={handleCancelModal}
        title="Are you sure you want to leave this filter?"
        message="Your progress will not be saved, Would you still like to leave?"
        buttonProps={[
          { label: 'Cancel', onClick: handleCancelModal },
          { label: 'Leave', onClick: handleLeaveModal }
        ]}
        id="warning"
      />
    </Panel>
  );
};

export default DomainFiltersMiddlePanel;
