/*eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'apollo-react/components/Button';
import Typography from 'apollo-react/components/Typography';
import Grid from 'apollo-react/components/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from 'apollo-react/components/Switch';
import * as colors from 'apollo-react/colors';
import { makeStyles } from '@mui/styles';
import Banner from 'apollo-react/components/Banner';
import { uuidv4 } from 'Utils';
import { DomainRulesContext } from '../../DomainRules/DomainRulesProvider';
import InProgress from 'Images/status-inprogress.svg';
import Queued from 'Images/status-queued.svg';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import FilterIcon from 'apollo-react-icons/Filter';
import FilterExpression from './FilterExpression';
import Fade from '@mui/material/Fade';
import validate from 'Pages/Validator/validate';
import ERROR from 'Pages/Validator/messages';

const useStyles = makeStyles({
  filterType: {
    width: '100%',
    marginBottom: '1rem !important',
    boxShadow: '0px 4px 16px rgba(0,0,0,0.04)',
    border: '1px solid #E9E9E9',
    borderRadius: '4px'
  },
  filterTypeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '48px',
    width: '100%'
  },
  filterTypeTitle: {
    fontSize: '18px'
  },
  defaultFormTitle: {
    fontWeight: '500',
    color: '#898989'
  },
  defaultForm: {
    fontSize: '1rem',
    margin: '0 0.5rem',
    fontWeight: '600'
  },
  filterExpressionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #F6F7FB',
    padding: '0rem'
  },
  modal: {
    maxHeight: '50%',
    width: '50%',
    '&>div:nth-child(2)': {
      maxHeight: '70%',
      overflow: 'hidden'
    }
  },
  filterExpressionsPopup: {
    whiteSpace: 'pre',
    background: '#F6F7FB',
    padding: '1rem',
    borderRadius: '0.2rem',
    maxHeight: '20vh',
    width: 'calc(100% - 2rem)',
    overflow: 'auto',
    fontWeight: '500',
    fontSize: '1rem'
  },
  accordianSummary: {
    paddingRight: 0
  }
});

const Filter = (props) => {
  const {
    expressions: filterExpressions = [],
    id: VariableExpId,
    inProgress,
    filterName,
    operator,
    handleVariableUpdate,
    defaultForm,
    filterExp,
    filterID,
    index,
    deleteDomainVariableExpression,
    hideViewCodelist,
    setCodelistEditorDisplay
  } = props;
  const contextData = useContext(DomainRulesContext);
  const {
    selectNodeText,
    setConfirmAlert,
    sourceFormItems,
    domainFormattedData,
    parentNodeName,
    codeListDataMapping,
    referenceData
  } = contextData;
  const classes = useStyles();
  const [expressions, setExpressions] = useState([]);
  const [noMap, setNoMap] = useState(false);
  const [banner, setBanner] = useState({ enabled: false, variant: 'info', message: '' });
  const [status, setStatus] = useState(0);
  const [allForms, setAllForms] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [formItemMapping, setFormItemMapping] = useState({});
  const [mappedBy, setMappedBy] = useState('');
  const [disableDoNotMap, setDisableDoNotMap] = useState(false);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    let arr = [];
    let mapping = {};
    const keys = Object.keys(sourceFormItems);
    setAllForms(keys);
    keys.forEach((key) => {
      mapping[key] = sourceFormItems[key].items;
      arr.push(...sourceFormItems[key].items);
    });
    setAllItems(arr);
    setFormItemMapping(mapping);
  }, [sourceFormItems]);

  useEffect(() => {
    setExpressions(JSON.parse(JSON.stringify(filterExpressions)));
    setConfirmAlert({
      enabled: false
    });
  }, [filterExpressions]);

  useEffect(() => {
    if (props && props[selectNodeText]) {
      setNoMap(props[selectNodeText].noMap);
    }
  }, [props[selectNodeText]]);

  const checkVariableIsSystem = () => {
    const domainNameData = domainFormattedData[parentNodeName];
    return domainNameData?.filter((domain) => {
      return domain.variableName === selectNodeText;
    });
  };

  useEffect(() => {
    const variable = checkVariableIsSystem();
    if (variable[0]?.mappedBy === 'S') {
      setDisableDoNotMap(true);
    } else {
      setDisableDoNotMap(false);
    }
    setMappedBy(variable[0]?.mappedBy);
  }, [selectNodeText]);

  const handleAddExpression = () => {
    setExpressions((exps) => [
      ...exps,
      { id: uuidv4(), expression: '', isValid: true, inProgress: true }
    ]);
    setDisableSave(false);
  };

  const handleExpressionUpdate = (type, expId, modifiedExpression, modifiedSourceName) => {
    switch (type) {
      case 'save-exp': {
        setExpressions((exps) => {
          const modifiedExps = [...exps];
          modifiedExps.forEach((exp) => {
            if (exp.id === expId) {
              exp.expression = modifiedExpression;
              exp.isValid = true;
              exp.result = {};
            }
          });
          return modifiedExps;
        });
        break;
      }
      case 'save-source-name': {
        setExpressions((exps) => {
          const modifiedExps = [...exps];
          modifiedExps.forEach((exp) => {
            if (exp.id === expId) {
              exp.sourceName = modifiedSourceName;
              exp.expression = modifiedExpression || exp.expression;
            }
          });
          return modifiedExps;
        });
        break;
      }
      case 'save-all': {
        setDisableSave(true);
        const modifiedExps = [...expressions];
        let isError = false;
        modifiedExps.forEach((exp) => {
          const result = validateRuleExpression(exp.expression);
          exp.isValid = result.isValid;
          exp.result = result;
          if (!exp.isValid) {
            isError = true;
            exp.inProgress = true;
          } else {
            exp.inProgress = false;
          }
        });

        setExpressions(modifiedExps);

        if (isError) {
          setDisableSave(false);
        } else {
          handleVariableUpdate(
            'save',
            noMap,
            modifiedExps,
            filterID,
            selectNodeText,
            ({ variant, message }) => {
              setBanner({ enabled: true, variant, message });
              setTimeout(() => setBanner({ enabled: false, variant: 'info', message: '' }), 3000);
            }
          );
        }

        break;
      }
      case 'edit-exp': {
        setExpressions((exps) => {
          const modifiedExps = [...exps];
          modifiedExps.forEach((exp) => {
            if (exp.id === expId) {
              exp.inProgress = true;
              exp.isValid = true;
            }
          });
          return modifiedExps;
        });
        setDisableSave(false);
        break;
      }
      case 'cancel-all': {
        setNoMap(props[selectNodeText].noMap);
        setExpressions(JSON.parse(JSON.stringify(filterExpressions)));
        setDisableSave(true);
        break;
      }
      case 'delete-exp': {
        const copyOfExps = [...expressions];
        copyOfExps.splice(
          copyOfExps.findIndex((exp) => {
            return exp.id === expId;
          }),
          1
        );
        setExpressions(copyOfExps);

        deleteDomainVariableExpression([expId], (variant, message) => {
          if (variant === 'success') {
            handleVariableUpdate('delete', noMap, copyOfExps, filterID, selectNodeText);
          }
          setBanner({ enabled: true, variant, message });
          setTimeout(() => setBanner({ enabled: false, variant: 'info', message: '' }), 3000);
        });
        break;
      }
    }
  };

  const openExpressionsModal = () => {
    const filterConditions = () => {
      const operatorMap = { '"$OR"': 'OR', '"$AND"': 'AND' };
      return (
        <Box className={classes.filterExpressionsPopup}>
          {filterExp.map((filter) => filter.expression).join(`\n${operatorMap[operator]}\n`)}
        </Box>
      );
    };
    const buttonProps = [
      {
        label: 'Close',
        onClick: () => {
          setConfirmAlert({
            enabled: false
          });
        },
        variant: 'secondary'
      }
    ];
    setConfirmAlert({
      enabled: true,
      title: 'Filter Conditions',
      subtitle: filterName,
      body: filterConditions(),
      buttonProps,
      width: classes.modal,
      onConfirm: () => {
        setConfirmAlert({
          enabled: false
        });
      },
      onCancel: () => {
        setConfirmAlert({
          enabled: false
        });
      }
    });
  };

  const handleStatusFlag = (flag, expression) => {
    !flag || props[selectNodeText].noMap
      ? setStatus(1)
      : flag && expression === ''
      ? setStatus(0.5)
      : setStatus(0);
  };

  const validateRuleExpression = (inputExp) => {
    let result = {
      isValid: true,
      message: '',
      cordinates: { start: 0, end: inputExp.length }
    };

    if (mappedBy === 'S') {
      if (inputExp.toLowerCase() === '"$nomap"') {
        result.isValid = false;
        result.message = ERROR.INVALID_EXPRESSION;
        return result;
      }
    }
    if (mappedBy === 'D') {
      if (inputExp.toLowerCase() === '"$sysmap"') {
        result.isValid = false;
        result.message = ERROR.USER_DEFINED_VARIABLE;
        return result;
      }
    }

    result = validate(inputExp, defaultForm, codeListDataMapping, formItemMapping, referenceData);
    return result;
  };

  return (
    <Accordion defaultExpanded className={classes.filterType}>
      <AccordionSummary
        className={classes.accordianSummary}
        expandIcon={<ExpandMoreIcon />}
        style={{ flexDirection: 'row-reverse' }}>
        <Box className={classes.filterTypeHeader}>
          <Typography variant={'h3'} className={classes.filterTypeTitle}>
            {filterName}
          </Typography>

          <Box
            onClick={(e) => e.stopPropagation()}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'default',
              color: '#444444'
            }}>
            <Typography className={classes.defaultForm}>
              <span className={classes.defaultFormTitle}>Default form: </span>
              {defaultForm}
            </Typography>
            <Button
              icon={FilterIcon}
              variant="text"
              size={'small'}
              disabled={filterExp?.length === 0}
              style={{ marginRight: 10 }}
              onClick={openExpressionsModal}>
              View filter conditions
            </Button>

            <Box style={{ display: 'flex', justifyContent: 'center', marginRight: '1rem' }}>
              {status === 1 || props[selectNodeText].noMap ? (
                <>
                  <StatusCheck
                    style={{
                      color: colors['green'],
                      height: '18px',
                      marginTop: '3px'
                    }}
                  />

                  <span>Completed</span>
                </>
              ) : (
                <>
                  <span>
                    <img
                      src={`${status === 0 ? Queued : InProgress}`}
                      style={{ height: '15px', marginRight: '5px', marginTop: '4px' }}
                    />
                  </span>
                  <span>{status === 0 ? 'Queued' : 'In Progress'}</span>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.filterExpressionContainer}>
        {disableDoNotMap && (
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '1rem 2rem 0'
            }}>
            <Typography style={{ color: 'red' }}>
              DO NOT MAP option is not allowed for system variables.
            </Typography>
          </Grid>
        )}
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem 2rem',
            borderBottom: '1px solid #F6F7FB'
          }}>
          <span>Do not Map</span>
          <Switch
            disabled={disableDoNotMap}
            onChange={(e) => {
              e.target.checked
                ? setConfirmAlert({
                    enabled: true,
                    variant: 'warning',
                    title: 'Are you sure, you do not want to map the rules ?',
                    message: 'Changes made for this variable would be lost',
                    onConfirm: () => {
                      handleVariableUpdate(
                        'save',
                        true,
                        [],
                        filterID,
                        selectNodeText,
                        ({ variant, message }) => {
                          setBanner({ enabled: true, variant, message });
                          setTimeout(
                            () => setBanner({ enabled: false, variant: 'info', message: '' }),
                            3000
                          );
                          setExpressions([]);
                          setNoMap(true);
                        },
                        filterName
                      );
                      setConfirmAlert({
                        enabled: false
                      });
                    },
                    onCancel: () => {
                      setNoMap(false);
                      setConfirmAlert({
                        enabled: false
                      });
                    }
                  })
                : setNoMap(false);
            }}
            checked={noMap}
            size="small"
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingLeft: '0.5rem',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '13px'
            }}
          />
        </Grid>
        {(expressions || []).map((exp, index) => {
          return (
            <FilterExpression
              key={'VarExpression-' + exp.id}
              index={index}
              sourceFieldList={sourceFormItems[defaultForm] || []}
              handleExpressionUpdate={handleExpressionUpdate}
              defaultForm={defaultForm}
              {...exp}
              disableExp={props.disableExpressions}
              variableName={selectNodeText}
              sourceFormItems={sourceFormItems}
              checkExpLength={expressions.length > 1 ? false : true}
              passStatusFlag={handleStatusFlag}
              allForms={allForms}
              allItems={allItems}
              hideViewCodelist={hideViewCodelist}
              setCodelistEditorDisplay={setCodelistEditorDisplay}
            />
          );
        })}
        <Button
          style={{ marginBottom: '2rem', marginTop: expressions.length > 0 ? '0rem' : '2rem' }}
          variant="primary"
          onClick={handleAddExpression}
          disabled={noMap}
          data-testid="add-filter-expression">
          + Add Expression
        </Button>
        <div style={{ position: 'absolute', right: 0, bottom: '11px', marginRight: '13px' }}>
          <Button
            style={{ marginRight: '0.5rem', float: 'left', width: '50%' }}
            variant={'secondary'}
            disabled={noMap}
            onClick={() => handleExpressionUpdate('cancel-all')}
            data-testid="cancel">
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleExpressionUpdate('save-all')}
            disabled={noMap || disableSave}
            data-testid="save">
            Save
          </Button>
        </div>
      </AccordionDetails>
      <Banner
        variant={banner.variant}
        open={banner.enabled}
        onClose={() => setBanner({ enabled: false, variant: 'info', message: '' })}
        message={banner.message}
        style={{ position: 'absolute' }}
        TransitionComponent={Fade}
      />
    </Accordion>
  );
};

export default React.memo(Filter);
